import { Button, StepProps, Steps } from "antd";
import { useEffect, useState } from "react";
import "./Stepper.css";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { ConversationStepsMapping, StepperConversationStepsMapping, StepperSteps } from "../../resources/constants";
import { setCNCurrentStepperStepFinishNextStepProcess, setCNStepperCurrentStep } from "../../../../Store/actions/_contentNewtonActions";

const initalSteps: StepProps[] = [
  {
    title: "Input Details",
    status: "process" //"finish",
  },
  {
    title: "Output Specifications",
    status: "wait"
  },
  {
    title: "Output Processing",
    status: "wait"
  },
  {
    title: "MLR Support",
    status: "wait"
  },
  {
    title: "MLR Submission",
    status: "wait"
  },
  {
    title: "Ready for Publish",
    status: "wait"
  }
];

export const CustomStepper = () => {
  // const [currentStep, setCNCurrentStep] = useState(0);
  const { stepperCurrentStep, stepperSteps, currentStep } = useAppSelector((state) => state.contentNewtonReducer);
  const [finishedStep, setFinishedStep] = useState(-1); //Update this on Next click
  // const [steps, setSteps] = useState(initalSteps);

  const dispatch = useAppDispatch();

  // const nextStep = () => {
  //   setCNCurrentStep(prev => (prev < steps.length - 1 ? prev + 1 : prev));
  // };

  console.log({ stepperCurrentStep });
  useEffect(() => {
    const currentStepperKeyRange = "sdfsd";
    const currentStepperKeyConversationStepRangeNames = StepperConversationStepsMapping[StepperSteps[stepperCurrentStep]];

    let lowerBound: number,
      upperBound: number | null = null;
    Object.entries(ConversationStepsMapping).find(([key, value]) => {
      if (value.includes(currentStepperKeyConversationStepRangeNames[0])) {
        lowerBound = Number(key);
      }
      if (value.includes(currentStepperKeyConversationStepRangeNames[1])) {
        upperBound = Number(key);
      }
    });

    // console.log({ lowerBound, upperBound, currentStepperKeyConversationStepRangeNames });

    if (upperBound) {
      if (currentStep > upperBound) dispatch(setCNCurrentStepperStepFinishNextStepProcess());
    }
  }, [currentStep]);

  const onStepChange = (newStep: number) => {
    // if (newStep <= finishedStep + 1) setCNCurrentStep(newStep);
    // setSteps(prevSteps =>{
    //   const updatedSteps = [...prevSteps];
    //   updatedSteps[newStep] =
    // })
  };

  return (
    <>
      <Steps className="cr-stepper" items={stepperSteps} direction="vertical" current={stepperCurrentStep} size="small" onChange={onStepChange} />
      {/* <Button onClick={nextStep}>Next</Button> */}
    </>
  );
};

{
  /* <Button onClick={nextStep}>Next</Button> */
}
