import React from "react";
import { Collapse } from "antd";
import { Children } from "./Children";
import { CustomPanelHeader } from "./CustomHeader";
import { ClaimType, NonClaimType } from "../../AssetLocalisation";
import { ExclamationCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { capitalizeFirstLetter } from "../../../../../../Utils/_gFunctions/helpers";

export function ClaimIdentificationWithouthTranslation({
  data,
  Index,
  setSelectedItemIndices,
  selectedItemIndices,
  type = "claims"
}: {
  data: ClaimType | NonClaimType;
  Index: number;
  setSelectedItemIndices?: Function;
  selectedItemIndices?: number[];
  type?: "claims" | "non-claims";
}) {
  const [expanded, setExpanded] = React.useState(false);
  const items = [
    {
      key: "1",
      label: (
        <CustomPanelHeader
          id={
            data?.id ? (
              <span>
                <span style={{ color: "blue" }}>{type === "claims" ? (data as ClaimType)?.claim_id : (data as NonClaimType)?.nonclaim_id}</span>
              </span>
            ) : (
              <>
                <span style={{ display: "flex", alignItems: "center" }}>
                  PromoMats ID:
                  <ExclamationCircleOutlined style={{ marginLeft: "8px" }} />
                  <span
                    style={{
                      color: "red",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "8px"
                    }}
                  >
                    Claim not found in VVPM
                    <SyncOutlined style={{ marginLeft: "8px" }} />
                  </span>
                </span>
              </>
            )
          }
          tag={capitalizeFirstLetter(data.keymessage)}
          title={data.text}
        />
      ),
      children: type === "claims" ? <Children references={[(data as ClaimType).reference]} footnotes={[(data as ClaimType).footnote]} /> : null
    }
  ];
  return (
    <div
      style={{
        border: "1px solid lightGray",
        borderRadius: "10px",
        height: "100%"
      }}
    >
      <Collapse
        items={items}
        expandIconPosition="end"
        ghost
        onChange={
          setSelectedItemIndices
            ? () =>
                setSelectedItemIndices((prevIndices: number[]) => {
                  if (prevIndices.includes(Index)) {
                    return prevIndices.filter((item: number) => item !== Index);
                  } else {
                    return [...prevIndices, Index];
                  }
                })
            : () => {
                setExpanded(!expanded);
              }
        }
        activeKey={selectedItemIndices ? (selectedItemIndices?.includes(Index) ? 1 : 0) : expanded ? 1 : 0}
      />
    </div>
  );
}
