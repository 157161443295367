import { Tag } from "antd";
import React from "react";

export const CustomPanelHeader: React.FC<{
  id: string | React.ReactElement;
  tag: string;
  title?: string;
}> = ({ id, tag, title }) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span>
          PromoMats ID: <span style={{ color: "blue" }}>{id}</span>
        </span>
        <span>
          <Tag>{tag}</Tag>
        </span>
      </div>
      <p style={{ textAlign: "left", paddingTop: "1rem" }}>{title}</p>
    </div>
  );
};
