import React from "react";
import { Card, Col, Image, Row } from "antd";
import "./ModulePreview.css"; // Make sure to create a CSS file for custom styles
import { ModuleType } from "../../types";

interface Claim {
  id: string;
  description: string;
}

interface NonClaim {
  description: string;
}

interface Props {
  module: ModuleType;
  // moduleId: number;
  // claims: ModuleType["claims"];
  // nonClaims: ModuleType["nonclaims"];
  // images?: string[];
}

export const ModulePreview: React.FC<Props> = ({ module: { module_id, claims, nonclaims, images } }) => {
  return (
    <div className="module-preview-container">
      <div className="module-title">Module: {module_id}</div>

      <div className="content-container">
        <Row gutter={16}>
          <Col span={12}>
            <Card title="Claims" className="scrollable">
              {claims.map((claim) => (
                <div key={claim.id} className="claim-item">
                  <strong>Claim: {claim.claim_id}</strong>
                  <p>{claim.text}</p>
                </div>
              ))}
            </Card>
          </Col>

          <Col span={12}>
            <Card title="Non Claims" className="scrollable">
              {nonclaims.map((nonClaim) => (
                <div key={nonClaim.id} className="nonclaim-item">
                  <strong>Claim: {nonClaim.claim_id}</strong>
                  <p>{nonClaim.text}</p>
                </div>
              ))}
            </Card>
          </Col>
        </Row>

        <Row className="images-container">
          {images?.map((image, index) => (
            <Col span={24} key={index}>
              <Image key={index} src={image.url} alt={`Image ${image.image_id}`} />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};
