import { Button, Modal, Tooltip } from "antd";
import ReactDiffViewer from "react-diff-viewer-continued";
import "./CompareStoryBoard.css";
import { ConversationChat } from "./ChatBot";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Store";
import { ComponentProps, useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";

type ReactDiffViewerProps = ComponentProps<typeof ReactDiffViewer>;

export const CompareStoryBoard = ({
  show,
  setShow,
  comparedChat,
  applyUpdatedStoryBoard
}: // loading
{
  show: boolean;
  setShow: Function;
  comparedChat: ConversationChat;
  applyUpdatedStoryBoard: Function;
  // loading: { apply: boolean };
}) => {
  const { responseCT } = useSelector((state: RootState) => state.contentTranscreationReducer);
  const [loading, setLoading] = useState(false);
  const [viewerStates, setViewerStates] = useState<Partial<ReactDiffViewerProps>>({
    oldValue: responseCT?.storyboard?.content || "",
    newValue: "",
    splitView: true,
    disableWordDiff: false,
    showDiffOnly: true
  });

  console.log({ viewerStates });

  // useEffect(() => {
  //   if (!loading.apply) setShow(false);
  // }, [loading.apply]);

  useEffect(() => {
    setViewerStates((prevState) => ({
      ...prevState,
      oldValue: responseCT?.storyboard?.content || "",
      newValue: comparedChat.message || ""
    }));
  }, [comparedChat, responseCT?.storyboard?.content]);

  const handleStateChange = <K extends keyof ReactDiffViewerProps>(key: K, value: ReactDiffViewerProps[K]) => {
    setViewerStates((prevState) => ({
      ...prevState,
      [key]: value
    }));
  };

  const toggleSplitMode = (mode: boolean) => {
    handleStateChange("splitView", mode);
  };

  const toggleWordDiff = () => {
    handleStateChange("disableWordDiff", !viewerStates.disableWordDiff);
  };
  const toggleShowDiffOnly = () => {
    handleStateChange("showDiffOnly", !viewerStates.showDiffOnly);
  };

  return (
    <Modal
      className="compare-storyboard-modal"
      centered
      width={"1000px"}
      open={show}
      title={
        <div className="modal-header">
          <div className="modal-title">Compare Storyboard</div>
          <div className="modal-controls">
            <CloseOutlined className="close-button" onClick={() => setShow(false)} />
            <div>
              <Button.Group>
                <Button type={!viewerStates.splitView ? "primary" : "default"} onClick={() => toggleSplitMode(false)}>
                  Unified
                </Button>
                <Button type={viewerStates.splitView ? "primary" : "default"} onClick={() => toggleSplitMode(true)}>
                  Split
                </Button>
              </Button.Group>

              <Button.Group style={{ marginLeft: 8 }}>
                <Button type={!viewerStates.disableWordDiff ? "primary" : "default"} onClick={toggleWordDiff}>
                  Word Diff
                </Button>
              </Button.Group>

              <Button.Group style={{ marginLeft: 8 }}>
                <Button type={viewerStates.showDiffOnly ? "primary" : "default"} onClick={toggleShowDiffOnly}>
                  Diff Only
                </Button>
              </Button.Group>
            </div>
          </div>
        </div>
      }
      closable={false}
      cancelText="Close"
      onCancel={() => setShow(false)}
      footer={(_, { OkBtn, CancelBtn }) => [
        <CancelBtn />,
        <Tooltip title="Apply the Compared Storyboard">
          <Button
            type="primary"
            onClick={async () => {
              setLoading(true);
              await applyUpdatedStoryBoard(comparedChat);
              setLoading(false);
              setShow(false);
            }}
            loading={loading}
          >
            Apply
          </Button>
        </Tooltip>
      ]}
    >
      <ReactDiffViewer {...viewerStates} />
    </Modal>
  );
};
