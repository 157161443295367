import { useEffect, useState } from "react";
import { ContentNewtonState } from "../../../../Store/reducers/_contentNewtonReducer";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { getFinalAssetURL, sseGetFinalAsset } from "../../../../Store/requests/_contentNewtonRequests";
import { FinalAssetSSEResponse } from "../Module Selection/types";
import { SSEMessage, SSEMessageList } from "../../components/common/DisplaySSEMessages";
import { setCNCurrentIndexDoneNextIndexProcess, setCNFinalAsset } from "../../../../Store/actions/_contentNewtonActions";
import { Button, notification, Skeleton, Tooltip } from "antd";
import { ConversationStepsMapping, ConversationStepsName } from "../../resources/constants";
import { EyeOutlined } from "@ant-design/icons";
import { FinalAssetPreviewModal } from "./FinalAssetPreviewModal";

export const FinalAsset = () => {
  const { currentStep, finalAsset, orderId, selectedTemplateId }: ContentNewtonState = useAppSelector((state) => state.contentNewtonReducer);
  const [loading, setLoading] = useState(!finalAsset);
  const [sseMessages, setSSEMessages] = useState<SSEMessage[]>([]);
  const [errorMessage, setErrorMessage] = useState("");

  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (finalAsset) {
      return;
    }

    const sse = sseGetFinalAsset(orderId, selectedTemplateId);

    sse.addEventListener("pending", async (data: { data: string }) => {
      console.log(`pending`, data?.data);
      setLoading(false);

      let parsedData: FinalAssetSSEResponse;
      try {
        parsedData = JSON.parse(data.data);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        sse.close();
        return;
      }

      if (parsedData) {
        if (parsedData.message) {
          setSSEMessages((prev) => [...prev, parsedData.message]);
        }
      }
    });

    sse.addEventListener("end", async (data: { data: string }) => {
      console.log(`sse.addEventListener("end"`, data?.data);
      sse.close();
      setLoading(false);

      let parsedData: FinalAssetSSEResponse;
      try {
        parsedData = JSON.parse(data.data);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        sse.close();
        return;
      }

      if (parsedData) {
        if (parsedData.error) {
          setErrorMessage(parsedData.message.text);
        } else if (parsedData.data) {
          setSSEMessages((prev) => [...prev, { type: "main" }]); //Blank message to finish the last step
          dispatch(setCNFinalAsset(parsedData.data));
        }
      }
    });

    sse.onerror = (err) => {
      notification.error({ message: `SSE Templates Listing Error!\n ${err}` });
      sse.close();
      setLoading(false);
    };
  }, [finalAsset]);

  const isFinalAssetStep = ConversationStepsMapping[currentStep] === ConversationStepsName.CREATING_FINAL_ASSET;

  const handleEdit = () => {
    setEditMode(true);
    setOpenPreviewModal(true);
  };

  //   const handleDownload = async () => {
  //     if (!finalAsset) return;
  //     const res = await getFinalAssetURL(finalAsset.asset_id);

  //     if (res?.data?.url) {
  //       const element = document.createElement("a");
  //       element.href = res.data.url;
  //       document.body.appendChild(element);
  //       element.click();
  //       document.body.removeChild(element);
  //     }
  //   };

  const handleDownload = async () => {
    if (!finalAsset) return;
    const element = document.createElement("a");
    element.setAttribute("href", "data:text/html;charset=utf-8," + encodeURIComponent(finalAsset?.html));
    element.setAttribute("download", "FinalAsset.html");
    element.click();
  };

  const handlePreview = () => {
    setEditMode(false);
    setOpenPreviewModal(true);
  };

  return (
    <div>
      {loading ? (
        <Skeleton active />
      ) : errorMessage ? (
        <div>{errorMessage}</div>
      ) : (
        <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "5px" }}>
          <SSEMessageList sseMessages={sseMessages} />
          {finalAsset && (
            <div style={{ width: "fit-content", display: "flex", flexDirection: "column", gap: "10px" }}>
              <div
                style={{
                  width: "300px",
                  height: "300px",
                  backgroundColor: "#2a0874", //"white",
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  border: "1px solid lightgray",
                  borderRadius: "10px"
                }}
              >
                <img src={finalAsset?.thumbnail} style={{ width: "100%" }} />
                <Tooltip title="Preview">
                  <EyeOutlined
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      fontSize: "18px",
                      color: "#fff",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      borderRadius: "50%",
                      padding: "3px",
                      cursor: "pointer"
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePreview();
                    }}
                  />
                </Tooltip>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button type="primary" onClick={handleEdit}>
                  Edit
                </Button>
                <Button type="primary" onClick={handleDownload}>
                  Download
                </Button>
              </div>
            </div>
          )}

          <div
            style={{
              display: isFinalAssetStep && finalAsset ? "flex" : "none",
              // justifyContent: "flex-end",
              marginTop: "5px"
            }}
          >
            <Button type="primary" onClick={() => dispatch(setCNCurrentIndexDoneNextIndexProcess())}>
              Proceed
            </Button>
          </div>

          {finalAsset && <FinalAssetPreviewModal open={openPreviewModal} setOpen={setOpenPreviewModal} asset={finalAsset} edit={editMode} />}
        </div>
      )}
    </div>
  );
};
