import LogRocket from "logrocket";
import { ErrorAlert } from "../../Components/Alerts";
import _apiService from "../../Services/_apiService";
import { setLoadingStatusAction, setNotificationMsgAction } from "../../Store/actions/_commonActions";
import { MODULE_PATHNAME, globalConstant } from "../_gConstants";
import { LayoutSyncTypes, apiResponse } from "../_gTypes";
import { useEffect, useState } from "react";
import { siteConfig } from "../../Services";
import { SSE } from "sse.js";

export const checkExpirationOfToken = (code: number) => {
  if (code === 401) return true;
  else return false;
};

export const handleGetDataFromApi = async (url: string, dispatch?: any) => {
  let res: any;
  try {
    if (!dispatch) {
      res = await _apiService.get(url);
    } else {
      dispatch(setLoadingStatusAction(true));
      res = await _apiService.get(url);
      dispatch(setLoadingStatusAction(false));
    }
  } catch (err) {
    console.log(err);
  }

  return res;
};

export const handlePostDataFromApi = async (url: string, body: any, dispatch?: any, config?: any) => {
  let res: any;
  try {
    if (!dispatch) {
      res = await _apiService.post(url, body, config);
    } else {
      dispatch(setLoadingStatusAction(true));
      res = await _apiService.post(url, body, config);
      dispatch(setLoadingStatusAction(false));
    }
  } catch (err) {
    console.log(err);
  }

  return res;
};

export const handlePutDataFromApi = async (url: string, body: any, dispatch?: any) => {
  let res: any;
  try {
    if (!dispatch) {
      res = await _apiService.put(url, body);
    } else {
      dispatch(setLoadingStatusAction(true));
      res = await _apiService.put(url, body);
      dispatch(setLoadingStatusAction(false));
    }
  } catch (err) {
    console.log(err);
  }

  return res;
};

export const handlePatchDataFromApi = async (url: string, body: any, dispatch?: any) => {
  let res: any;
  const controller = new AbortController();
  try {
    if (!dispatch) {
      res = await _apiService.patch(url, body);
      console.log(res, "response");
    } else {
      dispatch(setLoadingStatusAction(true));
      res = await _apiService.patch(url, body);
      dispatch(setLoadingStatusAction(false));
    }
  } catch (err) {
    console.log(err);
  }
  return res;
};

export const handleDeleteDataFromApi = async (url: string, dispatch?: any) => {
  let res: any;
  try {
    if (!dispatch) {
      res = await _apiService.delete(url);
    } else {
      dispatch(setLoadingStatusAction(true));
      res = await _apiService.delete(url);
      dispatch(setLoadingStatusAction(false));
    }
  } catch (err) {
    console.log(err);
  }

  return res;
};

export const handleCancelApiRequest = () => {
  try {
    // _apiService.getAxiosSourceTokenState().cancel();
    _apiService.getAxiosSourceTokenState()?.abort?.();
    //@ts-ignore
    // window?.store?.dispatch(setLoadingStatusAction(false));
  } catch (err) {
    console.log(err);
  }
};

export const handleAPIErrors = (res: apiResponse) => {
  try {
    // if (res?.hints && Object.keys(res?.hints)?.length) {
    //   ErrorAlert(res?.hints);
    // } else if (res?.reason && res?.reason?.length) {
    //   ErrorAlert(res?.reason);
    // } else {
    //   ErrorAlert(globalConstant.INTERNAL_SERVER_ERROR);
    // }
  } catch (error) {
    ErrorAlert(globalConstant.INTERNAL_SERVER_ERROR);
    console.log(error);
  }
};

export const getDefaultLayout = (layoutSync: LayoutSyncTypes) => {
  const arrLayoutKeys: string[] = Object.keys(layoutSync);

  arrLayoutKeys?.forEach((item: string) => {
    //@ts-ignore
    layoutSync[item] = false;
  });

  return { ...layoutSync };
};

/**
 * Returns active layout
 * @param module string
 * @returns
 */
export const getCurrentLayout = (module: string) => {
  switch (module) {
    case MODULE_PATHNAME.SEMANTIC_SEARCH:
      return { SEMANTIC_SEARCH: true };
    case MODULE_PATHNAME.CONTENT_AI:
      return { CONTENT_AI: true };
    case MODULE_PATHNAME.CONTENT_TRANSLATION:
      return { CONTENT_TRANSLATION: true };
    case MODULE_PATHNAME.TRANSLATION_MODULE:
      return { TRANSLATION_MODULE: true };
    case MODULE_PATHNAME.CONTENT_NEWTON:
      return { CONTENT_NEWTON: true };

    default:
      return { CONTENT_TRANSCREATION: true };
  }
};

// export const onDownload = (url: string) => {
//   const link = document.createElement("a");
//   link.href = url;
//   link.download = url;
//   document.body.appendChild(link);
//   link.click();
//   URL.revokeObjectURL(url);
//   link.remove();
// };
export const onDownload = (url: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank"; // Add this line to open the link in a new tab
  link.download = url;
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(url);
  link.remove();
};

export function getUTCtoLocalDateTimeString(dateString: string): string {
  try {
    //date-only forms are interpreted as a UTC time and date-time forms are interpreted as local time.
    //literal character Z indicates UTC
    const localDateObj = new Date(dateString + "Z");

    const day = localDateObj.getDate();
    const month = localDateObj.getMonth() + 1;
    const year = localDateObj.getFullYear();
    const hours = localDateObj.getHours();
    const minutes = localDateObj.getMinutes();
    const amOrPm = hours >= 12 ? "PM" : "AM";

    // Format day and month to have leading zeros if needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    // Format hours to be in 12-hour format with leading zeros if needed
    const formattedHours = hours % 12 || 12; // Handle 0 as 12
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    const formattedDate = `${formattedDay}-${formattedMonth}-${year} ${formattedHours}:${formattedMinutes} ${amOrPm}`;

    return formattedDate;
  } catch (err) {
    console.log(err);
    return "Invalid Date";
  }
}

/**
 *
 * @param ref reference of dom element
 * @param cb handles functionality when user click outside the referenced element
 */
export function useOutsideAlerter(ref: any, cb: () => void) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref?.current && !ref?.current?.contains?.(event.target)) {
        cb();
      }
    } // Bind the event listener
    document?.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document?.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

// Hook
export function useDebounce(value: string | undefined, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

// export const getCustomSSE = (url: string, payload?: any) => {
//   var headers: Record<string, string> = {
//     Authorization: localStorage.getItem(siteConfig.ACCESS_TOKEN) || ("" as string)
//   };

//   const options = { ...headers };

//   if (payload) options["payload"] = payload;

//   const sse = new SSE(siteConfig.BASE_URL + url, {
//     //@ts-ignore
//     options
//   });

//   return sse;
// };

export const formatDateTime = (date = new Date()) => {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    // throw new Error("formatDateTime received invalid date object");
    return "2:04 PM, 15 Aug";
  }

  const hours = (date.getHours() % 12 || 12).toString().padStart(2, "0"); // 12-hour format
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = date.getHours() >= 12 ? "PM" : "AM";
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });

  return `${hours}:${minutes} ${ampm}, ${day} ${month}`; // "2:04 PM, 15 Nov"
};
