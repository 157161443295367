import { useEffect, useState } from "react";
import { ContentNewtonState } from "../../../../Store/reducers/_contentNewtonReducer";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { getFinalAssetURL, sseGetFinalAsset, sseGetLocalisedAssets, sseLocaliseAsset } from "../../../../Store/requests/_contentNewtonRequests";
import { FinalAssetSSEResponse } from "../Module Selection/types";
import { SSEMessage, SSEMessageList } from "../../components/common/DisplaySSEMessages";
import {
  setCNCurrentIndexData,
  setCNCurrentIndexDoneNextIndexProcess,
  setCNFinalAsset,
  setCNLocalisedAssetData,
  setCNPutStepsWithGivenNamesAfterCurrentIndex
} from "../../../../Store/actions/_contentNewtonActions";
import { Button, notification, Skeleton, Tooltip } from "antd";
import { ConversationStepsMapping, ConversationStepsName } from "../../resources/constants";
import { EyeOutlined } from "@ant-design/icons";
import { set } from "lodash";
import { LocalisedClaimsModal } from "./Modals/LocalisedClaimsModal";
import { LocalisedNonClaimsModal } from "./Modals/LocalisedNonClaimsModal";
import { ImagesModal } from "./Modals/ImagesModal";
import { AssembleModal } from "./Modals/AssembleModal";
// import { FinalAssetPreviewModal } from "./FinalAssetPreviewModal";

export interface EquivalentClaimType {
  id: number;
  claim_id: string;
  text: string;
  type?: string;
  keymessage?: string;
  reference: string;
  footnote: string;
}

export interface ClaimType {
  id: number;
  order_id: number;
  market: string;
  claim_id: string;
  text: string;
  type?: string;
  keymessage?: string;
  reference: string;
  footnote: string;
  equivalent: EquivalentClaimType;
}

export interface EquivalentNonClaimType {
  id: number;
  nonclaim_id: string;
  text: string;
  type?: string;
  keymessage?: string;
  suggestions: {
    id: string;
    text: string;
    type?: string;
    keymessage?: string;
  }[];
}

export interface NonClaimType {
  id: number;
  order_id: number;
  market: string;
  nonclaim_id: string;
  text: string;
  type?: string;
  keymessage?: string;
  equivalent: EquivalentNonClaimType;
}

interface EquivalentImageType {
  id: number;
  image_id: string;
  url: string;
  source: string;
}

export interface ImageType {
  id: number;
  order_id: number;
  market: string;
  image_id: string;
  url: string;
  source: string;
  equivalent: EquivalentImageType;
}

export interface LocalisedAssetsSSEResponse {
  message: { text: string; type: "main" | "step" | "substep" };
  data: LocalisedAssetType[];
  error: boolean;
}

export interface LocalisedAssetSSEResponse {
  message: { text: string; type: "main" | "step" | "substep" };
  data: ClaimType[] | NonClaimType[] | ImageType[] | OriginalAssetType;
  error: boolean;
}

export interface LocalisedAssetType {
  id: number;
  order_id: number;
  orderitem_id: number;
  market: string;
  status: "SUCCESS" | "PENDING";
  html: string;
  thumbnail: string;
}

export interface OriginalAssetType {
  id: number;
  order_id: number;
  html: string;
  thumbnail: string;
}

export type LocalisedAssetDataType = {
  claims: ClaimType[];
  nonclaims: NonClaimType[];
  images: ImageType[];
  originalAsset: OriginalAssetType;
  localisedAssets: LocalisedAssetType[];
};

export const AssetLocalisation = ({ index }: { index: number }) => {
  const { currentStep, finalAsset, orderId, marketAndLanguageInputPrompt, localisedAssetData, conversationSteps }: ContentNewtonState =
    useAppSelector((state) => state.contentNewtonReducer);
  console.log("localisedAssetData?.claims.length", localisedAssetData, conversationSteps[index].data);
  const [loading, setLoading] = useState(!localisedAssetData?.claims?.length && !conversationSteps[index].data);
  const [sseMessages, setSSEMessages] = useState<SSEMessage[]>([]);
  const [errorMessage, setErrorMessage] = useState(conversationSteps[index].data ?? "");

  const [localisedClaims, setLocalisedClaims] = useState<ClaimType[]>([]);
  const [localisedNonClaims, setLocalisedNonClaims] = useState<NonClaimType[]>([]);
  const [localisedImages, setLocalisedImages] = useState<ImageType[]>([]);
  const [originalAsset, setOriginalAsset] = useState<OriginalAssetType>();
  const [localisedAssets, setLocalisedAssets] = useState<LocalisedAssetType[]>([]);

  const [openModal, setOpenModal] = useState<"claims" | "non-claims" | "image" | "assemble" | undefined>();

  const [sseComplete, setSSEComplete] = useState(false);

  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [fatalError, setFatalError] = useState(false);
  const [retry, setRetry] = useState(false);

  const dispatch = useAppDispatch();

  const handleRetry = () => {
    setRetry(true);
    resetComponentStates();
  };

  const resetComponentStates = () => {
    setLoading(!localisedAssetData?.claims?.length && !conversationSteps[index].data);
    setSSEMessages([]);
    setErrorMessage(conversationSteps[index].data ?? "");
    setLocalisedClaims([]);
    setLocalisedNonClaims([]);
    setLocalisedImages([]);
    setOriginalAsset(undefined);
    setLocalisedAssets([]);
    setSSEComplete(false);
  };

  useEffect(() => {
    if (conversationSteps[index].status === "Done") return;
    fetchLocalisedData();
  }, []);

  useEffect(() => {
    if (!retry) return;
    fetchLocalisedData();
    setRetry(false);
    fatalError && setFatalError(false);
  }, [retry]);

  const fetchLocalisedData = async () => {
    // if (conversationSteps[index].status === "Done") return;
    // if (!retry /* && localisedAssetData?.claims?.length ||  */) {
    //   return;
    // }

    // if (!retry && fatalError) {
    //   // setFatalError(false);
    //   return;
    // }

    // retry && setRetry(false);
    // fatalError && setFatalError(false);

    const sse = sseLocaliseAsset(orderId, marketAndLanguageInputPrompt);

    sse.addEventListener("pending", async (data: { data: string }) => {
      console.log(`pending`, data?.data);
      setLoading(false);

      let parsedData: LocalisedAssetSSEResponse;
      try {
        parsedData = JSON.parse(data.data);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        sse.close();
        return;
      }

      if (parsedData) {
        if (parsedData.message) {
          let identifiedText = identifyText(parsedData.message.text);
          setSSEMessages((prev) => [
            ...prev,
            {
              ...parsedData.message,
              buttonOnClick: parsedData.data
                ? () => {
                    setOpenModal(identifiedText);
                  }
                : undefined
            }
          ]);
          if (identifiedText === "claims") {
            setLocalisedClaims(parsedData.data as ClaimType[]);
            dispatch(setCNLocalisedAssetData("claims", parsedData.data as ClaimType[]));
          } else if (identifiedText === "non-claims") {
            setLocalisedNonClaims(parsedData.data as NonClaimType[]);
            dispatch(setCNLocalisedAssetData("nonclaims", parsedData.data as NonClaimType[]));
          } else if (identifiedText === "image") {
            setLocalisedImages(parsedData.data as ImageType[]);
            dispatch(setCNLocalisedAssetData("images", parsedData.data as ImageType[]));
          }
        }
      }
    });

    sse.addEventListener("end", async (data: { data: string }) => {
      console.log(`sse.addEventListener("end"`, data?.data);
      sse.close();
      setLoading(false);

      let parsedData: LocalisedAssetSSEResponse;
      try {
        parsedData = JSON.parse(data.data);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        sse.close();
        return;
      }

      if (parsedData) {
        if (parsedData.error) {
          setErrorMessage(parsedData.message.text);
          dispatch(setCNCurrentIndexData(parsedData.message.text));
          dispatch(
            setCNPutStepsWithGivenNamesAfterCurrentIndex([
              ConversationStepsName.USER_PROMPT_MARKET_AND_LANGUAGE,
              ConversationStepsName.LOCALISATION_WITHOUT_TRANSLATION
            ])
          );
          dispatch(setCNCurrentIndexDoneNextIndexProcess());
        } else if (parsedData.message) {
          setSSEMessages((prev) => [
            ...prev,
            {
              ...parsedData.message,
              buttonOnClick: parsedData.data
                ? () => {
                    setAssembleMessageLoader(true);
                    fetchLocalisedAssets();
                  }
                : undefined
            },
            { type: "main" }
          ]); //Blank message to finish the last step

          if (parsedData.data) {
            setOriginalAsset(parsedData.data as OriginalAssetType);
            dispatch(setCNLocalisedAssetData("originalAsset", parsedData.data as OriginalAssetType));
          }
        }
      }
    });

    sse.onerror = (err) => {
      notification.error({ message: `SSE Asset Localisation Error!\n ${err}` });
      sse.close();
      setLoading(false);
      setFatalError(true);
    };
  };

  const setAssembleMessageLoader = (loading: boolean) => {
    setSSEMessages((prev) => {
      const updatedMessages = [...prev];
      //Remove last blank message
      if (loading) updatedMessages.splice(updatedMessages.length - 1, 1);
      const assembleMessage = updatedMessages[updatedMessages.length - 1];
      updatedMessages[updatedMessages.length - 1] = {
        ...assembleMessage,
        loading
      };
      if (!loading) {
        updatedMessages.push({ type: "main" });
      }
      return updatedMessages;
    });
  };

  const fetchLocalisedAssets = async () => {
    const sse = sseGetLocalisedAssets(orderId);

    sse.addEventListener("end", async (data: { data: string }) => {
      console.log(`sse.addEventListener("end"`, data?.data);
      sse.close();
      setLoading(false);
      setAssembleMessageLoader(false);

      let parsedData: LocalisedAssetsSSEResponse;
      try {
        parsedData = JSON.parse(data.data);
      } catch (error) {
        console.error("Error parsing sseGetLocalisedAssets JSON:", error);
        sse.close();
        return;
      }

      if (parsedData) {
        if (parsedData.error) {
          setErrorMessage(parsedData.message.text);
          // dispatch(setCNCurrentIndexData(parsedData.message.text));
          // dispatch(
          //   setCNPutStepsWithGivenNamesAfterCurrentIndex([
          //     ConversationStepsName.USER_PROMPT_MARKET_AND_LANGUAGE,
          //     ConversationStepsName.LOCALISATION_WITHOUT_TRANSLATION
          //   ])
          // );
          // dispatch(setCNCurrentIndexDoneNextIndexProcess());
        } else if (parsedData.data) {
          setLocalisedAssets(parsedData.data as LocalisedAssetType[]);
          dispatch(setCNLocalisedAssetData("localisedAssets", parsedData.data as LocalisedAssetType[]));
          setOpenModal("assemble");
        }
      }
    });

    sse.onerror = (err) => {
      notification.error({ message: `SSE Asset Localisation Error!\n ${err}` });
      sse.close();
      setLoading(false);
      setFatalError(true);
      setAssembleMessageLoader(false);
    };
  };

  const isLocalisedAssetStep = ConversationStepsMapping[currentStep] === ConversationStepsName.LOCALISATION_WITHOUT_TRANSLATION;

  const identifyText = (text: string): "claims" | "non-claims" | "image" | undefined => {
    if (!text) return undefined;
    text = text.toLowerCase();
    if (text.includes(" claims ")) {
      return "claims";
    } else if (text.includes("non-claims")) {
      return "non-claims";
    } else if (text.includes("image")) {
      return "image";
    }
  };

  const handleEdit = () => {
    setEditMode(true);
    setOpenPreviewModal(true);
  };

  //   const handleDownload = async () => {
  //     if (!finalAsset) return;
  //     const res = await getFinalAssetURL(finalAsset.asset_id);

  //     if (res?.data?.url) {
  //       const element = document.createElement("a");
  //       element.href = res.data.url;
  //       document.body.appendChild(element);
  //       element.click();
  //       document.body.removeChild(element);
  //     }
  //   };

  const handleDownload = async () => {
    //TODO: Replace with LocalisedAssets
    if (!originalAsset) return;
    const element = document.createElement("a");
    element.setAttribute("href", "data:text/html;charset=utf-8," + encodeURIComponent(originalAsset?.html));
    element.setAttribute("download", "LocalisedAsset.html");
    element.click();
  };

  const handlePreview = () => {
    setEditMode(false);
    setOpenPreviewModal(true);
  };

  return (
    <div>
      {!fatalError &&
        (loading ? (
          <Skeleton active />
        ) : errorMessage ? (
          <div>{errorMessage}</div>
        ) : (
          <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "5px" }}>
            <SSEMessageList sseMessages={sseMessages} />
            {/* {finalAsset && (
            <div>
              <div style={{ width: "300px", height: "300px", backgroundColor: "white", display: "flex", alignItems: "center", position: "relative" }}>
                <img src={finalAsset?.thumbnail} style={{ width: "100%" }} />
                <Tooltip title="Preview">
                  <EyeOutlined
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      fontSize: "18px",
                      color: "#fff",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      borderRadius: "50%",
                      padding: "3px",
                      cursor: "pointer"
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePreview();
                    }}
                  />
                </Tooltip>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button type="primary" onClick={handleEdit}>
                  Edit
                </Button>
                <Button type="primary" onClick={handleDownload}>
                  Download
                </Button>
              </div>
            </div>
          )} */}

            <div
              style={{
                display: isLocalisedAssetStep && localisedClaims.length && localisedNonClaims.length && localisedImages.length ? "flex" : "none",
                justifyContent: "flex-end"
              }}
            >
              <Button type="primary" onClick={() => dispatch(setCNCurrentIndexDoneNextIndexProcess())}>
                Proceed
              </Button>
            </div>

            <LocalisedClaimsModal localisedClaims={localisedClaims} open={openModal === "claims"} setOpen={setOpenModal} />
            <LocalisedNonClaimsModal localisedNonClaims={localisedNonClaims} open={openModal === "non-claims"} setOpen={setOpenModal} />
            {originalAsset && !!localisedAssets.length && (
              <AssembleModal originalAsset={originalAsset} localisedAssets={localisedAssets} open={openModal === "assemble"} setOpen={setOpenModal} />
            )}
            <ImagesModal localisedImages={localisedImages} open={openModal === "image"} setOpen={setOpenModal} />

            {/* {openModal === "claims" && (
              <LocalisedClaimsModal localisedClaims={localisedClaims} open={openModal === "claims"} setOpen={setOpenModal} />
            )}
            {openModal === "non-claims" && (
              <LocalisedNonClaimsModal localisedNonClaims={localisedNonClaims} open={openModal === "non-claims"} setOpen={setOpenModal} />
            )}
            {openModal === "image" && <ImagesModal localisedImages={localisedImages} open={openModal === "image"} setOpen={setOpenModal} />}
            {openModal === "assemble" && originalAsset && !!localisedAssets.length && (
              <AssembleModal originalAsset={originalAsset} localisedAssets={localisedAssets} open={openModal === "assemble"} setOpen={setOpenModal} />
            )} */}

            {/* {finalAsset && <FinalAssetPreviewModal open={openPreviewModal} setOpen={setOpenPreviewModal} asset={finalAsset} edit={editMode} />} */}
          </div>
        ))}
      {fatalError && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>Error occured!</div>
          <Button type="link" onClick={handleRetry}>
            Retry
          </Button>
        </div>
      )}
    </div>
  );
};
