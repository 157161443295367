import React, { useState, useEffect } from "react";
import { Spin, Typography, Button } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
const { Text } = Typography;

export interface SSEMessage {
  type: "main" | "step" | "substep";
  text?: string;
  loading?: boolean; // false means finished|done
  // button?: boolean;
  buttonOnClick?: () => void;
}

interface SSEMessageListProps {
  sseMessages: SSEMessage[];
}

/**
 * A list of messages received from the server over Server Sent Events (SSE)
 *
 * Each message is rendered as a list item with a loading indicator and a text
 * description. The loading indicator is a spinning icon if the message is being
 * processed, and a checkmark if the message has been processed.
 *
 * The list is ordered in the same order as the messages are received from the server.
 *
 * If the message has a type of "step" or "substep", it will be marked as complete
 * once the next message is received. If the message has a type of "main", loading state
 * doesn't matter.
 *
 * @param {SSEMessage[]} sseMessages - The array of messages received from the server
 * over SSE.
 * @returns A list of messages received from the server over SSE.
 */

export const SSEMessageList: React.FC<SSEMessageListProps> = ({ sseMessages }) => {
  const [messages, setMessages] = useState<SSEMessage[]>([]);

  useEffect(() => {
    if (sseMessages.length > 0) {
      const newMessage = sseMessages[sseMessages.length - 1];
      const prevMessages = sseMessages.slice(0, sseMessages.length - 1);
      if (!newMessage.text) {
        setMessages(markLastStepOrSubStepComplete([...prevMessages]));
      } else {
        setMessages(() => {
          const updatedMessages = [...prevMessages];

          if (newMessage.type === "step" || newMessage.type === "main") {
            markLastStepOrSubStepComplete(updatedMessages);
          } else if (newMessage.type === "substep") {
            if (updatedMessages[updatedMessages.length - 1].type === "substep") {
              updatedMessages[updatedMessages.length - 1] = {
                ...updatedMessages[updatedMessages.length - 1],
                loading: false
              };
            }
          }
          updatedMessages.push({
            ...newMessage,
            loading: newMessage.type === "step" || newMessage.type === "substep"
          });
          return updatedMessages;
        });
      }
    }
  }, [sseMessages]);

  const markLastStepOrSubStepComplete = (updatedMessages: SSEMessage[]) => {
    for (let i = updatedMessages.length - 1; i >= 0; i--) {
      const message = updatedMessages[i];

      if (message.loading && message.type === "substep") {
        updatedMessages[i] = {
          ...message,
          loading: false
        };

        for (let j = i - 1; j >= 0; j--) {
          if (updatedMessages[j].type === "step") {
            updatedMessages[j] = {
              ...updatedMessages[j],
              loading: false
            };
          }
        }
        break;
      }

      if (message.loading && message.type === "step") {
        updatedMessages[i] = {
          ...message,
          loading: false
        };
        break;
      }
    }

    return updatedMessages;
  };

  const renderMessage = (message: SSEMessage, index: number) => {
    return (
      <div
        key={index}
        style={{
          display: "flex",
          alignItems: "center",
          paddingLeft: message.type === "substep" ? "30px" : "0"
        }}
      >
        {message.type !== "main" && (
          <div style={{ marginRight: "10px" }}>
            {message.loading ? <Spin size="small" /> : <CheckCircleFilled style={{ color: "green", fontSize: "16px" }} />}
          </div>
        )}
        <Text
          // strong={message.loading}
          // style={{ fontWeight: message.loading ? "600" : "normal", fontSize: message.type === "main" ? "14px" : "12px" }}
        >
          {message.text}
        </Text>
        {message.buttonOnClick && !message.loading && (
          // <Button type="link" style={{ marginLeft: "10px", color: "#5d2cc9", fontWeight: "600", fontSize: "12px" }} onClick={message.buttonOnClick}>
          //   View
          // </Button>
          <span
            style={{ marginLeft: "10px", color: "#5d2cc9", fontWeight: "600", fontSize: "12px", cursor: "pointer" }}
            onClick={message.buttonOnClick}
          >
            View
          </span>
        )}
      </div>
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>{messages.map((message, index) => renderMessage(message, index))}</div>
  );
};
