import React, { useEffect, useState } from "react";
import { Layout, Menu, MenuProps } from "antd";
import { DashboardOutlined, MessageOutlined, AppstoreOutlined, SettingOutlined, RightOutlined, LeftOutlined } from "@ant-design/icons";
import "./Sidebar.css";
import { ENUM_ROUTE_URLS } from "../../../Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { PROJECT_ICONS } from "../../../Assets/Img/_DIcons";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

const items: MenuItem[] = [
  {
    key: ENUM_ROUTE_URLS.CONTENT_NEWTON,
    label: "Content Newton",
    icon: <img src={PROJECT_ICONS.CN_SIDEBAR_CONTENT_NEWTON_ICON} height={18} width={16} alt="Content Newton Icon" />
  },
  {
    key: "reports",
    label: "Reports",
    icon: <MessageOutlined style={{ fontSize: "16px" }} />,
    disabled: true
  },
  {
    key: "rearrange-assets",
    label: "Rearrange Assets",
    icon: <AppstoreOutlined style={{ fontSize: "16px" }} />,
    disabled: true
  },
  {
    key: "settings",
    label: "Settings",
    icon: <SettingOutlined style={{ fontSize: "16px" }} />,
    disabled: true
  }
];

const Sidebar: React.FC = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === ENUM_ROUTE_URLS.CONTENT_NEWTON) {
      setSelectedKey(ENUM_ROUTE_URLS.CONTENT_NEWTON);
    } else {
      setSelectedKey(null);
    }
  }, [location.pathname]);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const onMenuClick: MenuProps["onClick"] = ({ key }) => {
    //navigate(key);
    navigate(ENUM_ROUTE_URLS.CONTENT_NEWTON);
  };

  return (
    <Sider className="app-sidebar" theme="light" collapsible collapsed={collapsed} onCollapse={handleCollapse} trigger={null}>
      <Menu
        onClick={onMenuClick}
        // defaultSelectedKeys={[ROUTE_PATHS.CONTENT_REPLICATOR]}
        selectedKeys={selectedKey ? [selectedKey] : []}
        mode="inline"
        items={items}
      />
      <div
        onClick={() => setCollapsed(!collapsed)}
        style={{
          height: "20px",
          width: "20px",
          borderRadius: "50%",
          position: "absolute",
          left: "100%",
          transform: "translateX(-50%)",
          top: "50%",
          cursor: "pointer",
          zIndex: 1,
          color: "black",
          background: "white",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
          display: "flex",
          justifyContent: "center"
        }}
      >
        {collapsed ? <RightOutlined /> : <LeftOutlined />}
      </div>
    </Sider>
  );
};

export default Sidebar;
