import { ChatBotInput } from "../../../../Content AI/Components/Content Creator/ChatBotInput";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import "./ChatBotGIF.css";

export const ChatBotGIF = () => {
  return (
    <div className="chat-bot-box">
      <ChatBotInput values={["How can I help you?"]} />
      <img className="chat-bot-image" src={PROJECT_ICONS.C_AI_CHAT_BOT_IMAGE} />
    </div>
  );
};
