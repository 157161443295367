import React from "react";
import { Col, Collapse, Radio, RadioChangeEvent } from "antd";
import { Children } from "./Children";
import { CustomPanelHeader } from "./CustomHeader";
import { Footer } from "./Footer";
import { useState } from "react";
import { SuggestedContent } from "./SuggestedContent";
import { EquivalentClaimType, EquivalentNonClaimType } from "../../AssetLocalisation";
import { ExclamationCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { capitalizeFirstLetter } from "../../../../../../Utils/_gFunctions/helpers";

export function ClaimIdentificationWithouthTranslationDark({
  data,
  isSuggestionsAvailable = false,
  Index,
  setSelectedItemIndices,
  selectedItemIndices,
  type = "claims"
}: {
  data: EquivalentClaimType | EquivalentNonClaimType;
  isSuggestionsAvailable?: boolean;
  Index: number;
  setSelectedItemIndices?: Function;
  selectedItemIndices?: number[];
  type?: "claims" | "non-claims";
}) {
  const [expanded, setExpanded] = React.useState(false);

  const [contentOpen, setContentOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(data.id);

  isSuggestionsAvailable = (data as EquivalentNonClaimType)?.suggestions?.length ? true : false;

  const onChange = (e: RadioChangeEvent) => {
    setSelectedId(e.target.value);
  };
  const items = [
    {
      key: "1",
      label: (
        <CustomPanelHeader
          data={data}
          id={
            data.id ? (
              <span>
                <span style={{ color: "blue" }}>
                  {type === "claims" ? (data as EquivalentClaimType)?.claim_id : (data as EquivalentNonClaimType)?.nonclaim_id}
                </span>
              </span>
            ) : (
              <>
                <span style={{ display: "flex", alignItems: "center" }}>
                  <ExclamationCircleOutlined style={{ marginLeft: "8px" }} />
                  <span
                    style={{
                      color: "red",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "8px"
                    }}
                  >
                    Claim not found in VVPM
                    <SyncOutlined style={{ marginLeft: "8px" }} />
                  </span>
                </span>
              </>
            )
          }
          tag={capitalizeFirstLetter(data.keymessage)}
          title={data.text}
        />
      ),
      children:
        type === "claims" ? (
          <Children references={[(data as EquivalentClaimType).reference]} footnotes={[(data as EquivalentClaimType).footnote]} />
        ) : null
    }
  ];
  return (
    <Radio.Group onChange={onChange} value={selectedId} style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          // width: "600px",
          border: "1px solid lightGray",
          borderRadius: "10px",
          overflow: "hidden",
          height: "100%"
        }}
      >
        <Collapse
          items={items}
          expandIconPosition="end"
          bordered={false}
          onChange={
            setSelectedItemIndices
              ? () =>
                  setSelectedItemIndices((prevIndices: number[]) => {
                    if (prevIndices.includes(Index)) {
                      return prevIndices.filter((item: number) => item !== Index);
                    } else {
                      return [...prevIndices, Index];
                    }
                  })
              : () => {
                  setExpanded(!expanded);
                }
          }
          activeKey={selectedItemIndices ? (selectedItemIndices?.includes(Index) ? 1 : 0) : expanded ? 1 : 0}
        />
        {type !== "claims" && (
          <Footer
            onClick={() => setContentOpen((pre) => !pre)}
            tag="VVPM"
            rating="98%"
            btnName={isSuggestionsAvailable ? (contentOpen ? "Hide Suggestions" : "Load More Suggestions") : "Load More Suggestions"}
          />
        )}

        {/* <Col>{contentOpen && <SuggestedContent data={data} />}</Col> */}

        <Col
          style={{
            padding: ".5rem",
            gap: "1rem",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#fafafa"
          }}
        >
          {isSuggestionsAvailable &&
            contentOpen &&
            (data as EquivalentNonClaimType)?.suggestions?.map((item) => {
              return <SuggestedContent key={item.id} data={item} />;
            })}
        </Col>
      </div>
    </Radio.Group>
  );
}
