import { useEffect, useState } from "react";
import { ContentNewtonState } from "../../../../../Store/reducers/_contentNewtonReducer";
import { useAppDispatch, useAppSelector } from "../../../../../Store/hooks";
import { sseAddModules, sseListModules } from "../../../../../Store/requests/_contentNewtonRequests";
import { Button, notification, Skeleton } from "antd";
import { ModuleType, SuggesteModuleSSEData } from "../types";
import { SSEMessage, SSEMessageList } from "../../../components/common/DisplaySSEMessages";
import {
  setCNCurrentIndexDoneNextIndexProcess,
  setCNOrderId,
  setCNSystemSuggestedModulesList
} from "../../../../../Store/actions/_contentNewtonActions";
import { ModulesListing } from "../ModulesListing/ModulesListing";
import { ConversationStepsMapping, ConversationStepsName } from "../../../resources/constants";
import { SSMRendering } from "./SSMRendering";

export const SystemSuggestedModules = () => {
  const { currentStep, orderId, selectedModuleIds, systemSuggestedModulesList }: ContentNewtonState = useAppSelector(
    (state) => state.contentNewtonReducer
  );

  const [sseMessages, setSSEMessages] = useState<SSEMessage[]>([]);
  const [ssePostMessages, setSSEPostMessages] = useState<SSEMessage[]>([]); //Messages received after getting the suggested modules data

  const [modulesList, setModulesList] = useState<any[]>([]);
  const [systemSuggestedModules, setSystemSuggestedModules] = useState<any[]>([]);
  // const [orderId, setOrderId] = useState(0);
  const [loading, setLoading] = useState(!systemSuggestedModulesList.modules.length);
  const [errorMessage, setErrorMessage] = useState("");
  const [sseClosed, setSseClosed] = useState(!!systemSuggestedModulesList.modules.length);
  const dispatch = useAppDispatch();

  console.log({ sseMessages, ssePostMessages, modulesList, systemSuggestedModules });

  useEffect(() => {
    setModulesList(systemSuggestedModulesList.modules);
    setSystemSuggestedModules(systemSuggestedModulesList.system_suggested_modules);
  }, [systemSuggestedModulesList]);

  // Simulate SSE receiving new messages one by one
  // useEffect(() => {
  //   // const mockSSEData: Message[] = [
  //   //   { type: "main", text: "Main message without steps" },
  //   //   { type: "step", text: "Step 1" },
  //   //   { type: "substep", text: "Sub-step 1.1" },
  //   //   { type: "substep", text: "Sub-step 1.2" },
  //   //   { type: "step", text: "Step 2" },
  //   //   { type: "substep", text: "Step 2.1" },
  //   //   { type: "main", text: "Main Step" },
  //   //   { type: "step" } // Message without text, used to signal completion of last step/substep
  //   // ];

  //   const mockSSEData: Message[] = mockSSEMessages.map(({ message }) => ({
  //     type: (message?.type || "main") as Message["type"],
  //     text: message?.text || ""
  //   }));

  //   mockSSEData.forEach((msg, index) => {
  //     setTimeout(() => {
  //       setSSEMessages((prev) => [...prev, msg]);
  //       //@ts-ignore
  //       if (msg.data) {
  //         //@ts-ignore
  //         setModulesList(msg.data);
  //       }
  //     }, index * 1000 * 0); // Simulate delay between SSE events
  //   });
  // }, []);

  useEffect(() => {
    if (systemSuggestedModulesList.modules?.length) {
      return;
    }

    const sse = sseAddModules(selectedModuleIds);

    sse.addEventListener("pending", async (data: { data: string }) => {
      console.log(`pending`, data?.data);
      setLoading(false);

      let parsedData: SuggesteModuleSSEData;
      try {
        parsedData = JSON.parse(data.data);
        // parsedData = sseMessages[messageNumber];
        // setMessageNumber(messageNumber + 1);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        sse.close();
        return;
      }

      if (parsedData) {
        if (parsedData.message) {
          setSSEMessages((prev) => [...prev, parsedData.message]);
        }
        if (parsedData.data) {
          let { order_id, modules, system_suggested_modules } = parsedData.data;
          dispatch(setCNOrderId(order_id));
          modules && dispatch(setCNSystemSuggestedModulesList({ order_id, modules, system_suggested_modules }));
          setSSEMessages((prev) => [...prev, { type: "main" }]); //Blank message to finish the last step
        }
      }
    });

    sse.addEventListener("end", async (data: { data: string }) => {
      console.log(`sse.addEventListener("end"`, data?.data);
      sse.close();
      setLoading(false);

      let parsedData: SuggesteModuleSSEData;
      try {
        parsedData = JSON.parse(data.data);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        sse.close();
        return;
      }

      setSseClosed(true);

      if (parsedData?.message) {
        setSSEPostMessages((prev) => [...prev, parsedData.message, { type: "main" }]); //Blank message to finish the last step
      }
    });

    sse.onerror = (err) => {
      notification.error({ message: `SSE Module Listing Error!\n ${err}` });
      sse.close();
      setLoading(false);
    };
  }, [systemSuggestedModulesList.modules]);

  const isSystemSuggestedModuleStep = ConversationStepsMapping[currentStep] === ConversationStepsName.MODULES_SYSTEM_SUGGESTED;

  return (
    <div>
      {loading ? (
        <Skeleton active />
      ) : errorMessage ? (
        <div>{errorMessage}</div>
      ) : (
        <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "5px" }}>
          <SSEMessageList sseMessages={sseMessages} />
          {!!modulesList.length && <SSMRendering modules={modulesList} systemSuggestedModules={systemSuggestedModules} />}
          <SSEMessageList sseMessages={ssePostMessages} />
          <div
            style={{
              display: orderId && isSystemSuggestedModuleStep && sseClosed ? "flex" : "none",
              justifyContent: "flex-end"
            }}
          >
            <Button type="primary" disabled={!selectedModuleIds.length} onClick={() => dispatch(setCNCurrentIndexDoneNextIndexProcess())}>
              Proceed
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
