import { FC, useMemo, useState, useRef } from "react";
import { Avatar, Button, Card, Col, Row, Typography, Space, Checkbox, Input, Image, Tooltip, Drawer, Spin, Modal, Menu } from "antd";
import {
  AlignLeftOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import { nextinsightsecondcardindividualstyle, nextinsightsecondcardindividualstyledisable, nextinsightsecondextracardstyle } from "../StylesFolderForNextInsight/_NextInsightPageStyleConstant";

interface NextInsightPageProps {
  showComponentForChunks: (type: string, url: string) => void;
}

export const NextInsightDocs: FC<NextInsightPageProps> = ({ showComponentForChunks }) => {
  const { cardsData , searchType} = useSelector((state: any) => state.semanticSearchReducer);

  return (
    <>
      <Row style={{ padding: "10px", paddingTop: "10px", paddingBottom: "10px" }}>
        <Image src={PROJECT_ICONS.DOCS} style={{ paddingRight: "9px" }} />
        <p
          style={{
            paddingRight: "5px",
            fontSize: "16px",
            fontWeight: cardsData && cardsData.length >= 1 ? "bold" : "normal",
            color: "#66676B",
          }}
        >
          
          {searchType==="Web Search" || searchType==="Med Affairs Agent"?"Web URL(s)":"Document(s)"}
        </p>
        <p
          style={{
            paddingRight: "5px",
            fontSize: "16px",
            fontWeight: cardsData && cardsData.length >= 1 ? "bold" : "normal",
            color: "#66676B",
          }}
        >
          |
        </p>
        <p
          style={{
            paddingRight: "5px",
            fontSize: "16px",
            fontWeight: cardsData && cardsData.length >= 1 ? "bold" : "normal",
            color: "#66676B",
          }}
        >
          {cardsData ? (cardsData.length >= 10 ? cardsData.length : "0" + cardsData.length) : "00"}{" "}
        </p>
      </Row>
      <Row gutter={[24, 24]} style={{ paddingLeft: "30px", display: "flex", alignContent: "center" }}>
        {cardsData && cardsData.length > 0 ? (
          cardsData.length <= 4 ? (
            cardsData.map((document: any, index: number) => (
              <Col
                key={index}
                span={5}
                style={nextinsightsecondcardindividualstyle}
                onClick={() => {
                  showComponentForChunks("Document", document?.filename);
                }}
              >
<Image 
  src={searchType === "Web Search" || searchType==="Med Affairs Agent"? PROJECT_ICONS.WEBSEARCH : PROJECT_ICONS.PDF} 
  preview={false} 
  style={{height:searchType === "Web Search"  || searchType==="Med Affairs Agent" ?'18px':"26px"}}
/>
              </Col>
            ))
          ) : (
            <>
              {cardsData.slice(0, 3).map((document: any, index: number) => (
                <Col
                  key={index}
                  span={5}
                  style={nextinsightsecondcardindividualstyle}
                  onClick={() => {
                    showComponentForChunks("Document", document?.filename);
                  }}
                >
                  <Image 
  src={searchType === "Web Search"|| searchType==="Med Affairs Agent" ? PROJECT_ICONS.WEBSEARCH : PROJECT_ICONS.PDF} 
  preview={false} 
  style={{height:searchType === "Web Search" || searchType==="Med Affairs Agent" ?'18px':"26px"}}

/>
                </Col>
              ))}
              <Col
                span={5}
                style={nextinsightsecondextracardstyle}
                onClick={() => {
                  showComponentForChunks("Document", cardsData[0]?.filename);
                }}
              >
                <div style={{ color: "white", fontWeight: "600", fontSize: "15px" }}>
                  +0{cardsData.length - 3}
                </div>
              </Col>
            </>
          )
        ) : (
          <Col span={5} style={nextinsightsecondcardindividualstyledisable}>
            <Image 
  src={searchType === "Web Search"|| searchType==="Med Affairs Agent" ? PROJECT_ICONS.WEBSEARCH : PROJECT_ICONS.PDF} 
  preview={false} 
  style={{height:searchType === "Web Search"|| searchType==="Med Affairs Agent" ?'18px':"26px"}}
/>
          </Col>
        )}
      </Row>
    </>
  );
};
