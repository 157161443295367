import React, { FC, useState, useMemo, useEffect, useRef } from "react";
import { Row, Col, Input, Button, Avatar, Image, Modal, Checkbox, Card, Space, Typography, Spin, Tooltip, Switch, Drawer } from "antd";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import { useSelector } from "react-redux";
import { getChunkaByName, getQuestionRecommendation } from "../../../../Store/requests/_semanticSearchRequests";
import _localStorageService from "../../../../Services/_localStorageService";
import { useTranslation } from "react-i18next";
import "./StylesFolderForNextInsight/_ModalComponent.css";
import { Toggler } from "./ModalComponentChildren/_Toggler";
import { DocumentSimilarity } from "./ModalComponentChildren/_DocumentSimilarity";
import { ImageSemantic } from "./ModalComponentChildren/_ImageSemantic";
import { AudioSemantic } from "./ModalComponentChildren/_AudioSemantic";
import { VideoSemantic } from "./ModalComponentChildren/_VideoSemantic";
import { SwitchingFileComponent } from "./ModalComponentChildren/_SwitchingFileComponent";
interface ModalComponentProps {
  fileType: string | null;
  onHide: () => void;
  imageName: any;
  fileName: any;
  videoName: any;
  audioName: any;
}
interface SSEData {
  id: any;
  created_at: string;
  question: string;
  answer: string;
  interested: any | null;
  page_number: number | null;
  k: number;
  score_threshold: number;
  status: string;
}

export const ModalComponent: React.FC<ModalComponentProps> = ({ fileType, onHide, imageName, fileName, videoName, audioName }) => {
  const { cardsData, cardData, cardDataCollection, cardsImageData, cardsAudioData, cardsVideoData ,searchType} = useSelector(
    (state: any) => state?.semanticSearchReducer
  );
  const [modalopenfiletype, setmodalopenfiletype] = useState<string | null>(fileType);
  const [paradata, setParadata] = useState<any>(null);
  const [selectedBoxName, setSelectedBoxName] = useState<string>(fileName);
  const [selectedImageBoxUrl, setSelectedImageBoxUrl] = useState<any>(imageName);
  const [imageparadata, setImageParaData] = useState<any>(null);
  const [selectedVideoBoxUrl, setSelectedVideoBoxUrl] = useState<any>(videoName);
  const [videoparadata, setVideoParaData] = useState<any>(null);
  const [selectedAudioBoxUrl, setSelectedAudioBoxUrl] = useState<any>(audioName);
  const [audioparadata, setAudioParaData] = useState<any>(null);
  const [isLoadingCoffee, setIsLoadingCoffee] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [filename, setFileName] = useState<string>("");
  const [s3_url, sets3_url] = useState("");

  const showDrawer = (s: string) => {
    if (modalopenfiletype === "Document") {
      setFileName(s);
      setDrawerVisible(true);
    }
  };

  useEffect(() => {
    const fetchParaDataAndUpdateState = async () => {
      try {
        const response = await getChunkaByName({ file_name: selectedBoxName, sse_id: cardData?.id,is_internet_search:searchType==="Web Search"?true:false});
        setParadata(response?.data?.chunks?.chunks);
        sets3_url(response?.data?.s3_url);
        setIsLoadingCoffee(false);
      } catch (error) {
        console.error("Error fetching para data:", error);
        setIsLoadingCoffee(false);
      }
    };
    if (modalopenfiletype === "Document") {
      setIsLoadingCoffee(true);
      fetchParaDataAndUpdateState();
    }
  }, [selectedBoxName]);
  useEffect(() => {
    const fetchParaDataAndUpdateStateImage = async () => {
      try {
  
        const response = cardsImageData.find((item: any) => item.url === selectedImageBoxUrl);
        setImageParaData(response);
        setIsLoadingCoffee(false);
      } catch (error) {
        console.error("Error fetching para data:", error);
        setIsLoadingCoffee(false);
      }
    };
    if (modalopenfiletype === "Image") {
      setIsLoadingCoffee(true);
      fetchParaDataAndUpdateStateImage();
    }
  }, [selectedImageBoxUrl]);
  useEffect(() => {
    const fetchParaDataAndUpdateStateVideo = async () => {
      try {
        console.log(selectedVideoBoxUrl +"this is one")
        const response = cardsVideoData.find((item: any) => item.s3_url === selectedVideoBoxUrl);
        console.log(response)
        setVideoParaData(response);
        setIsLoadingCoffee(false);
      } catch (error) {
        console.error("Error fetching para data:", error);
        setIsLoadingCoffee(false);
      }
    };
    if (modalopenfiletype === "Video") {
      setIsLoadingCoffee(true);
      fetchParaDataAndUpdateStateVideo();
    }
  }, [selectedVideoBoxUrl]);

  useEffect(() => {
    const fetchParaDataAndUpdateStateAudio = async () => {
      try {
        const response = cardsAudioData.find((item: any) => item.s3_url === selectedAudioBoxUrl);
        setAudioParaData(response);
        setIsLoadingCoffee(false);
      } catch (error) {
        console.error("Error fetching para data:", error);
        setIsLoadingCoffee(false);
      }
    };
    if (modalopenfiletype === "Audio") {
      setIsLoadingCoffee(true);
      fetchParaDataAndUpdateStateAudio();
    }
  }, [selectedAudioBoxUrl]);

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backdropFilter: "blur(6px)", // Background blur effect
          background: "rgba(44, 78, 101, 0.5)", // semi-transparent background with the color #2C4E65
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "transform 1s ease-in-out", // transition effect
          zIndex: 9,
        }}
      >
        <div
          style={{ position: "fixed", zIndex: 99999999999, top: 16, fontWeight: "600", fontSize: "30px", right: 30, cursor: "pointer" }}
          onClick={onHide}
        >
          X
        </div>
        <Col span={18} style={{ paddingLeft: "10px", marginTop: "10px" }}>
          <Toggler
            setSelectedBoxName={setSelectedBoxName}
            setSelectedAudioBoxUrl={setSelectedAudioBoxUrl}
            setSelectedImageBoxUrl={setSelectedImageBoxUrl}
            setSelectedVideoBoxUrl={setSelectedVideoBoxUrl}
            setmodalopenfiletype={setmodalopenfiletype}
            modalopenfiletype={modalopenfiletype}
          />
          {modalopenfiletype === "Document" ? (
            <>
              <DocumentSimilarity
                modalopenfiletype={modalopenfiletype}
                isLoadingCoffee={isLoadingCoffee}
                paradata={paradata}
                selectedBoxName={selectedBoxName}
                s3_url={s3_url}
              ></DocumentSimilarity>
            </>
          ) : modalopenfiletype === "Image" ? (
            <>
              <ImageSemantic modalopenfiletype={modalopenfiletype} imageparadata={imageparadata} isLoadingCoffee={isLoadingCoffee}></ImageSemantic>
            </>
          ) : modalopenfiletype === "Audio" ? (
            <>
              <AudioSemantic modalopenfiletype={modalopenfiletype} audioparadata={audioparadata} isLoadingCoffee={isLoadingCoffee} />
            </>
          ) : modalopenfiletype === "Video" ? (
            <>
              <VideoSemantic modalopenfiletype={modalopenfiletype} videoparadata={videoparadata} isLoadingCoffee={isLoadingCoffee} />
            </>
          ) : (
            <></>
          )}
        </Col>

        <Col span={4} style={{ paddingLeft: "10px", marginTop: "46px" }}>
          <SwitchingFileComponent
            modalopenfiletype={modalopenfiletype}
            selectedAudioBoxUrl={selectedAudioBoxUrl}
            selectedImageBoxUrl={selectedImageBoxUrl}
            selectedVideoBoxUrl={selectedVideoBoxUrl}
            selectedBoxName={selectedBoxName}
            setSelectedAudioBoxUrl={setSelectedAudioBoxUrl}
            setSelectedBoxName={setSelectedBoxName}
            setSelectedImageBoxUrl={setSelectedImageBoxUrl}
            setSelectedVideoBoxUrl={setSelectedVideoBoxUrl}
          ></SwitchingFileComponent>
        </Col>
        <div className="chatmessanger">
          <Image src={PROJECT_ICONS.CHATMESSANGER} preview={false} />
        </div>
      </div>
    </>
  );
};
