import { FC, useMemo, useState, useRef } from "react";
import { Avatar, Button, Card, Col, Row, Typography, Space, Checkbox, Input, Image, Tooltip, Drawer, Spin, Modal, Menu } from "antd";
import {
  AlignLeftOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";

import "./StylesFolderForNextInsight/_NextInsightPage.css";
import { setPreviousId, setNextId, setLoadingforReportGeneration, setReportGenerationType } from "../../../../Store/actions/_semanticSearchActions";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useEffect } from "react";
// import Markdown from "markdown-to-jsx";
import { ModalComponent } from "./_ModalComponent";
import { ModalComponentForFormat } from "./_ModalComponentForFormat";
import { ModalComponentForExport } from "./_ModalComponentForExport";
import { ModalLoaderForPptGeneration } from "./Modals/_ModalLoaderForPptGeneration";
import { ChatWithDocumentComponent } from "../ChatWithDocument/_ChatWithDocumentComponent";
import { ComponentNextInsightProps, Folder } from "../Core/constants";
import { ReferenceFilesDrawer } from "../ReferenceFilesDrawer/_ReferenceFilesDrawer";
import { NextInsightSimilaritySearch } from "./NEXTInsightComponents/_NextInsightSimilaritySearch";
import { NextInsightSemanticAnswer } from "./NEXTInsightComponents/_NextInsightSemanticAnswer";

export const NextInsightPage: React.FC<ComponentNextInsightProps> = ({
  stepperForinternalVarB,
  setStepperForInternalVarb,
  internalVarA,
  setInternalVarA,
  internalVarB,
  setInternalVarB,
  columns,
  setColumns,
}) => {
  const {
    cardDataCollection,
    proModeScreen,
    loadingForReportGeneration,

  } = useSelector((state: any) => state?.semanticSearchReducer);
  const dispatch = useDispatch();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [filename, setFileName] = useState<string>("");
  const [componentVisible, setComponentVisible] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState<string | null>("");
  const [drawerVisibleforReferenceFiles, setDrawerVisibleforReferenceFiles] = useState(false);
  const [componentFormatVisible, setComponentFormatVisible] = useState(false);
  const [drawerimageNameforsimilaritysearch, setdrawerimageforsimilaritysearch] = useState("");
  const [drawerfilenameforsimilaritysearch, setdrawerfilenameforsimilaritysearch] = useState<any>("");
  const [drawervideoNameforsimilaritysearch, setdrawervideoforsimilaritysearch] = useState("");
  const [draweraudioNameforsimilaritysearch, setdraweraudioforsimilaritysearch] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuItems, setmenuItems] = useState([]);
  const [isLoadingForPro, setIsLoadingForPro] = useState(proModeScreen ? true : false);

  const menuRef = useRef<HTMLDivElement | null>(null);
  const menuRefConversation = useRef<HTMLDivElement | null>(null);
  
  
  const handleOutsideClick = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsMenuOpen(false);
    }
    if (menuRefConversation.current && !menuRefConversation.current.contains(event.target as Node)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isMenuOpen]);


  useEffect(() => {
    // Set isLoadingForPro to true initially
    if (proModeScreen) {
      setIsLoadingForPro(true);

      // After 3 seconds, set isLoadingForPro to false
      const timeout = setTimeout(() => {
        setIsLoadingForPro(false);
      }, 5000);

      // Clean up the timeout to avoid memory leaks
      return () => clearTimeout(timeout);
    }
  }, []); // Empty


  const showModalForFormat = () => {
    setComponentFormatVisible(true);
  };

  const hideModalForFormat = () => {
    setComponentFormatVisible(false);
  };

  const showComponentForChunks = (s: string, name: string) => {
    // Create an object to map file types to state updates
    const fileTypeState: Record<string, () => void> = {
      "Document": () => {
        setdrawerfilenameforsimilaritysearch(name);
      },
      "Image": () => {
        setdrawerimageforsimilaritysearch(name);
      },
      "Video": () => {
        setdrawervideoforsimilaritysearch(name);
        console.log("opened modal with " + name)
      },
      "Audio": () => {
        setdraweraudioforsimilaritysearch(name);
      },
    };
  
    // Set default state for unknown types
    const setDefaultState = () => {
      setdrawerfilenameforsimilaritysearch("");
      setdrawerimageforsimilaritysearch("");
      setdrawervideoforsimilaritysearch("");
      setdraweraudioforsimilaritysearch("");
    };
  
    // Set component visibility and selected file type
    setComponentVisible(true);
    setSelectedFileType(s);
    
    // Reset all types first
    setDefaultState();
    
    // Apply the specific update based on the file type
    if (fileTypeState[s]) {
      fileTypeState[s]();
    } else {
      // Handle unknown file types if needed
      console.warn(`Unknown file type: ${s}`);
    }
  };
  
  const hideComponent = () => {
    setComponentVisible(false);
  };

  useEffect(() => {
    if (drawerVisible) {
      console.log("It is working opening");
    }
  }, [drawerVisible]);

  const showDrawer = (s: string) => {
    setFileName(s);
    setDrawerVisible(true);
  };

  const openS3LinkInNewTab = (link: any) => {
    window.open(link, "_blank");
  };

  console.log(cardDataCollection);
  return (
    <>
      <Col span={17} style={{ paddingLeft: "20px" }} className="next-insight-page">
        <Card className="card-element">
         <NextInsightSemanticAnswer showDrawer={showDrawer} showModalForFormat={showModalForFormat} setDrawerVisibleforReferenceFiles={setDrawerVisibleforReferenceFiles}></NextInsightSemanticAnswer>
        </Card>
      </Col>

      <Col span={7} style={{ paddingLeft: "20px" }}>
        <Card className="card-element2">
         <NextInsightSimilaritySearch showComponentForChunks={showComponentForChunks}/>
        </Card>
      </Col>

      {drawerVisibleforReferenceFiles && (
        <ReferenceFilesDrawer drawerVisibleforReferenceFiles={drawerVisibleforReferenceFiles} setDrawerVisibleforReferenceFiles={setDrawerVisibleforReferenceFiles} openS3LinkInNewTab={openS3LinkInNewTab} showDrawer={showDrawer}/>
      )}

      {drawerVisible && (
        <ChatWithDocumentComponent
          drawervisible={drawerVisible}
          filenamefromnextinsight={filename}
          setdrawervisible={setDrawerVisible}
          setfilename={setFileName}
        />
      )}
      {componentVisible && (
        <ModalComponent
          onHide={hideComponent}
          fileType={selectedFileType}
          imageName={drawerimageNameforsimilaritysearch}
          videoName={drawervideoNameforsimilaritysearch}
          audioName={draweraudioNameforsimilaritysearch}
          fileName={drawerfilenameforsimilaritysearch}
        />
      )}
      {componentFormatVisible && (
        <ModalComponentForExport
          componentFormatVisible={componentFormatVisible}
          onHide={hideModalForFormat}
          stepperForinternalVarB={stepperForinternalVarB}
          setStepperForInternalVarb={setStepperForInternalVarb}
          internalVarA={internalVarA}
          setInternalVarA={setInternalVarA}
          internalVarB={internalVarB}
          setInternalVarB={setInternalVarB}
          columns={columns}
          setColumns={setColumns}
        />
      )}
      {loadingForReportGeneration && <ModalLoaderForPptGeneration />}
    </>
  );
};
