import { SendOutlined } from "@ant-design/icons";
import { Button, Input, InputRef } from "antd";
import { useState, KeyboardEvent as ReactKeyboardEvent, useEffect, useRef, LegacyRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import {
  setCNCurrentIndexData,
  setCNCurrentIndexDoneNextIndexProcess,
  setCNMaketAndLanguageInputPrompt
} from "../../../../Store/actions/_contentNewtonActions";
import { ContentNewtonState } from "../../../../Store/reducers/_contentNewtonReducer";

export const MarketAndLanguageInputPrompt = ({ index }: { index: number }) => {
  const { marketAndLanguageInputPrompt, conversationSteps }: ContentNewtonState = useAppSelector((state) => state.contentNewtonReducer);

  const dispatch = useAppDispatch();
  const [inputPrompt, setInputPrompt] = useState(marketAndLanguageInputPrompt);
  const [showMessage, setShowMessage] = useState(false || !!marketAndLanguageInputPrompt);
  const ref = useRef<InputRef>();

  console.log("index", index);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  const getStoredPrompt = () => {
    return conversationSteps[index].data;
  };

  const isCurrentStepDone = () => {
    return conversationSteps[index].status === "Done";
  };

  const handleSend = () => {
    if (inputPrompt?.trim()) {
      dispatch(setCNMaketAndLanguageInputPrompt(inputPrompt.trim()));
      dispatch(setCNCurrentIndexData(inputPrompt.trim()));
      dispatch(setCNCurrentIndexDoneNextIndexProcess());
      setShowMessage(true);
    }
  };

  const handleKeyPress = (e: ReactKeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  return (
    <div style={{ display: "flex", gap: "5px", justifyContent: "flex-end" }}>
      {!isCurrentStepDone() && (
        <>
          <Input
            ref={ref as LegacyRef<InputRef>}
            style={{ width: "450px" }}
            value={inputPrompt}
            placeholder="Enter Prompt"
            onChange={(e) => setInputPrompt(e.target.value)}
            onKeyDown={handleKeyPress}
          />
          <Button icon={<SendOutlined />} onClick={handleSend} disabled={!inputPrompt?.trim()} />
        </>
      )}
      {isCurrentStepDone() && <div>{getStoredPrompt() || inputPrompt}</div>}
    </div>
  );
};
