import { FC, useEffect, useState } from "react";
import { Row, Col, Button, Checkbox } from "antd";
import _localStorageService from "../../../../Services/_localStorageService";
import { SSE } from "sse.js";
import { useSelector, useDispatch } from "react-redux";
import { FileForChatWithDoc, setSearchQuery } from "../../../../Store/actions/_semanticSearchActions";
import "./StylesFolderForNextInsight/_MultiModal.css";

interface ViewFilesProps {
  handleAdvancedSearchClose: () => void;
}

export const ViewFiles: FC<ViewFilesProps> = ({ handleAdvancedSearchClose }) => {
  const { fileForChatWithDoc } = useSelector((state: any) => state.semanticSearchReducer);
  const dispatch = useDispatch();
  
  // Local state to keep track of selected files
  const [selectedFiles, setSelectedFiles] = useState<string[]>(fileForChatWithDoc);

  // Function to handle checkbox toggle
  const handleCheckboxChange = (file: string) => {
    setSelectedFiles((prev) => {
      if (prev.includes(file)) {
        return prev.filter((f) => f !== file); // Unselect the file
      } else {
        return [...prev, file]; // Select the file
      }
    });
  };

  return (
    <>
      <div className="multillm-container">
        <div style={{ fontWeight: 'bold', marginTop: '20px', marginLeft: '30px', fontSize: '16px' }}>
          All Files ({fileForChatWithDoc.length})
        </div>
        <div style={{ height: '350px', overflowY: 'auto' }}>
          {fileForChatWithDoc.map((file: string) => (
            <div key={file} style={{ marginLeft: '30px',paddingTop:'15px' }}>
              <Checkbox
                checked={selectedFiles.includes(file)}
                onChange={() => handleCheckboxChange(file)}
              >
                {file} {/* Display file name or description */}
              </Checkbox>
            </div>
          ))}
        </div>
        <div style={{display:'flex',justifyContent:'flex-end',width:'100%',padding:'20px'}}>
        <Button
        type="link"
          onClick={handleAdvancedSearchClose}
          style={{ marginTop: '10px' ,color:'rgb(30, 77, 161)'}}
        >
          CANCEL
        </Button>
        <Button
        type="link"
          onClick={()=>{dispatch(FileForChatWithDoc(selectedFiles)) ;   handleAdvancedSearchClose()}}
          style={{ marginTop: '10px',fontWeight:'bold',color:'rgb(30, 77, 161)'}}
        >
          SUBMIT
        </Button>
        </div>
      </div>
    </>
  );
};
