import React, { LegacyRef, useEffect, useRef } from "react";
import "./UserMessage.css";
import { ConversationStep } from "../../../resources/types";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";

interface MessageProps {
  chatData: ConversationStep;
  children: React.ReactNode;
}

const UserMessage: React.FC<MessageProps> = ({ chatData, children }) => {
  const currentElRef = useRef<HTMLDivElement>();
  const { timestamp, titleMessage } = chatData;

  useEffect(() => {
    if (currentElRef.current) {
      setTimeout(() => {
        currentElRef.current && currentElRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 100);
    }
  }, [currentElRef]);

  return (
    <div className="user-message-container" ref={currentElRef as LegacyRef<HTMLDivElement>}>
      <div className="message-container">
        <div className="timestamp">{timestamp}</div>
        <div className="message-content">
          {titleMessage && <div className="message" dangerouslySetInnerHTML={{ __html: titleMessage || "" }}></div>}
          <div className="content">{children}</div>
        </div>
        {/* <div className="message">{titleMessage}</div> */}
      </div>
      <div className="message-icons">
        {/* <img src={PROJECT_ICONS.CN_EDIT_MESSAGE_ICON} alt="Edit" className="edit-icon icon" />
        <img src={PROJECT_ICONS.CN_PIN_MESSAGE_ICON} alt="Pin" className="pin-icon icon" /> */}
        <img src={PROJECT_ICONS.CN_USER_AVATAR_ICON} alt="Avatar" className="avatar" />
      </div>
    </div>
  );
};

export default UserMessage;
