import { SSE } from "sse.js";
import { siteConfig } from "../../Services";
import { apiResponse } from "../../Utils/_gTypes";
import { handleGetDataFromApi, handlePutDataFromApi } from "../../Utils";
import { notification } from "antd";
import { setSessionExpiredFlagAction } from "../actions/_authActions";

const BaseURL = process.env.REACT_APP_API_URL_FOR_CONTENT_NEWTON_DEV;

const getUserID = () => "11";

const getBaseURL = () => {
  return localStorage.getItem("contentNewtonURL") || BaseURL;
};

export const SSE_CN = (url: string, payload?: string) => {
  var headers: Record<string, string> = {
    Authorization: localStorage.getItem(siteConfig.ACCESS_TOKEN) as string
  };
  const sse = new SSE(getBaseURL() + url, { headers, payload });
  sse.onerror = (err) => {
    notification.error({ message: `SSE Error!\n ${err}` });

    try {
      let error = JSON.parse(err.data) as { detail: string };
      if (error?.detail?.toLowerCase().includes("session")) {
        //@ts-ignore
        window.store?.dispatch(setSessionExpiredFlagAction(true));
      }
    } catch (err) {}
    console.log("SSE_CN sse.onerror", { err });
  };

  return sse;
};

export const sseListModules = (prompt: string) => SSE_CN(siteConfig.CN_LIST_MODULES + `?prompt=${prompt}`);

// export const sseAddModules = (selectedModuleIds: number[]) => SSE_CN(siteConfig.CN_LIST_MODULES, JSON.stringify(selectedModuleIds));
export const sseAddModules = (selectedModuleIds: number[]) =>
  SSE_CN(siteConfig.CN_LIST_MODULES + `?module_list=${selectedModuleIds.join()}`, "post method");

export const sseGetTemplates = (orderId: number) => SSE_CN(siteConfig.CN_GET_TEMPLATES + `?order_id=${orderId}`);

export const sseGetFinalAsset = (orderId: number, templateId: number) =>
  SSE_CN(siteConfig.CN_FINAL_ASSET + `?order_id=${orderId}&template_id=${templateId}`, "post method");

export const getFinalAssetURL = async (assetId: number) => {
  const res: apiResponse = await handleGetDataFromApi(siteConfig.CN_FINAL_ASSET + `/${assetId}/download`);
  const { status, data } = res;

  if (status !== 200) {
    return undefined;
  }
  return data;
};

export const saveFinalAssetData = async (assetId: number, html: string) => {
  const res: apiResponse = await handlePutDataFromApi(siteConfig.CN_FINAL_ASSET + `/${assetId}`, { asset_data: html });
  return res?.data;
};

export const sseLocaliseAsset = (order_id: number, prompt: string) => SSE_CN(siteConfig.CN_LOCALISE_ASSETS, JSON.stringify({ order_id, prompt }));

export const sseGetLocalisedAssets = (order_id: number) => SSE_CN(siteConfig.CN_GET_LOCALISED_ASSETS, JSON.stringify({ order_id }));

export const sseTranslateAsset = (order_id: number, prompt: string) => SSE_CN(siteConfig.CN_TRANSLATE_ASSETS, JSON.stringify({ order_id, prompt }));

export const sseGetTranslatedAssets = (order_id: number) => SSE_CN(siteConfig.CN_GET_TRANSLATED_ASSETS, JSON.stringify({ order_id }));
