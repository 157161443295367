import React, { FC, useState, useMemo, useEffect, useRef } from "react";
import debounce from "lodash/debounce";
import { Row, Col, Input, Button, Avatar, Image, Modal, Checkbox, Card, Space, Typography, Spin, Tooltip, Switch } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setEncodedSearchQueryAction,
  setSearchQuery,
  setAdvancedFilter,
  setAdvancedFilterStatus,
  setFilterListAction,
  setInitialFolders,
  setSemanticSearchId,
  setKvalue,
  setFormat,
  setEncodedSemanticSearchId,
  setActiveCardType,
  setLandingScreen,
  setProModeScreen,
  setProModeStatus,
  setConversationId,
  setCardDataCollection,
  setEditableScreen,
  setLoadingforReportGeneration,
  setReportGenerationType,
  setSlidesForReports,
  setPdfForReports,
  setLoadingforSwitching,
  setLLMModel,
  setSearchType,
  setBatchQAStatus,
  setBatchQAData,
  setBatchQAFolders,
  setFileSummariserStatus,
  FileForChatWithDoc,
  setGPTResearcher,
  setUpdateResearchAgentAction
} from "../../../../Store/actions/_semanticSearchActions";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import { setLoadingStatusAction, setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { setAddToReportRequest, getDocuments, startConversation } from "../../../../Store/requests/_semanticSearchRequests";

import { CT_DASHBOARD_STATUS, handleCancelApiRequest, onDownload } from "../../../../Utils";
import { ResearchAgentType, SEMANTIC_SEARCH_CARD, SEMANTIC_SEARCH_MODULES } from ".././Core/constants";
import TextArea from "antd/es/input/TextArea";
import MultiModalSelection from "./_MultiModalSelection";
import { PromptSuggestion } from "./_PromptSuggestion";
import { ViewFiles } from "./_ViewFiles";

interface ComponentAProps {
  columns: string[];
  setColumns: React.Dispatch<React.SetStateAction<string[]>>;
  internalVarA: number;
  setInternalVarA: React.Dispatch<React.SetStateAction<number>>;
  internalVarB: string;
  setInternalVarB: React.Dispatch<React.SetStateAction<string>>;
  setStepperForInternalVarb: React.Dispatch<React.SetStateAction<number>>;
  stepperForinternalVarB: number;
  tagsForProMode: string[];
  setTagsForProMode: React.Dispatch<React.SetStateAction<string[]>>;
}

const { Option } = Select;

const suffix = <span style={{ color: "#66676B", fontSize: "10px", fontFamily: "Nunito-sans" }}>SEARCH</span>;

export const LandingInitialSearch: React.FC<ComponentAProps> = ({
  setTagsForProMode,
  tagsForProMode,
  stepperForinternalVarB,
  setStepperForInternalVarb,
  internalVarA,
  setInternalVarA,
  internalVarB,
  setInternalVarB,
  columns,
  setColumns
}) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const btnRef: any = useRef();
  const timerRef: any = useRef();

  const { searchQuery, advancedFilterStatus, advancedFilterList, advancedFilter, semanticEncodedSearchid, searchType } = useSelector(
    (state: any) => state?.semanticSearchReducer
  );

  const { researchAgent }: { researchAgent: ResearchAgentType } = useSelector((state: any) => state?.semanticSearchReducer);

  const { isLoading }: { isLoading: boolean } = useSelector((state: any) => state?.nonPersistedReducer);
  const [searchTerm, setSearchTerm] = useState<string>(searchType === "Summariser" ? searchQuery : "");
  const [kval, setKval] = useState<any>(4);
  const debouncedChangeHandler = useMemo(() => debounce((val: string) => handleInputChange(val), 350), []);
  const [inputValue, setInputValue] = useState("");

  const [localsearcjid, setlocalsearchid] = useState(semanticEncodedSearchid);
  const [promodeinitial, setPromodeInitial] = useState<boolean>(false); // State for toggle switch
  const [fontSize, setFontSize] = useState("38px");
  const [isadvancedSearchOpen, setisAdvancedSearchOpen] = useState<boolean>(false);
  const [isPromptSuggestion, setIsPromptSuggestion] = useState<boolean>(false);
  const [viewFile, setViewFile] = useState<boolean>(false);
  const [typedText, setTypedText] = useState<string>(searchType[0]); // State to store the typed out text
  const [currentIndex, setCurrentIndex] = useState(0);
  const typingSpeed = 500; // Typing speed in milliseconds
  const [isChecked, setIsChecked] = useState(false);

  console.log(researchAgent)

  // Function to handle the change in switch state
  const handleSwitchChange = (checked: any) => {
    setIsChecked(checked);
    dispatch(setGPTResearcher(checked));

    dispatch(setUpdateResearchAgentAction({ ...researchAgent, is_gpt_search: checked }));
  };

  useEffect(() => {
    // Reset typedText and currentIndex when searchQuery changes
    setTypedText(searchType);
  }, [searchType]); // Add searchQuery and searchType as dependencies

  // Array of questions
  const questions = ["What are the treatment strategies for Covid 19", "What is the role of SOD 1 GENE", "Role of SOD 1 GENE"];
  //
  const buttonStyle = {
    padding: "5px",
    color: "#034EA2",
    background: "#FFFFFF",
    border: "1px #034EA2 solid",
    borderRadius: "24px",
    fontSize: "13px",
    fontWeight: "400",
    marginRight: "10px",
    marginTop: "5px",
    cursor: "pointer"
  };

  useEffect(() => {
    const handleResize = () => {
      let fontSize;
      if (window.innerWidth <= 1500 && window.innerWidth > 1280) {
        fontSize = "38px";
      } else if (window.innerWidth <= 1280) {
        fontSize = "30px";
      } else {
        fontSize = "70px";
      }
      setFontSize(fontSize);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call the function initially to set dimensions
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Update input value only if search is not in progress
  const handlePromodeChange = (checked: boolean) => {
    setPromodeInitial(checked);
  };

  const getFoldersBackend = async () => {
    if (isLoading) {
    } else {
      try {
        // Step 3: Set isFetching to true when fetching advanced filters
        setIsFetching(true);

        const folderData = await getDocuments();
        if (folderData) {
          await dispatch(setInitialFolders(folderData?.data));
          dispatch(setLandingScreen(false));
          await dispatch(setAdvancedFilter(true));
        }
      } catch (error) {
        await dispatch(setInitialFolders([]));
        // dispatch(setAdvancedFilter(true));
        dispatch(setLandingScreen(true));

        dispatch(setNotificationMsgAction("Folders cannot be loaded for Filter"));
        console.error("Error fetching folder data:", error);
      } finally {
        // Step 4: Set isFetching back to false after fetching
        setIsFetching(false);
      }
    }
  };

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
      // handleCancelApiRequest();
    };
  }, []);
  console.log(searchType);

  useEffect(() => {
    if (!searchTerm) {
      console.log(semanticEncodedSearchid + "this is one now  currently looking");
      if (!localsearcjid) {
        dispatch(setEncodedSearchQueryAction(""));
        dispatch(setSearchQuery(""));
      }
    }
  }, [searchTerm]);

  useEffect(() => {
    setSearchTerm(searchQuery);
  }, [searchQuery]);

  const handleInputChange = (e: any) => {
    if (!isLoading) {
      setSearchTerm(e.target.value);
      setInputValue(e.target.value);
      if (e.target.value === "") {
        setEncodedSemanticSearchId(0);
        setSemanticSearchId(0);
        setlocalsearchid(0);
      }
    }
  };

  const savePrompt = async (isSave: boolean) => {
    handleStateInitialisationbeforequerying();
    if (!isSave) {
      handleResetOnError();
      return;
    }
    if (!searchTerm) return;

    dispatch(setSearchQuery(searchTerm));

    dispatch(setLoadingStatusAction(true));
    const res = await startConversation();
    if (!res) {
      handleResetOnError();
      return;
    }
    handleDisptachSemanticId(res);
  };

  const handleSearch = async (s: string) => {
    handleStateInitialisationbeforequerying();
    setSearchTerm(s);
    dispatch(setSearchQuery(s));
    dispatch(setLoadingStatusAction(true));
    const res = await startConversation();
    if (!res) {
      handleResetOnError();
      return;
    }
    handleDisptachSemanticId(res);
  };

  const handleStateInitialisationbeforequerying = async () => {
    dispatch(setActiveCardType(SEMANTIC_SEARCH_CARD.DOCUMENTS));
    dispatch(setCardDataCollection([]));
    setInternalVarA(10);
    setInternalVarB(t(I18N_MODULE_KEYS["semantic.search.result.formatoption.textsmall"]));
    setStepperForInternalVarb(3);
    console.log(t(I18N_MODULE_KEYS["semantic.search.result.formatoption.textsmall"]) + "this is beimg called as setintervalb");
    dispatch(setFormat(3));
    dispatch(setKvalue(kval));
    dispatch(setLoadingforReportGeneration(false));
    dispatch(setEditableScreen(false));
    dispatch(setReportGenerationType(""));
    dispatch(setSlidesForReports({}));
    dispatch(setPdfForReports({}));
    dispatch(setLoadingforSwitching(false));
    dispatch(setLandingScreen(false));
    setTagsForProMode([]);
    dispatch(setAdvancedFilter(false));
    dispatch(setProModeScreen(promodeinitial));
    dispatch(setProModeStatus(promodeinitial));
    dispatch(setFileSummariserStatus(false));
  };

  const handleResetOnError = async () => {
    setSearchTerm("");
    dispatch(setLandingScreen(true));
    dispatch(setEditableScreen(false));
    dispatch(setLoadingforReportGeneration(false));
    dispatch(setReportGenerationType(""));
    dispatch(setSearchQuery(""));
    setInputValue("");
    clearTimeout(timerRef.current);
    dispatch(setCardDataCollection([]));
    handleCancelApiRequest();
    dispatch(setLoadingStatusAction(false));
    dispatch(setEncodedSemanticSearchId(0));
    dispatch(setSemanticSearchId(0));
    dispatch(setEncodedSearchQueryAction(""));
    dispatch(setFormat(3))
    dispatch(setProModeScreen(false));
    dispatch(setProModeStatus(false));
    setTagsForProMode([]);
    dispatch(setConversationId(0));
    dispatch(setNotificationMsgAction("Error while fetching response"));
    dispatch(setBatchQAStatus(false));
    dispatch(setBatchQAData([]));
    dispatch(setBatchQAFolders([]));
    dispatch(setFileSummariserStatus(false));
  };

  const handleDisptachSemanticId = async (res: any) => {
    dispatch(setConversationId(res?.id));
    if (semanticEncodedSearchid === 0) {
      dispatch(setEncodedSemanticSearchId(1));
    } else if (semanticEncodedSearchid === 1) {
      dispatch(setEncodedSemanticSearchId(10));
    } else if (semanticEncodedSearchid === 10) {
      dispatch(setEncodedSemanticSearchId(1));
    }
  };

  const handleAdvancedSearch = async () => {
    dispatch(setSearchType("Local"));
    setisAdvancedSearchOpen(true);
  };
  const handleAdvancedSearchClose = async () => {
    setisAdvancedSearchOpen(false);
    setIsPromptSuggestion(false);
    setViewFile(false);
  };

  return (
    <>
      <div>
        <Col style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "0px", paddingLeft: "20px" }} span={9}>
          <div
            style={{
              background: "#1E4DA1",
              color: "white",
              padding: "5px",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              paddingRight: "15px",
              paddingLeft: "15px"
            }}
          >
            {typedText.toUpperCase()}
          </div>
        </Col>
      </div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "0px" }}>
        <Col span={20} style={{ paddingLeft: "70px" }}>
          <div style={{ color: "black", background: "white", borderRadius: "10px", boxShadow: "0px 0px 10px #0000001F" }}>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px" }}>
              <TextArea
                style={{
                  flex: "1",
                  border: "none",
                  borderRadius: "3px",
                  height: fontSize,
                  resize: "none"
                }}
                placeholder="Ask Anything..."
                value={searchTerm} // No value otherwise
                onChange={handleInputChange}
                readOnly={isLoading}
                disabled={isLoading}
                onKeyDownCapture={(e) => {
                  const { which } = e;

                  if (which === 13) {
                    btnRef?.current?.click();
                    if (!isLoading) {
                    }
                  }
                }}
              />
              <Button
                type="link"
                onClick={() => savePrompt(searchTerm && searchQuery && isLoading ? false : true)}
                ref={btnRef}
                style={{ padding: "20px" }}
                disabled={!searchTerm || isLoading ?  true :false}
              >
                <span style={{ color: "#66676B", fontSize: "20px" }}>
                  {searchTerm && searchQuery && isLoading ? (
                    t(I18N_MODULE_KEYS["semantic.search.input.heading.clear"])
                  ) : (
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        border: "2px solid #66676B",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <ArrowRightOutlined style={{ fontSize: "15px" }} />
                    </div>
                  )}
                </span>
              </Button>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Tooltip title="Advanced Search">
                <div style={{ paddingTop: "15px" }}>
                  <Button
                    type="link"
                    onClick={handleAdvancedSearch}
                    style={{ color: "#034EA2", font: "normal normal bold 600 16px/22px Nunito Sans" }}
                  >
                    <span style={{ height: "25px", fontWeight: "600", paddingLeft: "15px" }}>Advanced Search</span>
                  </Button>
                </div>
              </Tooltip>
              <div style={{ paddingTop: "25px", padding: "15px" }}>
                {searchType === "Summariser" ? (
                  <>
                    <Tooltip title="Prompt Suggestion">
                      <Button
                        type="link"
                        style={{ color: "#034EA2", font: "normal normal bold 600 16px/22px Nunito Sans" }}
                        onClick={() => {
                          setIsPromptSuggestion(true);
                        }}
                      >
                        <span style={{ height: "25px", fontWeight: "600", paddingLeft: "15px" }}>Prompt Suggestion</span>
                      </Button>
                    </Tooltip>
                    |
                    <Button
                      type="link"
                      style={{ color: "#034EA2", font: "normal normal bold 600 16px/22px Nunito Sans" }}
                      // onClick={()=>{setIsPromptSuggestion(true)}}
                      onClick={() => {
                        FileForChatWithDoc.length >= 1 ? setViewFile(true) : setViewFile(false);
                      }}
                    >
                      <span style={{ height: "25px", fontWeight: "600", paddingLeft: "15px" }}>View Files</span>
                    </Button>
                  </>
                ) : searchType === "Chat With Document" ? (
                  <>
                    <Button
                      type="link"
                      style={{ color: "#034EA2", font: "normal normal bold 600 16px/22px Nunito Sans" }}
                      // onClick={()=>{setIsPromptSuggestion(true)}}
                      onClick={() => {
                        FileForChatWithDoc.length >= 1 ? setViewFile(true) : setViewFile(false);
                      }}
                    >
                      <span style={{ height: "25px", fontWeight: "600", paddingLeft: "15px" }}>View Files</span>
                    </Button>
                  </>
                ) : searchType === "Web Search" ? (
                  <>
                    <>
                      <div style={{ marginTop: "5px" }}>
                        <label style={{ fontWeight: "bold" }}>Research Agent</label>
                        <Switch style={{ marginLeft: "10px" }} checked={isChecked} onChange={handleSwitchChange} />
                      </div>
                    </>
                  </>
                ) : !advancedFilterStatus ? (
                  <Tooltip title={t(I18N_MODULE_KEYS["semantic.search.header.advancedfilters"])}>
                    <Button
                      type="link"
                      onClick={getFoldersBackend}
                      style={{ color: "#034EA2", font: "normal normal bold 600 16px/22px Nunito Sans" }}
                    >
                      {isFetching && !advancedFilter ? (
                        <Spin style={{ paddingLeft: "15px" }} size="small" />
                      ) : (
                        <span style={{ height: "25px", fontWeight: "600", paddingLeft: "15px" }}>
                          {t(I18N_MODULE_KEYS["semantic.search.header.advancedfilters"])}
                        </span>
                      )}
                    </Button>
                  </Tooltip>
                ) : (
                  <>
                    {" "}
                    <Tooltip title={t(I18N_MODULE_KEYS["semantic.search.header.appliedfilters"])}>
                      <Button
                        type="link"
                        style={{
                          font: "normal normal normal 13px/22px Nunito Sans",
                          letterSpacing: "0px",
                          color: "#034EA2"
                        }}
                        onClick={() => {
                          dispatch(setNotificationMsgAction("Advanced Filters have been removed from your search"));
                          dispatch(setAdvancedFilterStatus(false));
                          dispatch(setFilterListAction([]));
                          console.log(advancedFilterList);

                          console.log(advancedFilterStatus);
                        }}
                      >
                        <span style={{ fontSize: "14px", fontWeight: "600", paddingLeft: "15px" }}>
                          {t(I18N_MODULE_KEYS["semantic.search.header.appliedfilters"])}
                        </span>
                        <span></span>
                      </Button>
                    </Tooltip>
                  </>
                )}
              </div>
            </div>
          </div>
        </Col>
      </div>
      <Col span={24} style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10px", gap: "10px" }}>
        <div style={{ color: "#66676B", fontSize: "12px" }}>Try Asking :</div>
        {questions.map((question, index) => (
          <div key={index} style={buttonStyle} onClick={() => handleSearch(question)}>
            {question}
          </div>
        ))}
      </Col>
      {isadvancedSearchOpen ? <MultiModalSelection handleAdvancedSearchClose={handleAdvancedSearchClose} /> : <></>}
      {isPromptSuggestion ? <PromptSuggestion handleAdvancedSearchClose={handleAdvancedSearchClose} /> : <></>}
      {viewFile ? <ViewFiles handleAdvancedSearchClose={handleAdvancedSearchClose} /> : <></>}
    </>
  );
};
