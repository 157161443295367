import React from "react";
export const Children: React.FC<{
  references: string[];
  footnotes: string[] | [];
}> = ({ references, footnotes }) => {
  return (
    <div style={{ textAlign: "left", paddingLeft: "1.5rem" }}>
      <div>
        <b>Reference:</b>
        <div>
          {references?.map((ref, i) => {
            return <p key={i}>{ref}</p>;
          })}
        </div>
      </div>
      {footnotes?.length ? (
        <div>
          <b>Footnotes: </b>
          {footnotes?.length ? footnotes?.map((note, i) => <p key={i}>{note}</p>) : "N/A"}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
