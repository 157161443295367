import { useEffect, useState } from "react";
import { Checkbox, Col, Row, Tooltip } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import "./TemplatesListing.css";
import { FinalAssetTemplateType } from "../../Module Selection/types";
import { useAppDispatch } from "../../../../../Store/hooks";
import { TemplatesPreviewModal } from "../TemplatesPreviewModal/TemplatesPreviewModal";
import { setCNSelectedTemplateId } from "../../../../../Store/actions/_contentNewtonActions";

export const TemplatesListing = ({ templateList }: { templateList: FinalAssetTemplateType[] }) => {
  const [selectedTemplateId, setSelectedTemplateId] = useState(0);
  const [openPreview, setOpenPreview] = useState(false);
  const [previewItem, setPreviewItem] = useState<FinalAssetTemplateType>();

  const dispatch = useAppDispatch();

  const handleSelectDeselect = (id: number) => {
    if (selectedTemplateId) {
      setSelectedTemplateId(0);
      //Global state update
      dispatch(setCNSelectedTemplateId(0));
    } else {
      setSelectedTemplateId(id);
      //Global state update
      dispatch(setCNSelectedTemplateId(id));
    }
  };

  // templateList?.flatMap((item, index) =>
  //   Array.from({ length: 2 + 1 }).map((_, i) => ({
  //     ...item,
  //     id: `${item.module_id}-${i}`
  //   }))
  // );

  const isSelected = (id: number) => {
    return selectedTemplateId === id;
  };

  return (
    <div style={{ display: "flex" }}>
      <Row gutter={8} style={{ display: "flex", flexWrap: "nowrap", overflowX: "auto", overflowY: "hidden", width: "100%" }}>
        {templateList?.map((item) => (
          <Col span={6} key={item.id}>
            <div
              className="checkbox-tile content-container"
              onClick={() => handleSelectDeselect(item.id)}
              style={{ height: "215px", display: "flex", alignItems: "center", background: "#2a0874" /* "white" */, borderRadius: "10px" }}
            >
              <Checkbox
                checked={selectedTemplateId === item.id}
                onClick={(e) => e.stopPropagation()}
                onChange={() => handleSelectDeselect(item.id)}
                className="checkbox-overlay"
              />
              <img src={item.thumbnail} className={`item ${isSelected(item.id) ? "selected-item" : ""}`} style={{ width: "100%" }} />

              <Tooltip title="Preview">
                <EyeOutlined
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    fontSize: "18px",
                    color: "#fff",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    borderRadius: "50%",
                    padding: "3px",
                    cursor: "pointer"
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setPreviewItem(item);
                    setOpenPreview(true);
                  }}
                />
              </Tooltip>
            </div>
          </Col>
        ))}
      </Row>
      {previewItem && openPreview && (
        <TemplatesPreviewModal open={openPreview} setOpen={setOpenPreview} previeweditem={previewItem} templatesList={templateList} />
      )}
    </div>
  );
};
