import React from "react";
export const Children: React.FC<{
  references: string[];
  footnotes: string[];
}> = ({ references, footnotes }) => {
  return (
    <div style={{ textAlign: "left" }}>
      <div>
        <b>Reference:</b>
        <div>
          {references?.length
            ? references?.map((ref, i) => {
                return <p key={i}>{ref}</p>;
              })
            : "N/A"}
        </div>
      </div>
      {footnotes?.length ? (
        <div>
          <b>Footnotes: </b>
          <p>{footnotes?.length ? footnotes?.map(note => note) : "N/A"} </p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
