import { StepProps } from "antd";
import { ConversationStep, FinalAsset } from "../../Pages/Content Newton/resources/types";
import {
  RESET_CN_STATES,
  SET_CN_AVAILABLE_MODULES_LIST,
  SET_CN_CONVERSATION_STEPS,
  SET_CN_CURRENT_STEP,
  SET_CN_CURRENT_STEP_DATA,
  SET_CN_CURRENT_STEP_DONE,
  SET_CN_CURRENT_STEP_DONE_GIVEN_STEP_PROCESS,
  SET_CN_CURRENT_STEP_DONE_NEXT_STEP_PROCESS,
  SET_CN_CURRENT_STEP_LOADING,
  SET_CN_CURRENT_STEP_TIMESTAMP,
  SET_CN_CURRENT_INDEX,
  SET_CN_CURRENT_INDEX_DATA,
  SET_CN_CURRENT_INDEX_DONE,
  SET_CN_CURRENT_INDEX_DONE_NEXT_INDEX_PROCESS,
  SET_CN_PUT_STEP_WITH_GIVEN_NAME_AFTER_CURRENT_INDEX,
  SET_CN_PUT_STEPS_WITH_GIVEN_NAMES_AFTER_CURRENT_INDEX,
  SET_CN_CURRENT_INDEX_LOADING,
  SET_CN_CURRENT_INDEX_TIMESTAMP,
  SET_CN_CURRENT_STEPPER_STEP_FINISH,
  SET_CN_CURRENT_STEPPER_STEP_FINISH_NEXT_STEP_PROCESS,
  SET_CN_DETAILS_INPUT_PROMPT,
  SET_CN_FINAL_ASSET,
  SET_CN_FINAL_ASSET_TEMPLATES_LIST,
  SET_CN_ORDER_ID,
  SET_CN_PUT_STEP_WITH_GIVEN_NAME_AFTER_CURRENT_STEP,
  SET_CN_SELECTED_MODULE_IDS,
  SET_CN_SELECTED_TEMPLATE_ID,
  SET_CN_STEPPER_CURRENT_STEP,
  SET_CN_STEPPER_STEPS,
  SET_CN_SYSTEM_SUGGESTED_MODULES_LIST,
  SET_CN_LOCALISED_ASSET_DATA,
  SET_CN_MARKET_AND_LANGUAGE_INPUT_PROMPT,
  SET_CN_TRANSLATED_ASSET_DATA,
  SET_CN_TRANSLATION_LANGUAGE_INPUT_PROMPT
} from "../constants/_contentNewtonConstants";
import { FinalAssetTemplateType, ModuleType, SuggesteModuleSSEData } from "../../Pages/Content Newton/steps/Module Selection/types";
import { LocalisedAssetDataType } from "../../Pages/Content Newton/steps/Localisation/AssetLocalisation";

export const setCNStepperCurrentStep = (payload: number) => ({
  type: SET_CN_STEPPER_CURRENT_STEP,
  payload
});

export const setCNStepperSteps = (payload: StepProps[]) => ({
  type: SET_CN_STEPPER_STEPS,
  payload
});

export const setCNCurrentStepperStepFinish = () => ({
  type: SET_CN_CURRENT_STEPPER_STEP_FINISH
});

export const setCNCurrentStepperStepFinishNextStepProcess = () => ({
  type: SET_CN_CURRENT_STEPPER_STEP_FINISH_NEXT_STEP_PROCESS
});

export const setCNConversationSteps = (payload: ConversationStep[]) => ({
  type: SET_CN_CONVERSATION_STEPS,
  payload
});

export const setCNCurrentStep = (payload: number) => ({
  type: SET_CN_CURRENT_STEP,
  payload
});

export const setCNCurrentStepDone = () => ({
  type: SET_CN_CURRENT_STEP_DONE
});

export const setCNCurrentStepDoneNextStepProcess = () => ({
  type: SET_CN_CURRENT_STEP_DONE_NEXT_STEP_PROCESS
});

export const setCNCurrentStepDoneGivenStepProcess = (payload: number) => ({
  type: SET_CN_CURRENT_STEP_DONE_GIVEN_STEP_PROCESS,
  payload
});

export const setCNCurrentStepData = (payload: any) => ({
  type: SET_CN_CURRENT_STEP_DATA,
  payload
});

export const setCNPutStepWithGivenNameAfterCurrentStep = (payload: string) => ({
  type: SET_CN_PUT_STEP_WITH_GIVEN_NAME_AFTER_CURRENT_STEP,
  payload
});

export const setCNCurrentStepLoading = (payload: boolean) => ({
  type: SET_CN_CURRENT_STEP_LOADING,
  payload
});

export const setCNCurrentStepTimestamp = (payload?: string) => ({
  type: SET_CN_CURRENT_STEP_TIMESTAMP,
  payload
});

export const setCNCurrentIndex = (payload: number) => ({
  type: SET_CN_CURRENT_INDEX,
  payload
});

export const setCNCurrentIndexDone = () => ({
  type: SET_CN_CURRENT_INDEX_DONE
});

export const setCNCurrentIndexDoneNextIndexProcess = () => ({
  type: SET_CN_CURRENT_INDEX_DONE_NEXT_INDEX_PROCESS
});

export const setCNCurrentIndexData = (payload: any) => ({
  type: SET_CN_CURRENT_INDEX_DATA,
  payload
});

export const setCNPutStepWithGivenNameAfterCurrentIndex = (payload: string) => ({
  type: SET_CN_PUT_STEP_WITH_GIVEN_NAME_AFTER_CURRENT_INDEX,
  payload
});

export const setCNPutStepsWithGivenNamesAfterCurrentIndex = (payload: string[]) => ({
  type: SET_CN_PUT_STEPS_WITH_GIVEN_NAMES_AFTER_CURRENT_INDEX,
  payload
});

export const setCNCurrentIndexLoading = (payload: boolean) => ({
  type: SET_CN_CURRENT_INDEX_LOADING,
  payload
});

export const setCNCurrentIndexTimestamp = (payload?: string) => ({
  type: SET_CN_CURRENT_INDEX_TIMESTAMP,
  payload
});

export const setCNDetailsInputPrompt = (payload?: string) => ({
  type: SET_CN_DETAILS_INPUT_PROMPT,
  payload
});

export const setCNAvailableModulesList = (payload?: ModuleType[]) => ({
  type: SET_CN_AVAILABLE_MODULES_LIST,
  payload
});

export const setCNSelectedModuleIds = (payload?: number[]) => ({
  type: SET_CN_SELECTED_MODULE_IDS,
  payload
});

export const setCNOrderId = (payload?: number) => ({
  type: SET_CN_ORDER_ID,
  payload
});

// export const setCNSystemSuggestedModulesList = (payload?: SuggesteModuleSSEData["data"]["system_suggested_modules"] /* ModuleType[] */) => ({
export const setCNSystemSuggestedModulesList = (payload?: SuggesteModuleSSEData["data"]) => ({
  type: SET_CN_SYSTEM_SUGGESTED_MODULES_LIST,
  payload
});

export const setCNFinalAssetTemplatesList = (payload?: FinalAssetTemplateType[]) => ({
  type: SET_CN_FINAL_ASSET_TEMPLATES_LIST,
  payload
});

export const setCNSelectedTemplateId = (payload?: number) => ({
  type: SET_CN_SELECTED_TEMPLATE_ID,
  payload
});

export const setCNFinalAsset = (payload?: FinalAsset) => ({
  type: SET_CN_FINAL_ASSET,
  payload
});

export const setCNMaketAndLanguageInputPrompt = (payload?: string) => ({
  type: SET_CN_MARKET_AND_LANGUAGE_INPUT_PROMPT,
  payload
});

export const setCNLocalisedAssetData = (key: keyof LocalisedAssetDataType, data: LocalisedAssetDataType[keyof LocalisedAssetDataType]) => ({
  type: SET_CN_LOCALISED_ASSET_DATA,
  payload: {
    key,
    data
  }
});

export const setCNTranslationLanguageInputPrompt = (payload?: string) => ({
  type: SET_CN_TRANSLATION_LANGUAGE_INPUT_PROMPT, 
  payload
});

export const setCNTranslatedAssetData = (key: keyof LocalisedAssetDataType, data: LocalisedAssetDataType[keyof LocalisedAssetDataType]) => ({
  type: SET_CN_TRANSLATED_ASSET_DATA,
  payload: {
    key,
    data
  }
});

export const resetCNStates = () => ({
  type: RESET_CN_STATES
});

export type ContentNewtonActions =
  | ReturnType<typeof setCNStepperCurrentStep>
  | ReturnType<typeof setCNStepperSteps>
  | ReturnType<typeof setCNCurrentStepperStepFinish>
  | ReturnType<typeof setCNCurrentStepperStepFinishNextStepProcess>
  | ReturnType<typeof setCNConversationSteps>
  | ReturnType<typeof setCNCurrentStep>
  | ReturnType<typeof setCNCurrentStepDone>
  | ReturnType<typeof setCNCurrentStepDoneNextStepProcess>
  | ReturnType<typeof setCNCurrentStepDoneGivenStepProcess>
  | ReturnType<typeof setCNCurrentStepData>
  | ReturnType<typeof setCNCurrentStepLoading>
  | ReturnType<typeof setCNCurrentStepTimestamp>
  | ReturnType<typeof setCNCurrentIndex>
  | ReturnType<typeof setCNCurrentIndexDone>
  | ReturnType<typeof setCNCurrentIndexDoneNextIndexProcess>
  | ReturnType<typeof setCNCurrentIndexData>
  | ReturnType<typeof setCNPutStepWithGivenNameAfterCurrentIndex>
  | ReturnType<typeof setCNPutStepsWithGivenNamesAfterCurrentIndex>
  | ReturnType<typeof setCNCurrentIndexLoading>
  | ReturnType<typeof setCNCurrentIndexTimestamp>
  | ReturnType<typeof setCNDetailsInputPrompt>
  | ReturnType<typeof setCNAvailableModulesList>
  | ReturnType<typeof setCNSelectedModuleIds>
  | ReturnType<typeof setCNOrderId>
  | ReturnType<typeof setCNSystemSuggestedModulesList>
  | ReturnType<typeof setCNFinalAssetTemplatesList>
  | ReturnType<typeof setCNSelectedTemplateId>
  | ReturnType<typeof setCNFinalAsset>
  | ReturnType<typeof setCNMaketAndLanguageInputPrompt>
  | ReturnType<typeof setCNLocalisedAssetData>
  | ReturnType<typeof setCNTranslationLanguageInputPrompt>
  | ReturnType<typeof setCNTranslatedAssetData>
  | ReturnType<typeof resetCNStates>;
