import { useState } from 'react'
import { Button, Modal, Form, Input, Select, message } from 'antd'
import axios from 'axios'
import { createPredisPost } from '../../../../Store/requests/_contentTranscreationRequests';


const { Option } = Select

interface CreateNewPostModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  openPredisAIPopup: () => void;
}

export default function CreateNewPostModal({ open, setOpen, openPredisAIPopup }: CreateNewPostModalProps) {

  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const handleCancel = () => {
    setOpen(false)
    form.resetFields()
  }

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const apiPayload = {
        text: values.Text,
        no_post: values.numberOfPosts.toString(),
        heading: values.Heading,
        subheading: values.SubHeading,
        cta: values.Cta,
        media_type: values.mediaType
      };
      console.log('API payload:', apiPayload)
      const data = await createPredisPost(apiPayload, setLoading);

      if (!data) {
        message.error('Failed to submit form. Please try again.');
        return;
      }

      console.log('API response:', data);
      setOpen(false);
      form.resetFields()
      openPredisAIPopup();
    } catch (error) {
      console.error('API error:', error)
      message.error('Failed to submit form. Please try again.')
    } finally {
      setLoading(false)
    }
  }


  const goToPredisAI = () => {
    setOpen(false)
    openPredisAIPopup();
  };

  return (
    <div className="p-4">
      <Modal
        title="Create New Post"
        open={open}
        onCancel={handleCancel}
        footer={null}
        centered
      // bodyStyle={{ height: "90vh", overflowY: "auto" }}


      >


        <Form form={form} onFinish={onFinish} layout="vertical" >
          <Form.Item
            name="Text"
            label="Text"
            rules={[{ required: true, message: 'Please input your text!' }]}
          >
            <Input.TextArea rows={2} placeholder='Entre your text here' />
          </Form.Item>
          <Form.Item
            name="Heading"
            label="Heading"
            rules={[{ required: true, message: 'Please input your Heading', }]}
          >
            <Input placeholder="Enter your heading here" />
          </Form.Item>
          <Form.Item
            name="SubHeading"
            label="Sub Heading"
            rules={[
              { required: true, message: 'Please input your SubHeading!' }
            ]}
          >
            <Input placeholder="Enter your sub heading here" />
          </Form.Item>

          <Form.Item
            name="Cta"
            label="CTA"
            rules={[{ required: true, message: 'Please input your Cta!' }]}
          >
            <Input placeholder='Enter your cta here' />
          </Form.Item>
          <Form.Item
            name="numberOfPosts"
            label="Number of Posts"
            rules={[{ required: true, message: 'Please select the Number of Posts!' }]}
          >
            <Select placeholder="Select number of posts">
              {[1, 2, 3, 4, 5, 6, 7].map((num) => (
                <Option key={num} value={num}>
                  {num}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="mediaType"
            label="Media Type"
            rules={[{ required: true, message: 'Please select the media type!' }]}
          >
            <Select placeholder="Select media type">
              {[{key:"Image",value:"single_image"}, {key:"Video",value:"video"}].map((obj) => (
                <Option key={obj.key} value={obj.value}>
                  {obj.key}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <div style={{ display: "flex", gap: "1rem" }}>
              <Button disabled={loading} type="default" className="flex-1" onClick={goToPredisAI}>
                Go to Portal
              </Button>
              <Button disabled={loading} type="primary" htmlType="submit" className="flex-1">
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>

      </Modal>
    </div>
  )
}