import { FC, useState, useEffect } from "react";
import { Row, Col, Button, Checkbox, Card, Select, Image } from "antd";
import _localStorageService from "../../../../Services/_localStorageService";
import { useSelector, useDispatch } from "react-redux";
import { FileForChatWithDoc } from "../../../../Store/actions/_semanticSearchActions";
import "./StylesFolderForNextInsight/_MultiModal.css";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import Markdown from "markdown-to-jsx";

const { Option } = Select;

export const FileSummariserResultScreen: FC = () => {
  const { fileForChatWithDoc, cardData } = useSelector((state: any) => state.semanticSearchReducer);
  const dispatch = useDispatch();
  
  // Local state to keep track of selected file and summary
  const [selectedFile, setSelectedFile] = useState<string>(fileForChatWithDoc[0]);
  const [summary, setSummary] = useState<string>(cardData?.summary || "");


  return (
    <>
      <Col span={24} style={{ paddingLeft: "20px" }} className="next-insight-page">
        <Card className="card-element">
          <div style={{display:'flex',justifyContent:'space-between'}}>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Image src={PROJECT_ICONS.PDF} preview={false} />
            <Select
              value={selectedFile}
              // onChange={setSelectedFile}
              style={{ width: 300 }}
            >
              {fileForChatWithDoc.map((file: string) => (
                <Option key={file} value={file}>
                  {file}
                </Option>
              ))}
            </Select>
          </div>
          <div style={{marginRight:'10px'}}> 
            <Button type="link" style={{color:'#1E4DA1'}}>SHARE</Button>
            <Button type="link" style={{color:'#1E4DA1',fontWeight:'bold'}}>SAVE AS ARTIFACT</Button>
            <Image src={PROJECT_ICONS.DOTS} preview={false}/>
          </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center", marginTop: "0px", height:window.innerHeight >=700? "475px":"425px", overflowY: "scroll", padding: '10px' }}>
            <div style={{ width: "90%" }}>
              <div style={{ background: "rgb(237, 244, 255)", padding: "12px", borderRadius: "10px", marginTop: "30px" }}>
                <Markdown>
                  {summary}
                </Markdown>
              </div>
            </div> 
          </div>
        </Card>
      </Col>
    </>
  );
};
