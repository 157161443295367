import React, { FC, useState, useMemo, useEffect, useRef } from "react";
import { Row, Col, Input, Button, Avatar, Image, Modal, Checkbox, Card, Space, Typography, Spin, Tooltip, Switch, Tag } from "antd";
import { useSelector } from "react-redux";
import _localStorageService from "../../../../Services/_localStorageService";
import { SSE } from "sse.js";
import { useDispatch } from "react-redux";
import { setLoadingStatusAction } from "../../../../Store/actions/_commonActions";
import { setEncodedSemanticSearchId, setProModeStatus, setUpdateResearchAgentAction } from "../../../../Store/actions/_semanticSearchActions";
import { getTavilyChunks } from "../../../../Store/requests/_semanticSearchRequests";
interface ComponentAProps {
  urlsForProMode: string[];
  setUrlsForProMode: React.Dispatch<React.SetStateAction<string[]>>;
}

export const MedMode: React.FC<ComponentAProps> = ({ setUrlsForProMode, urlsForProMode }) => {
  const { searchQuery, semanticEncodedSearchid ,researchAgent } = useSelector((state: any) => state?.semanticSearchReducer);
  const dispatch = useDispatch();
  const apiurl = process.env.REACT_APP_API_URL_FOR_SEMANTIC;
  const token = _localStorageService.getAccessToken();
  const [isloading, setIsLoading] = useState(false);
  const [urls, setUrls] = useState([]);
  const [selectedUrls, setSelectedUrls] = useState<string[]>([]);
  const [inputValue, setisInputvalue] = useState("");
  const searchQueryRef = useRef<HTMLParagraphElement>(null);
  const [height,setHeight]=useState(0)

  console.log("this is height")
console.log(height)
  // Function to handle tag selection
  const handleUrlSelect = (url: string) => {
    setSelectedUrls((prevSelectedUrls) => {
      if (prevSelectedUrls.includes(url)) {
        return prevSelectedUrls.filter((selectedUrl) => selectedUrl !== url);
      } else {
        return [...prevSelectedUrls, url];
      }
    });
  };
  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setisInputvalue(event.target.value); // Remove leading and trailing whitespace
  };

  const handleCallingSend = async () => {
    const newUrls = inputValue.split(",").filter((url) => url.trim() !== "");

    // Concatenate newTags with selectedTags
    const updatedUrls = [...selectedUrls, ...newUrls];

    // Set the updated tags array to tagsForProMode state
    setUrlsForProMode(updatedUrls);
    dispatch(setLoadingStatusAction(true));
    dispatch(setUpdateResearchAgentAction({ 
      ...researchAgent, 
      is_gpt_search: true, 
      is_internet_search: true 
    }));
        if (semanticEncodedSearchid === 0) {
      dispatch(setEncodedSemanticSearchId(1));
    } else if (semanticEncodedSearchid === 1) {
      dispatch(setEncodedSemanticSearchId(10));
    } else if (semanticEncodedSearchid === 10) {
      dispatch(setEncodedSemanticSearchId(1));
    }
  };

  // Update input value only if search is not in progress
  const handleCallingSkip = async () => {
    setUrlsForProMode([]);
    dispatch(setUpdateResearchAgentAction({ 
      ...researchAgent, 
      is_gpt_search: true, 
      is_internet_search: true 
    }));
        dispatch(setLoadingStatusAction(true));
    if (semanticEncodedSearchid === 0) {
      dispatch(setEncodedSemanticSearchId(1));
    } else if (semanticEncodedSearchid === 1) {
      dispatch(setEncodedSemanticSearchId(10));
    } else if (semanticEncodedSearchid === 10) {
      dispatch(setEncodedSemanticSearchId(1));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Set loading state at the start of data fetching
      try {
        const res = await getTavilyChunks(searchQuery);
        if (!res) {
          setIsLoading(false);
          return;
        }
        
        console.log("Keywords:", res?.data); // Assuming 'keywords' come from the response
        setUrls(res?.data); // Update 'setUrls' with the data from the response'
        setSelectedUrls(res?.data)
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false); // Ensure loading is false after data fetching
      }
    };
  
    setIsLoading(true);
    fetchData(); // Call the function to fetch data on component mount
    
    return () => {
      console.log("Unmount");
    };
  }, []); // Include dependencies in the dependency array
  
  useEffect(() => {
    if (searchQueryRef.current) {
      const height = searchQueryRef.current.offsetHeight;
      console.log("Height of searchQuery:", height);
      setHeight(height)
    }
  }, [searchQuery]);
  return (
    <>
      {isloading ? (
        <>
          <Col span={24} style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "250px" }}>
            <Spin />
          </Col>
        </>
      ) : (
        <>
          <Col span={16} style={{ paddingLeft: "20px" }}>
            <Card style={{ width: "100%", opacity: "1", borderRadius: "25px", height: "592px" }}>
              <Col span={24}>
                <p                   ref={searchQueryRef} // Attach the ref to the paragraph element

                  style={{
                    color: "#66676B",
                    padding: "0px",
                    fontSize: "22px",
                    fontWeight: "400",
                  }}
                >
                  {searchQuery}
                </p>
              </Col>
              <Col span={24} style={{ marginTop: "15px", paddingLeft: "25px" }}>
                <div style={{ background: "#F4F6F9", padding: "24px", borderRadius: "10px" }}>
                  <p style={{ fontSize: "16px", fontWeight: "600" }}>MED AFFAIRS AGENT</p>
                  <p style={{ color: "#66676B", fontSize: "16px", fontWeight: "600", paddingTop: "5px" }}>Which resources would you like to proceed with</p>
                  <div style={{ display: "flex", paddingTop: "10px", overflowX: "auto",marginTop:'10px', flexDirection:'column',gap:'10px' ,maxHeight:height<=35?"300px":height<=80?"220px":height<=100?"180px":"155px",overflowY:'auto'}}>
                  {urls.map((item: any, index: any) => (
  <div key={index} style={{ marginBottom: '10px' }}>
    <Checkbox
      checked={selectedUrls.includes(item)}
      onChange={() => handleUrlSelect(item)}
    />
    <span style={{ 
    marginLeft: '10px', 
    fontWeight: 'bold', 
    cursor: 'pointer', 
    color: 'black', 
    textDecoration: 'none'
  }}

  onMouseEnter={(e) => {
    const target = e.target as HTMLElement; // Type assertion to HTMLElement
    target.style.color = 'rgb(3, 78, 162)';
    target.style.textDecoration = 'underline';
  }}
  onMouseLeave={(e) => {
    const target = e.target as HTMLElement; // Type assertion to HTMLElement
    target.style.color = 'black';
    target.style.textDecoration = 'none';
  }}
 onClick={() => window.open(item, '_blank')}
 >{item}
 
 </span>
  </div>
))}
                  </div>
                  <p style={{ color: "#66676B", fontSize: "14px", fontWeight: "400", marginTop: "15px" }}>
                    If other, please input the aspects you want to know
                  </p>
                  <Input
                    style={{ marginTop: "15px", borderRadius: "none" }}
                    onChange={handleInput}
                    placeholder="Please provide input for other aspects , if any"
                  />
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      style={{
                        marginTop: "20px",
                        background: selectedUrls.length > 0 ? "#034EA2" : "#E6E7E8", // Set background color based on selectedTags
                        color: selectedUrls.length > 0 ? "#FFFFFF" : "#66676B", // Set text color based on selectedTags
                      }}
                      onClick={selectedUrls.length > 0 ? handleCallingSend : undefined} // Conditionally enable onClick based on selectedTags
                    >
                      SEND
                    </Button>
                  </div>
                </div>
              </Col>
              
            </Card>
          </Col>

          <Col span={8} style={{ paddingLeft: "20px" }}>
            <Card
              style={{
                width: "100%",
                height: "592px",
                opacity: "1",
                overflowY: "scroll",
                borderRadius: "25px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                <p style={{ color: "#66676B" }}>Complete the prompt to view the details</p>
              </div>
            </Card>
          </Col>
        </>
      )}
    </>
  );
};
