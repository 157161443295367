import { t } from "i18next";
import { PROJECT_ICONS } from "../../../Assets/Img/_DIcons";
import { I18N_MODULE_KEYS } from "../../../Components/i18n/core/constants";
import { AllModulesUseCasesDataType, UseCaseDataType } from "./types";

const fieldCopilotData: UseCaseDataType = {
  module: {
    id: "field-copilot", //"content-transcreation", //"field-copilot", //used in navigate(route)
    name: I18N_MODULE_KEYS["module.fieldcopilot"],
    // heading: I18N_MODULE_KEYS["content.usecase.fieldcopilot.Field Copilot - Your Generative AI-Powered Assistant"],
    heading: I18N_MODULE_KEYS["content.usecase.fieldcopilot.Field Copilot - Your Generative AI-Powered Assistant"],
    description:
      I18N_MODULE_KEYS[
        "content.usecase.fieldcopilot.Empowering Reps with Unparalleled HCP, Patient Insights, and Advanced Pharma & Product Recommendations"
      ]
  },
  tabContents: {
    description: {
      id: "description",
      tabName: I18N_MODULE_KEYS["content.text.description"],
      content: [
        {
          id: 1,
          imgSrc: PROJECT_ICONS.UC_FC_DESC_ACCESS_POINT_ICON,
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.description.tabcontents.One-Stop Access Point"],
          description:
            I18N_MODULE_KEYS[
              "content.usecase.fieldcopilot.description.tabcontents.Generative AI-enabled synthesis offers a consolidated view, reducing the need for multiple tools"
            ]
        },
        {
          id: 2,
          imgSrc: PROJECT_ICONS.UC_FC_DESC_FAVORITES_ICON,
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.description.tabcontents.Tailored Recommendations"],
          description:
            I18N_MODULE_KEYS[
              "content.usecase.fieldcopilot.description.tabcontents.Personalized answers to rep questions, providing contextual recommendations in real-time"
            ]
        },
        {
          id: 3,
          imgSrc: PROJECT_ICONS.UC_FC_DESC_RESPONSE_ICON,
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.description.tabcontents.Scientific Responses"],
          description:
            I18N_MODULE_KEYS[
              'content.usecase.fieldcopilot.description.tabcontents.Not just answers, but insights into the "why", "who", and "what" behind every recommendation'
            ]
        },
        {
          id: 4,
          imgSrc: PROJECT_ICONS.UC_FC_DESC_COMPLIANCE_ICON,
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.description.tabcontents.Compliance-Centric"],
          description: I18N_MODULE_KEYS["content.usecase.fieldcopilot.description.tabcontents.Compliance-Centric"]
        },
        {
          id: 5,
          imgSrc: PROJECT_ICONS.UC_FC_DESC_KNOWLEDGE_BASE_ICON,
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.description.tabcontents.Curated Knowledge Base"],
          description:
            I18N_MODULE_KEYS[
              "content.usecase.fieldcopilot.description.tabcontents.Access to a meticulously chosen corpus of pharma company and product data & knowledge base"
            ]
        },
        {
          id: 6,
          imgSrc: PROJECT_ICONS.UC_FC_DESC_VIEW360_ICON,
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.description.tabcontents.Marketing 360 Insights Access"],
          description:
            I18N_MODULE_KEYS[
              "content.usecase.fieldcopilot.description.tabcontents.Seamless integration with digital platform ecosystem providing real-time updates on customer journey"
            ]
        },
        {
          id: 7,
          imgSrc: PROJECT_ICONS.UC_FC_DESC_TASK_MANAGEMENT_ICON,
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.description.tabcontents.Assisted Tasks Management"],
          description:
            I18N_MODULE_KEYS[
              "content.usecase.fieldcopilot.description.tabcontents.Quick access to daily schedules, upcoming appointments, and task reminders"
            ]
        }
      ],
      bannerSrc: PROJECT_ICONS.UC_FC_DESC_INDEGENE_WELCOME_FILED_COPILOT_IMAGE
    },
    "business-value-proposition": {
      id: "business-value-proposition",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Business Value Proposition"],
      content: [
        {
          id: 1, //contentId
          imgSrc: PROJECT_ICONS.UC_FC_BVP_FEEDBACK_ICON,
          tileColor: "#DCEAFF", //Tile Icon background color
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Intuitive Interface and Feedback Loop"],
          description: [
            {
              heading: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Interactive Interface"],
              description:
                I18N_MODULE_KEYS[
                  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Allowing Reps to engage and provide input to the AI."
                ]
            },
            {
              heading: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Feedback Collection"],
              description:
                I18N_MODULE_KEYS[
                  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Capturing user responses to refine and improve the model."
                ]
            },
            {
              heading: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Real-time Adaptability"],
              description:
                I18N_MODULE_KEYS[
                  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Updating the model's responses based on immediate feedback."
                ]
            }
          ]
        },
        {
          id: 2,
          imgSrc: PROJECT_ICONS.UC_FC_BVP_AI_ARCHITECTURE_ICON,
          tileColor: "#FFDBE9",
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Generative AI Model Architecture"],
          description: [
            {
              heading: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Generative AI"],
              description:
                I18N_MODULE_KEYS[
                  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Select LLM using GANs or Transformer architectures."
                ]
            },
            {
              heading: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Training and Optimization"],
              description:
                I18N_MODULE_KEYS[
                  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Enhancing performance through training techniques."
                ]
            },
            {
              heading: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Deployment & Scalability"],
              description: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Ensuring seamless solutions."]
            }
          ]
        },
        {
          id: 3,
          imgSrc: PROJECT_ICONS.UC_FC_BVP_INTEGRATED_KNOWLEDGE_ICON,
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Integrated Data & Knowledge System"],
          tileColor: "#CCEEFF",
          description: [
            {
              heading: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Diverse Data Sources"],
              description:
                I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Gathering varied and high-quality data."]
            },
            {
              heading: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Data Cleaning"],
              description:
                I18N_MODULE_KEYS[
                  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Removing inconsistencies, noise, or irrelevant data."
                ]
            },
            {
              heading: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Feature Engineering"],
              description:
                I18N_MODULE_KEYS[
                  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Enhancing the data to improve model performance."
                ]
            }
          ]
        },
        {
          id: 4,
          imgSrc: PROJECT_ICONS.UC_FC_BVP_COMPLIANCE_REPORTING_ICON,
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Compliance and Reporting Framework"],
          tileColor: "#EED5FF",
          description: [
            {
              heading: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Bias Mitigation"],
              description:
                I18N_MODULE_KEYS[
                  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Ensuring the model doesn't perpetuate harmful biases."
                ]
            },
            {
              heading: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Transparency"],
              description:
                I18N_MODULE_KEYS[
                  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Providing insights into how the AI makes decisions."
                ]
            },
            {
              heading: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Compliance & Guardrails"],
              description:
                I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Operating within legal and ethical boundaries."]
            }
          ]
        },
        {
          id: 5,
          imgSrc: PROJECT_ICONS.UC_FC_BVP_COMPREHENSIVE_LIBRARY_ICON,
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Comprehensive Prompt Library"],
          tileColor: "#C4FFCA",
          description: [
            {
              heading: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Dynamic Queries"],
              description:
                I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Adaptable prompts for diverse user needs."]
            },
            {
              heading: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Guided Interactions"],
              description:
                I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Directing users to obtain desired results."]
            },
            {
              heading: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Categorized Prompts"],
              description:
                I18N_MODULE_KEYS[
                  "content.usecase.fieldcopilot.business-value-proposition.tabcontents.Organizing queries by themes or functions for easy access."
                ]
            }
          ]
        }
      ]
    },
    "return-on-investment": {
      id: "return-on-investment",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.return-on-investment.tabcontents.Return on Investment"],
      content: [
        {
          id: 1,
          imgSrc: PROJECT_ICONS.UC_FC_ROI_FINANCIAL_IMPACT_ICON,
          tileColor: "#DCEAFF",
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.return-on-investment.tabcontents.Financial Impact"],
          description: [
            {
              heading: I18N_MODULE_KEYS["content.usecase.fieldcopilot.return-on-investment.tabcontents.Cost Efficiency"],
              description:
                I18N_MODULE_KEYS[
                  "content.usecase.fieldcopilot.return-on-investment.tabcontents.Reduce training expenses by leveraging AI-assisted, on-demand modules."
                ]
            },
            {
              heading: I18N_MODULE_KEYS["content.usecase.fieldcopilot.return-on-investment.tabcontents.Maximized ROI"],
              description:
                I18N_MODULE_KEYS[
                  "content.usecase.fieldcopilot.return-on-investment.tabcontents.Optimized rep performance amplifies sales outcomes, ensuring every training dollar yields higher returns."
                ]
            }
          ]
        },
        {
          id: 2,
          imgSrc: PROJECT_ICONS.UC_FC_ROI_SALES_OPS_EXCELLENCE_ICON,
          tileColor: "#FFDBE9",
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.return-on-investment.tabcontents.Sales Ops Excellence"],
          description: [
            {
              heading: I18N_MODULE_KEYS["content.usecase.fieldcopilot.return-on-investment.tabcontents.Rapid Onboarding"],
              description:
                I18N_MODULE_KEYS[
                  "content.usecase.fieldcopilot.return-on-investment.tabcontents.AI-guided modules accelerate the integration of new reps, enhancing time-to-productivity."
                ]
            },
            {
              heading: I18N_MODULE_KEYS["content.usecase.fieldcopilot.return-on-investment.tabcontents.Dynamic Adaptability"],
              description:
                I18N_MODULE_KEYS[
                  "content.usecase.fieldcopilot.return-on-investment.tabcontents.Equip reps with real-time market insights, minimizing extensive retraining needs."
                ]
            }
          ]
        },
        {
          id: 3,
          imgSrc: PROJECT_ICONS.UC_FC_ROI_ENHANCED_ENGAGEMENTS_ICON,
          tileColor: "#CCEEFF",
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.return-on-investment.tabcontents.Enhanced Engagements"],
          description: [
            {
              heading: I18N_MODULE_KEYS["content.usecase.fieldcopilot.return-on-investment.tabcontents.Informed Engagements"],
              description:
                I18N_MODULE_KEYS[
                  "content.usecase.fieldcopilot.return-on-investment.tabcontents.Elevate HCP interactions through timely, AI-driven insights, solidifying market positioning."
                ]
            },
            {
              heading: I18N_MODULE_KEYS["content.usecase.fieldcopilot.return-on-investment.tabcontents.Risk Mitigation"],
              description:
                I18N_MODULE_KEYS[
                  "content.usecase.fieldcopilot.return-on-investment.tabcontents.Built-in compliance and reporting mechanism ensure adherence to global standards, protecting brand reputation."
                ]
            }
          ]
        }
      ]
    },
    "success-metrics": {
      id: "success-metrics",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.success-metrics.tabcontents.Success Metrics"],
      content: [
        {
          id: 1,
          imgSrc: PROJECT_ICONS.UC_FC_SM_HOURGLASS_ICON,
          tileColor: "#DCEAFF",
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.success-metrics.tabcontents.Task Completion Time"],
          description:
            I18N_MODULE_KEYS[
              "content.usecase.fieldcopilot.success-metrics.tabcontents.Time it takes for a medical representative to complete their tasks with the assistance of the AI co-pilot compared to without it"
            ]
        },
        {
          id: 2,
          imgSrc: PROJECT_ICONS.UC_FC_SM_SALES_IMROVEMENT_ICON,
          tileColor: "#FFDBE9",
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.success-metrics.tabcontents.Sales/Productivity Improvement"],
          description:
            I18N_MODULE_KEYS[
              "content.usecase.fieldcopilot.success-metrics.tabcontents.Increase in sales or productivity achieved by medical representatives while using the AI co-pilot"
            ]
        },
        {
          id: 3,
          imgSrc: PROJECT_ICONS.UC_FC_SM_INFORMATION_ACCURACY_ICON,
          tileColor: "#CCEEFF",
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.success-metrics.tabcontents.Accuracy of Information"],
          description:
            I18N_MODULE_KEYS["content.usecase.fieldcopilot.success-metrics.tabcontents.The accuracy of the information provided by the AI co-pilot"]
        },
        {
          id: 4,
          imgSrc: PROJECT_ICONS.UC_FC_SM_CUSTOMER_FEEDBACK_ICON,
          tileColor: "#DCEAFF",
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.success-metrics.tabcontents.Customer Feedback"],
          description:
            I18N_MODULE_KEYS[
              "content.usecase.fieldcopilot.success-metrics.tabcontents.Feedback from customers who interacted with the medical representatives while using the AI co-pilot"
            ]
        },
        {
          id: 5,
          imgSrc: PROJECT_ICONS.UC_FC_SM_ENGAGEMENT_RATE_ICON,
          tileColor: "#FFDBE9",
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.success-metrics.tabcontents.Engagement Rate"],
          description:
            I18N_MODULE_KEYS[
              "content.usecase.fieldcopilot.success-metrics.tabcontents.How often medical representatives actively engage with the AI co-pilot during their interactions"
            ]
        },
        {
          id: 6,
          imgSrc: PROJECT_ICONS.UC_FC_SM_PERSONALIZATION_ICON,
          tileColor: "#CCEEFF",
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.success-metrics.tabcontents.Personalization"],
          description:
            I18N_MODULE_KEYS[
              "content.usecase.fieldcopilot.success-metrics.tabcontents.The AI co-pilot's ability to adapt its assistance to individual medical representatives' working styles and strengths"
            ]
        },
        {
          id: 7,
          imgSrc: PROJECT_ICONS.UC_FC_SM_COMPLIANCE_REGULATION_ICON,
          tileColor: "#DCEAFF",
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.success-metrics.tabcontents.Compliance and Regulations"],
          description:
            I18N_MODULE_KEYS[
              "content.usecase.fieldcopilot.success-metrics.tabcontents.The AI co-pilot assists medical representatives in adhering to industry regulations and company policies"
            ]
        },
        {
          id: 8,
          imgSrc: PROJECT_ICONS.UC_FC_SM_COST_SAVINGS_ICON,
          tileColor: "#FFDBE9",
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.success-metrics.tabcontents.Cost Savings"],
          description: I18N_MODULE_KEYS["content.usecase.fieldcopilot.success-metrics.tabcontents.The cost savings achieved by using the AI co-pilot"]
        }
      ]
    },
    "future-potential": {
      id: "future-potential",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.future-potential.tabcontents.Future Potential"],
      content: [
        {
          id: 1,
          imgSrc: PROJECT_ICONS.UC_FC_FP_CONTENT_GENERATION_ICON,
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.future-potential.tabcontents.Content Generation"],
          description:
            I18N_MODULE_KEYS[
              "content.usecase.fieldcopilot.future-potential.tabcontents.AI could assist in generating various types of content, such as articles, presentations, and case studies, based on the latest medical research and guidelines. This content could help medical representatives educate healthcare professionals effectively and keep them updated on advancements"
            ]
        },
        {
          id: 2,
          imgSrc: PROJECT_ICONS.UC_FC_FP_TRAINING_SIMULATIONS_ICON,
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.future-potential.tabcontents.Training Simulations"],
          description:
            I18N_MODULE_KEYS[
              "content.usecase.fieldcopilot.future-potential.tabcontents.Generative AI could create realistic simulations of medical scenarios, allowing medical representatives to practice their communication skills in a controlled environment. This would help them refine their pitches and responses to different situations"
            ]
        },
        {
          id: 3,
          imgSrc: PROJECT_ICONS.UC_FC_FP_LANGUAGE_TRANSLATION_ICON,
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.future-potential.tabcontents.Language Translation"],
          description:
            I18N_MODULE_KEYS[
              "content.usecase.fieldcopilot.future-potential.tabcontents.AI-powered language translation could enable medical representatives to communicate with healthcare professionals from different linguistic backgrounds, breaking down language barriers and expanding their reach"
            ]
        },
        {
          id: 4,
          imgSrc: PROJECT_ICONS.UC_FC_FP_PREDICTIVE_ANALYTICS_ICON,
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.future-potential.tabcontents.Predictive Analytics"],
          description:
            I18N_MODULE_KEYS[
              "content.usecase.fieldcopilot.future-potential.tabcontents.By analyzing historical data and patterns, AI could predict the most suitable times and methods for contacting specific healthcare professionals. This would enhance the effectiveness of interactions and improve engagement"
            ]
        },
        {
          id: 5,
          imgSrc: PROJECT_ICONS.UC_FC_FP_VOICE_INTERACTION_ICON,
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.future-potential.tabcontents.Voice Interaction"],
          description:
            I18N_MODULE_KEYS[
              "content.usecase.fieldcopilot.future-potential.tabcontents.With advancements in voice recognition technology, AI could assist medical representatives during phone calls or virtual meetings by providing real-time information and suggested talking points"
            ]
        },
        {
          id: 6,
          imgSrc: PROJECT_ICONS.UC_FC_FP_ETHICAL_CONSIDERATIONS_ICON,
          point: I18N_MODULE_KEYS["content.usecase.fieldcopilot.future-potential.tabcontents.Ethical Considerations"],
          description:
            I18N_MODULE_KEYS[
              "content.usecase.fieldcopilot.future-potential.tabcontents.While the potential benefits are substantial, there would be ethical considerations, particularly concerning patient privacy and data security. Medical representatives and AI developers would need to ensure that patient information is handled responsibly and in compliance with regulations like HIPAA"
            ]
        }
      ]
    }
    /*     customisation: {
      id: "customisation",
      tabName: "Customisation",
      content: []
    },
    "technology-used": {
      id: "technology-used",
      tabName: "Technology used",
      content: []
    } */
  }
};

const contentTranscreationData: UseCaseDataType = {
  module: {
    id: "content-transcreation",
    name: I18N_MODULE_KEYS["module.transcreation"],
    heading: "Content Transcreation - Your Generative AI-Powered Assistant",
    description: "Process of creating and adapting content from one language to another, while maintaining it's intent, context and style, tone."
  },
  tabContents: {
    description: {
      id: "description",
      tabName: I18N_MODULE_KEYS["content.text.description"],
      content: []
    },
    "business-value-proposition": {
      id: "business-value-proposition",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Business Value Proposition"],
      content: []
    },
    "return-on-investment": {
      id: "return-on-investment",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.return-on-investment.tabcontents.Return on Investment"],
      content: []
    },
    "success-metrics": {
      id: "success-metrics",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.success-metrics.tabcontents.Success Metrics"],
      content: []
    },
    "future-potential": {
      id: "future-potential",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.future-potential.tabcontents.Future Potential"],
      content: []
    }
    /*     customisation: {
      id: "customisation",
      tabName: "Customisation",
      content: []
    },
    "technology-used": {
      id: "technology-used",
      tabName: "Technology used",
      content: []
    } */
  }
};

const semanticSearchData: UseCaseDataType = {
  module: {
    id: "next-gist",
    name: I18N_MODULE_KEYS["module.semantic"],
    heading: "NEXT GIST - Your Generative AI-Powered Assistant",
    description:
      "NEXT GIST seeks contextually relevant results, understanding the query's meaning, unlike lexical search, which relies on word matching."
  },
  tabContents: {
    description: {
      id: "description",
      tabName: I18N_MODULE_KEYS["content.text.description"],
      content: []
    },
    "business-value-proposition": {
      id: "business-value-proposition",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Business Value Proposition"],
      content: []
    },
    "return-on-investment": {
      id: "return-on-investment",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.return-on-investment.tabcontents.Return on Investment"],
      content: []
    },
    "success-metrics": {
      id: "success-metrics",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.success-metrics.tabcontents.Success Metrics"],
      content: []
    },
    "future-potential": {
      id: "future-potential",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.future-potential.tabcontents.Future Potential"],
      content: []
    }
    /*     customisation: {
      id: "customisation",
      tabName: "Customisation",
      content: []
    },
    "technology-used": {
      id: "technology-used",
      tabName: "Technology used",
      content: []
    } */
  }
};

const intelligentAssistantData: UseCaseDataType = {
  module: {
    id: "intelligent-assistant",
    name: "Intelligent Assistant",
    heading: "Intelligent Assistant - Your Generative AI-Powered Assistant",
    description: "Process of creating and adapting content from one language to another, while maintaining it's intent, context and style, tone."
  },
  tabContents: {
    description: {
      id: "description",
      tabName: I18N_MODULE_KEYS["content.text.description"],
      content: []
    },
    "business-value-proposition": {
      id: "business-value-proposition",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Business Value Proposition"],
      content: []
    },
    "return-on-investment": {
      id: "return-on-investment",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.return-on-investment.tabcontents.Return on Investment"],
      content: []
    },
    "success-metrics": {
      id: "success-metrics",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.success-metrics.tabcontents.Success Metrics"],
      content: []
    },
    "future-potential": {
      id: "future-potential",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.future-potential.tabcontents.Future Potential"],
      content: []
    }
    /*     customisation: {
      id: "customisation",
      tabName: "Customisation",
      content: []
    },
    "technology-used": {
      id: "technology-used",
      tabName: "Technology used",
      content: []
    } */
  }
};

const contentAIData: UseCaseDataType = {
  module: {
    id: "content-ai",
    name: I18N_MODULE_KEYS["module.contentcreation"],
    heading: I18N_MODULE_KEYS["module.contentcreation"],
    description:
      I18N_MODULE_KEYS["content.usecase.contentai.description.Faster content generation with Domain Specificity, Compliance and Regulations"]
  },
  tabContents: {
    description: {
      id: "description",
      tabName: I18N_MODULE_KEYS["content.text.description"],
      content: [
        {
          id: 1,
          imgSrc: PROJECT_ICONS.UC_CA_DESC_PROMPT_ENRICHMENT_ICON,
          point: I18N_MODULE_KEYS["content.usecase.contentai.description.Prompt Enrichment"],
          description:
            I18N_MODULE_KEYS[
              "content.usecase.contentai.description.Allows user to enrich prompt to get the desired content that resonates with the target audience"
            ]
        },
        {
          id: 2,
          imgSrc: PROJECT_ICONS.UC_CA_DESC_REGULATORY_COMPLIANCE_CHECK_ICON,
          point: I18N_MODULE_KEYS["content.usecase.contentai.description.Automatic regulatory compliance check"],
          description:
            I18N_MODULE_KEYS[
              "content.usecase.contentai.description.Generated content is compliant with relevant bodies, ensures the content meets all medical legal and regulatory standards."
            ]
        },
        {
          id: 3,
          imgSrc: PROJECT_ICONS.UC_CA_DESC_LOCALIZATION_OPTIMIZATION_ICON,
          point: I18N_MODULE_KEYS["content.usecase.contentai.description.Localization optimization"],
          description:
            I18N_MODULE_KEYS[
              "content.usecase.contentai.description.Generates content suitable for different regions and languages, which is not only be translated but also culturally adapted to ensure maximum engagement"
            ]
        },
        {
          id: 4,
          imgSrc: PROJECT_ICONS.UC_CA_DESC_RESPONSIVE_DESIGN_ICON,
          point: I18N_MODULE_KEYS["content.usecase.contentai.description.Responsive design generator"],
          description:
            I18N_MODULE_KEYS[
              "content.usecase.contentai.description.Generates content that’s not just tailored in message but also in design, ensuring optimal viewing on desktops, tablets, and mobile devices."
            ]
        },
        {
          id: 5,
          imgSrc: PROJECT_ICONS.UC_CA_DESC_SCALABLE_MEGAPHONE_ICON,
          point: I18N_MODULE_KEYS["content.usecase.contentai.description.Scalable"],
          description:
            I18N_MODULE_KEYS[
              "content.usecase.contentai.description.Can generate large amount of content with ease, enabling businesses to scale their email campaigns without proportional increases in costs"
            ]
        }
      ],
      bannerSrc: PROJECT_ICONS.UC_CA_DESC_SEARCH_TITLE_HERE_IMAGE
    },
    "business-value-proposition": {
      id: "business-value-proposition",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Business Value Proposition"],
      content: [
        {
          id: 1, //contentId
          imgSrc: PROJECT_ICONS.UC_CA_BVP_TIME_TO_MARKET_ICON,
          tileColor: "#DCEAFF", //Tile Icon background color
          point: I18N_MODULE_KEYS["content.usecase.contentai.business-value-proposition.Faster Time-To-Market"],
          description:
            I18N_MODULE_KEYS[
              "content.usecase.contentai.business-value-proposition.With faster content generation, campaigns can be executed more swiftly, allowing businesses to quickly respond to market changes or new product launches."
            ]
        },
        {
          id: 2,
          imgSrc: PROJECT_ICONS.UC_CA_BVP_COST_EFFICIENCY_ICON,
          tileColor: "#FFDBE9",
          point: I18N_MODULE_KEYS["content.usecase.contentai.business-value-proposition.Cost Efficiency"],
          description:
            I18N_MODULE_KEYS[
              "content.usecase.contentai.business-value-proposition.Reduced costs associated with hiring content writers, translators, and designers, especially when scaling to larger audiences or expanding to new markets."
            ]
        },
        {
          id: 3,
          imgSrc: PROJECT_ICONS.UC_CA_BVP_MARKET_EXPANSION_ICON,
          point: I18N_MODULE_KEYS["content.usecase.contentai.business-value-proposition.Market Expansion"],
          tileColor: "#CCEEFF",
          description:
            I18N_MODULE_KEYS[
              "content.usecase.contentai.business-value-proposition.Allow businesses to effortlessly reach and communicate with global audiences, opening doors to new markets and opportunities"
            ]
        },
        {
          id: 4,
          imgSrc: PROJECT_ICONS.UC_CA_BVP_INCREASED_ENGAGEMENTS_ICON,
          point: I18N_MODULE_KEYS["content.usecase.contentai.business-value-proposition.Increased Engagements"],
          tileColor: "#EED5FF",
          description:
            I18N_MODULE_KEYS[
              "content.usecase.contentai.business-value-proposition.Personalized and relevant content typically leads to higher open rates and click-through rates and can lead to stronger relationships with HCPs"
            ]
        }
      ]
    },
    "return-on-investment": {
      id: "return-on-investment",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.return-on-investment.tabcontents.Return on Investment"],
      content: [
        {
          id: 1,
          imgSrc: PROJECT_ICONS.UC_CA_ROI_FINANCIAL_BENEFITS_ICON,
          tileColor: "#DCEAFF",
          point: I18N_MODULE_KEYS["content.usecase.contentai.return-on-investment.Financial Benefits"],
          description: [
            {
              heading: I18N_MODULE_KEYS["content.usecase.contentai.return-on-investment.Cost savings"],
              description:
                I18N_MODULE_KEYS["content.usecase.contentai.return-on-investment.Higher speed and efficiency, less trainings costs, reduced errors"]
            },
            {
              heading: I18N_MODULE_KEYS["content.usecase.contentai.return-on-investment.Improved top-line"],
              description:
                I18N_MODULE_KEYS[
                  "content.usecase.contentai.return-on-investment.Enhanced engagement can lead to increased sales, directly impacting the revenue stream"
                ]
            }
          ]
        },
        {
          id: 2,
          imgSrc: PROJECT_ICONS.UC_CA_ROI_OPERATIONAL_BENEFITS_ICON,
          tileColor: "#FFDBE9",
          point: I18N_MODULE_KEYS["content.usecase.contentai.return-on-investment.Operational Benefits"],
          description: [
            {
              heading: I18N_MODULE_KEYS["content.usecase.contentai.return-on-investment.Time saving"],
              description:
                I18N_MODULE_KEYS[
                  "content.usecase.contentai.return-on-investment.Faster content generation, localization, optimization, streamlining campaign execution"
                ]
            },
            {
              heading: I18N_MODULE_KEYS["content.usecase.contentai.return-on-investment.Employee efficiency"],
              description:
                I18N_MODULE_KEYS[
                  "content.usecase.contentai.return-on-investment.Instead of repetitive tasks, human resources can focus on strategic aspects"
                ]
            }
          ]
        },
        {
          id: 3,
          imgSrc: PROJECT_ICONS.UC_CA_ROI_MARKETING_BENEFITS_ICON,
          tileColor: "#CCEEFF",
          point: I18N_MODULE_KEYS["content.usecase.contentai.return-on-investment.Marketing Benefits"],
          description: [
            {
              heading: I18N_MODULE_KEYS["content.usecase.contentai.return-on-investment.Global reach"],
              description:
                I18N_MODULE_KEYS[
                  "content.usecase.contentai.return-on-investment.Content tailoring facilitate effective communication with global audiences, expanding the market footprint"
                ]
            },
            {
              heading: I18N_MODULE_KEYS["content.usecase.contentai.return-on-investment.Market agility"],
              description:
                I18N_MODULE_KEYS[
                  "content.usecase.contentai.return-on-investment.Rapid content generation allow businesses to swiftly react to market trends or changes"
                ]
            }
          ]
        }
      ]
    },
    "success-metrics": {
      id: "success-metrics",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.success-metrics.tabcontents.Success Metrics"],
      content: [
        {
          id: 1,
          imgSrc: PROJECT_ICONS.UC_CA_SM_CONTENT_PRODUCTION_SPEED_ICON,
          tileColor: "#DCEAFF",
          point: I18N_MODULE_KEYS["content.usecase.contentai.success-metrics.Content production speed"],
          description:
            I18N_MODULE_KEYS[
              "content.usecase.contentai.success-metrics.Measure the time taken from content ideation to publishing, and track the number of content pieces produced within a specific timeframe"
            ]
        },
        {
          id: 2,
          imgSrc: PROJECT_ICONS.UC_CA_SM_COST_PER_CONTENT_CALCULATOR_ICON,
          tileColor: "#FFDBE9",
          point: I18N_MODULE_KEYS["content.usecase.contentai.success-metrics.Cost per content piece"],
          description:
            I18N_MODULE_KEYS["content.usecase.contentai.success-metrics.Calculate the cost associated with producing each piece of content"]
        },
        {
          id: 3,
          imgSrc: PROJECT_ICONS.UC_CA_SM_CAC_CONTENT_VIEW_ICON,
          tileColor: "#CCEEFF",
          point: I18N_MODULE_KEYS["content.usecase.contentai.success-metrics.CAC"],
          description: I18N_MODULE_KEYS["content.usecase.contentai.success-metrics.The cost associated with acquiring a customer through the content"]
        },
        {
          id: 4,
          imgSrc: PROJECT_ICONS.UC_CA_SM_ROI_GRAPH_ICON,
          tileColor: "#DCEAFF",
          point: I18N_MODULE_KEYS["content.usecase.contentai.success-metrics.ROI"],
          description:
            I18N_MODULE_KEYS["content.usecase.contentai.success-metrics.Net profit from the content divided by the cost of producing the content"]
        },
        {
          id: 5,
          imgSrc: PROJECT_ICONS.UC_CA_SM_CONVERSION_RATE_ICON,
          tileColor: "#FFDBE9",
          point: I18N_MODULE_KEYS["content.usecase.contentai.success-metrics.Conversion rate"],
          description:
            I18N_MODULE_KEYS["content.usecase.contentai.success-metrics.The percentage of users who take a desired action after viewing the content"]
        },
        {
          id: 6,
          imgSrc: PROJECT_ICONS.UC_CA_SM_ERROR_RATES_ICON,
          tileColor: "#CCEEFF",
          point: I18N_MODULE_KEYS["content.usecase.contentai.success-metrics.Error rates"],
          description:
            I18N_MODULE_KEYS["content.usecase.contentai.success-metrics.Frequency of errors (e.g., grammatical, factual) identified post-publication"]
        },
        {
          id: 7,
          imgSrc: PROJECT_ICONS.UC_CA_SM_REVISION_RATE_ICON,
          tileColor: "#DCEAFF",
          point: I18N_MODULE_KEYS["content.usecase.contentai.success-metrics.Revision rate"],
          description:
            I18N_MODULE_KEYS["content.usecase.contentai.success-metrics.Measure the frequency of revisions or corrections post-production."]
        }
      ]
    }
    /*     "future-potential": {
      id: "future-potential",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.future-potential.tabcontents.Future Potential"],
      content: []
    } */
    /*     customisation: {
      id: "customisation",
      tabName: "Customisation",
      content: []
    },
    "technology-used": {
      id: "technology-used",
      tabName: "Technology used",
      content: []
    } */
  }
};

const hyperPersonalisationData: UseCaseDataType = {
  module: {
    id: "hyper-personalisation",
    name: I18N_MODULE_KEYS["content.dashboard.card.hyper.title"],
    heading: I18N_MODULE_KEYS["content.dashboard.card.hyper.title"],
    description: I18N_MODULE_KEYS["content.dashboard.card.hyper.desc"]
  },
  tabContents: {
    description: {
      id: "description",
      tabName: I18N_MODULE_KEYS["content.text.description"],
      content: []
    },
    "business-value-proposition": {
      id: "business-value-proposition",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Business Value Proposition"],
      content: []
    },
    "return-on-investment": {
      id: "return-on-investment",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.return-on-investment.tabcontents.Return on Investment"],
      content: []
    },
    "success-metrics": {
      id: "success-metrics",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.success-metrics.tabcontents.Success Metrics"],
      content: []
    },
    "future-potential": {
      id: "future-potential",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.future-potential.tabcontents.Future Potential"],
      content: []
    }
    /*     customisation: {
      id: "customisation",
      tabName: "Customisation",
      content: []
    },
    "technology-used": {
      id: "technology-used",
      tabName: "Technology used",
      content: []
    } */
  }
};

const sentimentAnalysisData: UseCaseDataType = {
  module: {
    id: "sentiment-analysis",
    // name: "Sentiment Analysis",
    name: I18N_MODULE_KEYS["content.dashboard.card.sentimentanalysis.title"],
    heading: I18N_MODULE_KEYS["content.dashboard.card.sentimentanalysis.title"],
    description: I18N_MODULE_KEYS["content.dashboard.card.sentimentanalysis.desc"]
  },
  tabContents: {
    description: {
      id: "description",
      tabName: I18N_MODULE_KEYS["content.text.description"],
      content: []
    },
    "business-value-proposition": {
      id: "business-value-proposition",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Business Value Proposition"],
      content: []
    },
    "return-on-investment": {
      id: "return-on-investment",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.return-on-investment.tabcontents.Return on Investment"],
      content: []
    },
    "success-metrics": {
      id: "success-metrics",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.success-metrics.tabcontents.Success Metrics"],
      content: []
    },
    "future-potential": {
      id: "future-potential",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.future-potential.tabcontents.Future Potential"],
      content: []
    }
    /*     customisation: {
      id: "customisation",
      tabName: "Customisation",
      content: []
    },
    "technology-used": {
      id: "technology-used",
      tabName: "Technology used",
      content: []
    } */
  }
};

const kolVideoData: UseCaseDataType = {
  module: {
    id: "kol-video",
    name: I18N_MODULE_KEYS["content.dashboard.card.kolvideo.title"],
    heading: I18N_MODULE_KEYS["content.dashboard.card.kolvideo.title"],
    description: I18N_MODULE_KEYS["content.dashboard.card.kolvideo.desc"]
  },
  tabContents: {
    description: {
      id: "description",
      tabName: I18N_MODULE_KEYS["content.text.description"],
      content: []
    },
    "business-value-proposition": {
      id: "business-value-proposition",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Business Value Proposition"],
      content: []
    },
    "return-on-investment": {
      id: "return-on-investment",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.return-on-investment.tabcontents.Return on Investment"],
      content: []
    },
    "success-metrics": {
      id: "success-metrics",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.success-metrics.tabcontents.Success Metrics"],
      content: []
    },
    "future-potential": {
      id: "future-potential",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.future-potential.tabcontents.Future Potential"],
      content: []
    }
    /*     customisation: {
      id: "customisation",
      tabName: "Customisation",
      content: []
    },
    "technology-used": {
      id: "technology-used",
      tabName: "Technology used",
      content: []
    } */
  }
};

const genAIDashboardAssistant: UseCaseDataType = {
  module: {
    id: "gen-ai-dashboard-assistant",
    name: I18N_MODULE_KEYS["content.dashboard.card.assistant.title"],
    heading: I18N_MODULE_KEYS["content.dashboard.card.assistant.title"],
    description: I18N_MODULE_KEYS["content.dashboard.card.assistant.desc"]
  },
  tabContents: {
    description: {
      id: "description",
      tabName: I18N_MODULE_KEYS["content.text.description"],
      content: []
    },
    "business-value-proposition": {
      id: "business-value-proposition",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Business Value Proposition"],
      content: []
    },
    "return-on-investment": {
      id: "return-on-investment",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.return-on-investment.tabcontents.Return on Investment"],
      content: []
    },
    "success-metrics": {
      id: "success-metrics",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.success-metrics.tabcontents.Success Metrics"],
      content: []
    },
    "future-potential": {
      id: "future-potential",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.future-potential.tabcontents.Future Potential"],
      content: []
    }
    /*     customisation: {
      id: "customisation",
      tabName: "Customisation",
      content: []
    },
    "technology-used": {
      id: "technology-used",
      tabName: "Technology used",
      content: []
    } */
  }
};

const patientJourneyMappingData: UseCaseDataType = {
  module: {
    id: "patient-journey",
    name: I18N_MODULE_KEYS["content.dashboard.card.patientjourney.title"],
    heading: I18N_MODULE_KEYS["content.dashboard.card.patientjourney.title"],
    description: I18N_MODULE_KEYS["content.dashboard.card.patientjourney.desc"]
  },
  tabContents: {
    description: {
      id: "description",
      tabName: I18N_MODULE_KEYS["content.text.description"],
      content: []
    },
    "business-value-proposition": {
      id: "business-value-proposition",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Business Value Proposition"],
      content: []
    },
    "return-on-investment": {
      id: "return-on-investment",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.return-on-investment.tabcontents.Return on Investment"],
      content: []
    },
    "success-metrics": {
      id: "success-metrics",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.success-metrics.tabcontents.Success Metrics"],
      content: []
    },
    "future-potential": {
      id: "future-potential",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.future-potential.tabcontents.Future Potential"],
      content: []
    }
    /*     customisation: {
      id: "customisation",
      tabName: "Customisation",
      content: []
    },
    "technology-used": {
      id: "technology-used",
      tabName: "Technology used",
      content: []
    } */
  }
};

const translationLocalisationData: UseCaseDataType = {
  module: {
    id: "translation-localisation",
    name: I18N_MODULE_KEYS["content.dashboard.card.translationlocalisation.title"],
    heading: I18N_MODULE_KEYS["content.dashboard.card.translationlocalisation.title"],
    description: I18N_MODULE_KEYS["content.dashboard.card.translationlocalisation.desc"]
  },
  tabContents: {
    description: {
      id: "description",
      tabName: I18N_MODULE_KEYS["content.text.description"],
      content: []
    },
    "business-value-proposition": {
      id: "business-value-proposition",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Business Value Proposition"],
      content: []
    },
    "return-on-investment": {
      id: "return-on-investment",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.return-on-investment.tabcontents.Return on Investment"],
      content: []
    },
    "success-metrics": {
      id: "success-metrics",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.success-metrics.tabcontents.Success Metrics"],
      content: []
    },
    "future-potential": {
      id: "future-potential",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.future-potential.tabcontents.Future Potential"],
      content: []
    }
    /*     customisation: {
      id: "customisation",
      tabName: "Customisation",
      content: []
    },
    "technology-used": {
      id: "technology-used",
      tabName: "Technology used",
      content: []
    } */
  }
};

const personalizedvideoData: UseCaseDataType = {
  module: {
    id: "personalized-video",
    name: I18N_MODULE_KEYS["content.dashboard.card.personalizedvideo.title"],
    heading: I18N_MODULE_KEYS["content.dashboard.card.personalizedvideo.title"],
    description: I18N_MODULE_KEYS["content.dashboard.card.personalizedvideo.desc"]
  },
  tabContents: {
    description: {
      id: "description",
      tabName: I18N_MODULE_KEYS["content.text.description"],
      content: []
    },
    "business-value-proposition": {
      id: "business-value-proposition",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Business Value Proposition"],
      content: []
    },
    "return-on-investment": {
      id: "return-on-investment",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.return-on-investment.tabcontents.Return on Investment"],
      content: []
    },
    "success-metrics": {
      id: "success-metrics",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.success-metrics.tabcontents.Success Metrics"],
      content: []
    },
    "future-potential": {
      id: "future-potential",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.future-potential.tabcontents.Future Potential"],
      content: []
    }
    /*     customisation: {
      id: "customisation",
      tabName: "Customisation",
      content: []
    },
    "technology-used": {
      id: "technology-used",
      tabName: "Technology used",
      content: []
    } */
  }
};

const contentcreationData: UseCaseDataType = {
  module: {
    id: "content-creation",
    name: I18N_MODULE_KEYS["content.dashboard.card.contentcreation.title"],
    heading: I18N_MODULE_KEYS["content.dashboard.card.contentcreation.title"],
    description: I18N_MODULE_KEYS["content.dashboard.card.contentcreation.desc"]
  },
  tabContents: {
    description: {
      id: "description",
      tabName: I18N_MODULE_KEYS["content.text.description"],
      content: []
    },
    "business-value-proposition": {
      id: "business-value-proposition",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Business Value Proposition"],
      content: []
    },
    "return-on-investment": {
      id: "return-on-investment",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.return-on-investment.tabcontents.Return on Investment"],
      content: []
    },
    "success-metrics": {
      id: "success-metrics",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.success-metrics.tabcontents.Success Metrics"],
      content: []
    },
    "future-potential": {
      id: "future-potential",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.future-potential.tabcontents.Future Potential"],
      content: []
    }
    /*     customisation: {
      id: "customisation",
      tabName: "Customisation",
      content: []
    },
    "technology-used": {
      id: "technology-used",
      tabName: "Technology used",
      content: []
    } */
  }
};

const iDetailExtractData: UseCaseDataType = {
  module: {
    id: "idetail-extract",
    name: I18N_MODULE_KEYS["module.idetailextract"],
    heading: "Content Transcreation - Your Generative AI-Powered Assistant",
    description: "Process of creating and adapting content from one language to another, while maintaining it's intent, context and style, tone."
  },
  tabContents: {
    description: {
      id: "description",
      tabName: I18N_MODULE_KEYS["content.text.description"],
      content: []
    },
    "business-value-proposition": {
      id: "business-value-proposition",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Business Value Proposition"],
      content: []
    },
    "return-on-investment": {
      id: "return-on-investment",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.return-on-investment.tabcontents.Return on Investment"],
      content: []
    },
    "success-metrics": {
      id: "success-metrics",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.success-metrics.tabcontents.Success Metrics"],
      content: []
    },
    "future-potential": {
      id: "future-potential",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.future-potential.tabcontents.Future Potential"],
      content: []
    }
    /*     customisation: {
      id: "customisation",
      tabName: "Customisation",
      content: []
    },
    "technology-used": {
      id: "technology-used",
      tabName: "Technology used",
      content: []
    } */
  }
};

const translationModule: UseCaseDataType = {
  module: {
    id: "translation-module",
    name: I18N_MODULE_KEYS["module.translation-module"],
    heading: "Content Transcreation - Your Generative AI-Powered Assistant",
    description: "Process of creating and adapting content from one language to another, while maintaining it's intent, context and style, tone."
  },
  tabContents: {
    description: {
      id: "description",
      tabName: I18N_MODULE_KEYS["content.text.description"],
      content: []
    },
    "business-value-proposition": {
      id: "business-value-proposition",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Business Value Proposition"],
      content: []
    },
    "return-on-investment": {
      id: "return-on-investment",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.return-on-investment.tabcontents.Return on Investment"],
      content: []
    },
    "success-metrics": {
      id: "success-metrics",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.success-metrics.tabcontents.Success Metrics"],
      content: []
    },
    "future-potential": {
      id: "future-potential",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.future-potential.tabcontents.Future Potential"],
      content: []
    }
    /*     customisation: {
      id: "customisation",
      tabName: "Customisation",
      content: []
    },
    "technology-used": {
      id: "technology-used",
      tabName: "Technology used",
      content: []
    } */
  }
};

const contentNewtonModule: UseCaseDataType = {
  module: {
    id: "content-newton",
    name: "Content Newton",
    heading: "Content Newton - Your Generative AI-Powered Assistant",
    description: "Process of creating and adapting content from one language to another, while maintaining it's intent, context and style, tone."
  },
  tabContents: {
    description: {
      id: "description",
      tabName: I18N_MODULE_KEYS["content.text.description"],
      content: []
    },
    "business-value-proposition": {
      id: "business-value-proposition",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.business-value-proposition.tabcontents.Business Value Proposition"],
      content: []
    },
    "return-on-investment": {
      id: "return-on-investment",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.return-on-investment.tabcontents.Return on Investment"],
      content: []
    },
    "success-metrics": {
      id: "success-metrics",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.success-metrics.tabcontents.Success Metrics"],
      content: []
    },
    "future-potential": {
      id: "future-potential",
      tabName: I18N_MODULE_KEYS["content.usecase.fieldcopilot.future-potential.tabcontents.Future Potential"],
      content: []
    }
    /*     customisation: {
      id: "customisation",
      tabName: "Customisation",
      content: []
    },
    "technology-used": {
      id: "technology-used",
      tabName: "Technology used",
      content: []
    } */
  }
};

const useCaseData: AllModulesUseCasesDataType = {
  "content-transcreation": contentTranscreationData,
  "next-gist": semanticSearchData,
  "intelligent-assistant": intelligentAssistantData,
  "content-ai": contentAIData,
  "field-copilot": fieldCopilotData,
  "hyper-personalisation": hyperPersonalisationData,
  "sentiment-analysis": sentimentAnalysisData,
  "kol-video": kolVideoData,
  "gen-ai-dashboard-assistant": genAIDashboardAssistant,
  "patient-journey-mapping": patientJourneyMappingData,
  "translation-localisation": translationLocalisationData,
  "personalized-video": personalizedvideoData,
  "content-creation": contentcreationData,
  "idetail-extract": iDetailExtractData,
  "translation-module": translationModule,
  "content-newton": contentNewtonModule
};

export default useCaseData;
