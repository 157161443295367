import { useEffect, useRef, useState } from "react";

const syncScroll = (sourceIframe: HTMLIFrameElement, targetIframe: HTMLIFrameElement) => {
  const sourceDoc = sourceIframe?.contentDocument || sourceIframe?.contentWindow?.document;
  const targetDoc = targetIframe?.contentDocument || targetIframe?.contentWindow?.document;

  if (sourceDoc && targetDoc) {
    const sourceScrollTop = sourceDoc.documentElement.scrollTop;
    targetDoc.documentElement.scrollTop = sourceScrollTop;
  }
};

export function useSyncedIframeScroll(isModalOpen: boolean) {
  const iframe1Ref = useRef<HTMLIFrameElement>(null);
  const iframe2Ref = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (!isModalOpen) return;

    const iframe1 = iframe1Ref.current;
    const iframe2 = iframe2Ref.current;

    if (!iframe1 || !iframe2) return;

    const onIframe1Scroll = () => {
      syncScroll(iframe1, iframe2);
    };

    const onIframe2Scroll = () => {
      syncScroll(iframe2, iframe1);
    };

    const attachScrollListeners = () => {
      const iframe1Window = iframe1?.contentWindow;
      const iframe2Window = iframe2?.contentWindow;

      if (iframe1Window && iframe2Window) {
        iframe1Window.addEventListener("scroll", onIframe1Scroll);
        iframe2Window.addEventListener("scroll", onIframe2Scroll);
      }
    };

    // Check if iframes are already loaded
    if (iframe1?.contentWindow && iframe2?.contentWindow) {
      attachScrollListeners();
    } else {
      // Attach load event listeners if the iframes are not fully loaded yet
      iframe1.onload = attachScrollListeners;
      iframe2.onload = attachScrollListeners;
    }

    return () => {
      if (iframe1?.contentWindow) {
        console.log("removing");
        iframe1.contentWindow.removeEventListener("scroll", onIframe1Scroll);
      }
      if (iframe2?.contentWindow) {
        iframe2.contentWindow.removeEventListener("scroll", onIframe2Scroll);
      }
    };
  }, [isModalOpen]);

  return { iframe1Ref, iframe2Ref };
}
