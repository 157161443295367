import React from "react";
import { Collapse } from "antd";
import { Children } from "./Children";
import { CustomPanelHeader } from "./CustomHeader";
import { capitalizeFirstLetter } from "../../../../../../Utils/_gFunctions/helpers";

interface Data {
  id: string;
  name: string;
  key_message: string;
  reference: string[];
  footnote: string[];
  language: string;
}

export function SuggestedContent({
  data
}: {
  data: {
    id: string;
    text: string;
    type?: string;
    keymessage?: string;
  };
}) {
  const items = [
    {
      key: "1",
      label: <CustomPanelHeader data={data} id={data["id"]} tag={capitalizeFirstLetter(data.keymessage)} title={data.text} />
      // children: <Children references={data["reference"]} footnotes={data["footnote"]} />
    }
  ];
  return (
    <div
      style={{
        border: "1px solid lightGray",
        borderRadius: "10px",
        overflow: "hidden"
      }}
    >
      <Collapse items={items} expandIconPosition="end" bordered={false} style={{ backgroundColor: "white" }} />
    </div>
  );
}
