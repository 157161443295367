import { FC, useMemo, useState, useRef } from "react";
import { Avatar, Button, Card, Col, Row, Typography, Space, Checkbox, Input, Image, Tooltip, Drawer, Spin, Modal, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import Lottie from "lottie-react";
import Markdown from "markdown-to-jsx";

const { Paragraph } = Typography;

interface NextInsightPageProps {
    modalopenfiletype: any;
  setSelectedAudioBoxUrl: (url: string) => void;
  setSelectedImageBoxUrl: (url: string) => void;
  setSelectedVideoBoxUrl: (url: string) => void;
  setSelectedBoxName: (name: string) => void;
  selectedBoxName: string;
  selectedImageBoxUrl: string;
  selectedAudioBoxUrl: string;
  selectedVideoBoxUrl: string;
}

export const SwitchingFileComponent: FC<NextInsightPageProps> = ({modalopenfiletype,setSelectedAudioBoxUrl,setSelectedImageBoxUrl,setSelectedVideoBoxUrl,setSelectedBoxName,selectedBoxName,selectedImageBoxUrl,selectedAudioBoxUrl,selectedVideoBoxUrl}) => {
  const {cardsData,cardsImageData,cardsAudioData,cardsVideoData,searchType} = useSelector((state: any) => state.semanticSearchReducer);
    // Function to handle click on a card
    const handleCardClick = (cardName: any) => {
        setSelectedBoxName(cardName);
        // Update the selected box ID
      };
      const handleCardImageClick = (cardImageurl: any) => {
        setSelectedImageBoxUrl(cardImageurl);
      };
      const handleCardVideoClick = (cardVideourl: any) => {
        setSelectedVideoBoxUrl(cardVideourl);
      };
      const handleCardAudioClick = (cardAudiourl: any) => {
        setSelectedAudioBoxUrl(cardAudiourl);
      };
  
  return (
    <>
       <Card
style={{ width: "100%", boxShadow: "0px 0px 10px #0000001F", height: "550px", borderRadius: "10px", opacity: "1", overflowY: "scroll" }}
>
<Row style={{ height: "100%" }}>
  <Col span={24}>
    <Row style={{}}>
      <Col span={24}>
        <Paragraph
          type="secondary"
          style={{
            font: "normal normal 700 18px/14px Nunito Sans",
            letterSpacing: "0px",
            color: "#000000",
            opacity: "1",
          }}
        >
          {modalopenfiletype==="Document"?searchType==="Web Search"?"Web URL":modalopenfiletype:modalopenfiletype}
          {"s"}
        </Paragraph>
      </Col>

      {modalopenfiletype === "Document" ? (
        cardsData.map((item: any) => (
          <Tooltip title={item.filenames} key={item.id} style={{ zIndex: 99999999 }} overlayStyle={{ zIndex: 99999999 }}>
            <Col
              span={24}
              style={{
                background: item.filename === selectedBoxName ? "#1E4DA1" : "#EDF4FF",
                borderRadius: "10px",
                height: "104px",
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "13px",
                cursor: "pointer",
              }}
              onClick={() => handleCardClick(item.filename)}
            >
              <Image
                preview={false}
                src={modalopenfiletype === "Document" ? searchType==="Web Search"|| searchType==="Med Affairs Agent"?PROJECT_ICONS.WEBSEARCH:PROJECT_ICONS.PDF: PROJECT_ICONS.AUDIOWHITE}
                style={{ height: "42px" }}
              />
            </Col>
          </Tooltip>
        ))
      ) : modalopenfiletype === "Image" ? (
        cardsImageData?.map((item: any) => (
          <Tooltip title={item.filename} key={item.id} style={{ zIndex: 99999999 }} overlayStyle={{ zIndex: 99999999 }}>
            <Col
              span={24}
              className={`card-col ${item.url === selectedImageBoxUrl ? "selected" : ""}`}
              onClick={() => handleCardImageClick(item.url)}
            >
              <Image preview={false} src={item?.url} style={{ height: "100px", borderRadius: "10px" }} />
            </Col>
          </Tooltip>
        ))
      ) : modalopenfiletype === "Video" ? (
        cardsVideoData?.map((item: any) => (
          <Tooltip title={item.filename} key={item.id} style={{ zIndex: 99999999 }} overlayStyle={{ zIndex: 99999999 }}>
            <Col span={24}>
              <video
                src={item?.s3_url}
                style={{
                  height: "120px",
                  borderRadius: "10px",
                  width: "100%",
                  // Added border style here
                }}
                className={`card-col ${item.s3_url === selectedVideoBoxUrl ? "selected" : ""}`}
                onClick={() => handleCardVideoClick(item?.s3_url)}
              />
            </Col>
          </Tooltip>
        ))
      ) : modalopenfiletype === "Audio" ? (
        cardsAudioData?.map((item: any) => (
          <Tooltip title={item.filename} key={item.id} style={{ zIndex: 99999999 }} overlayStyle={{ zIndex: 99999999 }}>
            <Col
              span={24}
              style={{
                background: item.s3_url === selectedAudioBoxUrl ? "#1E4DA1" : "#EDF4FF",
                borderRadius: "10px",
                height: "104px",
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "13px",
                cursor: "pointer",
              }}
              onClick={() => handleCardAudioClick(item?.s3_url)}
            >
              <Image preview={false} src={PROJECT_ICONS.AUDIOWHITE} style={{ height: "42px" }} />
            </Col>
          </Tooltip>
        ))
      ) : (
        <></>
      )}
    </Row>
  </Col>
</Row> 
</Card>  
    </>
  );
};

