import Lottie from "lottie-react";
import { FC } from "react";
import { PROJECT_ICONS } from "../../../Assets/Img/_DIcons";
import { Row, Col, Typography, Card, Button } from "antd";
import { I18N_MODULE_KEYS } from "../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
import { PicCenterOutlined } from "@ant-design/icons";

const { Paragraph } = Typography;
const { Meta } = Card;

// Array of card data
const cardData = [
    {
      title: "Competitive Intelligence Agent",
      description:
        "Empower your decision-making with our Competitive Intelligence Agent. This tool provides comprehensive market insights and competitor analysis, helping you stay ahead of industry trends and strategize effectively.",
      image:PROJECT_ICONS.USECASE_COMPETITIVE_INTELLIGENCE,
      },
    {
      title: "Brand Insight Agent",
      description:
        "Ensure your brand collaterals are up-to-date and get recommendations on key messages and other targeting strategies with the Brand Insight Agent. Specialized journeys help you save time in your routine tasks.",
        image:PROJECT_ICONS.USECASE_BRAND_INSIGHT,
      },
    {
      title: "Medical Literature Summary Agent",
      description:
        "Access critical medical research insights quickly and efficiently with the Medical Literature Summary Agent. It distills complex studies into concise summaries, aiding healthcare professionals in making informed decisions without the burden of excessive reading.",
        image:PROJECT_ICONS.USECASE_ML_SUMMARY,
      },
    {
      title: "Medical Literature Reference Agent",
      description:
        "Simplify your claims referencing process with the Medical Literature Reference Agent. This tool provides quick access to a curated library of essential medical literature, offering reliable references and insights to support the claims of the content.",
        image:PROJECT_ICONS.USECASE_ML_REVIEW,
      },
    {
      title: "Knowledge Localizer Agent",
      description:
        "Seamlessly translate and adapt global medical and pharmaceutical knowledge for local markets with the Knowledge Localizer Agent. This tool assists in creating tailored summaries, assets, and other materials, ensuring that vital information is accessible and relevant across different regions and languages.",
        image:PROJECT_ICONS.USECASE_LOCALISER_ICON,
      },
    
  {
      title: "Regulatory Compliance Tracker: Stay Ahead, Stay Compliant",
      description:
        "Maximize the value of conference videos, notes, presentations, collaterals, etc. with the Conference Analyzer Agent. This agent provides in-depth analysis and summaries of key presentations, sessions, and discussions, helping you extract actionable insights and stay informed on the latest industry developments.",
        image:PROJECT_ICONS.USECASE_COMPETITIVE_INTELLIGENCE,
      },
  ];

export const UseCaseSelection: FC = () => {
  const { t, i18n } = useTranslation();

  return (
    <div style={{ padding: "50px",paddingLeft:'80px' }}>
      <div style={{ display: "flex" }}>
        {/* Left Column with Cards */}
        <Col xs={24} md={17}>
          <Row gutter={[16, 16]}>
            {cardData.map((card, index) => (
              <Col xs={12} key={index}>
                <Card hoverable style={{borderRadius:'!important 15px'}}>
                  <div
                    style={{
                      marginLeft: "10px",
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <img src={card.image} style={{height:'60px'}}/>
                    <div style={{ fontWeight: "bold", fontSize: "20px" }}>
                      {card.title}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginLeft: "85px",
                      marginTop: "5px",
                      color: "#828282",
                      lineHeight:'26px'
                    }}
                  >
                    {card.description}
                  </div>
                  <div style={{display
                    :'flex',
                    justifyContent:'flex-end',marginTop:'15px'
                  }}>
                    <Button style={{background:'#034EA2',color:'white'}}>Demo</Button>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>

        {/* Right Column with Video */}
        <Col xs={24} md={7} style={{ marginLeft: "20px" ,padding:'0'}}>
        <Card
  hoverable
  bodyStyle={{ padding: '30px' }} // Set padding to 0
>
  {/* <img
    alt="example"
    src={PROJECT_ICONS.VIDEOFRAMEIMAGE}
    style={{ width: "100%" }}
  /> */}

  <div style={{width:'100%',background: '#254688BF',height:'500px',borderRadius:'8px'}}>
    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',height:'85%'}}>
  <img src={PROJECT_ICONS.CONTINUE} style={{height:'50px',width:'50px',marginTop:'20px'}}/>
  </div>
  <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
  <div style={{fontWeight:'500',color:'white',fontSize:'26px'}}>Video Description</div>
  </div>
  </div>
</Card>
        </Col>
      </div>
    </div>
  );
};
 