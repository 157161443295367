import { FC, useState } from "react";
import { Card, Col, Row, Typography, Image } from "antd";
import Lottie from "lottie-react";
import Markdown from "markdown-to-jsx";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import { useDispatch, useSelector } from "react-redux";

const { Paragraph } = Typography;

interface NextInsightPageProps {
    modalopenfiletype: any;
    imageparadata: any;
    isLoadingCoffee: boolean;
}

export const ImageSemantic: FC<NextInsightPageProps> = ({ modalopenfiletype, imageparadata, isLoadingCoffee }) => {
    const { searchQuery } = useSelector((state: any) => state.semanticSearchReducer);
    const [showDescription, setShowDescription] = useState(true); // Toggle state

    const parseDescription = (description: string) => {
        try {
            const cleanedDescription = description.replace(/```json\s*([\s\S]*?)```/g, '$1').trim();
            return JSON.parse(cleanedDescription);
        } catch (error) {
            console.error("Error parsing JSON:", error);
            return null;
        }
    };

    const parsedData = parseDescription(imageparadata?.description);

    const toggleView = () => {
        setShowDescription(prev => !prev); // Toggle the view
    };

    return (
        <Card style={{ width: "100%", borderRadius: "10px", boxShadow: "0px 0px 2px #0000001F", height: "550px" }}>
            {isLoadingCoffee ? (
                <>
                    <Lottie style={{ height: "300px", marginTop: "50px" }} animationData={PROJECT_ICONS.CUP} loop={true} />
                    <div style={{ textAlign: "center", color: "#66676B" }}>
                        <div style={{ fontWeight: 600, fontSize: "18px" }}>Grab a Cup of Coffee</div>
                        <div style={{ fontWeight: 600, fontSize: "18px" }}>Loading is in Progress</div>
                    </div>
                </>
            ) : (
                <Row style={{ height: "100%" }}>
                    <Col span={24}>
                        <Row>
                            <Col span={24} style={{ marginTop: "7px" }}>
                                <Paragraph type="secondary" style={{ color: "#000000", fontSize: "16px" }}>
                                    Search Query: {searchQuery}
                                </Paragraph>
                            </Col>
                            <Col
    span={24}
   
>
    <Paragraph
        type="secondary"
        style={{
            fontWeight: 700,
            fontSize: "18px",
            color: "#000000",
            textDecoration: "underline", // Underline on hover
            transition: "text-decoration 0.2s ease",
        }}
        onClick={() => {
          if (imageparadata?.url) {
              window.open(imageparadata.url, "_blank"); // Open URL in a new tab
          }
      }}
        onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
        onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
    >
        {imageparadata?.filename}
    </Paragraph>
</Col>

                            <Row gutter={24}> {/* Adjust the gutter value as needed */}
    <Col span={11}>
        <Image
            src={imageparadata?.url}
            style={{ width: "410px", height: "360px", borderRadius: "5px" }}
            preview={{
                getContainer: () => document.body, // This ensures the preview modal renders at the body level
              }}
        />
    </Col>
    <Col span={13}>
        {parsedData && (
            <>
                <div style={{ display: 'flex', gap: '10px' ,marginBottom:'10px'}}>
                    <div style={{ cursor: 'pointer', fontWeight: 'bold', color: showDescription ? 'rgb(30, 77, 161)' : 'black' }} onClick={()=>{setShowDescription(true)}}>
                        Description
                    </div>
                    <div style={{ cursor: 'pointer', fontWeight: 'bold', color: !showDescription ? 'rgb(30, 77, 161)' : 'black' }} onClick={()=>{setShowDescription(false)}}>
                        Extracted Text
                    </div>
                </div>
                <div style={{ height: '340px', overflowY: 'scroll', marginBottom: '30px' }}>
                    {!showDescription ? (
                        <Markdown>{parsedData?.extracted_text}</Markdown>
                    ) : (
                        <Markdown>{parsedData?.description}</Markdown>
                    )}
                </div> 
            </>
        )}
    </Col> 
</Row>
                        </Row>
                    </Col>
                </Row>
            )}
        </Card>
    );
};
