import { FC, useEffect, useState } from "react";
import { Row, Col } from "antd";
import { FileSummariserWrapper } from "./_FileSummariserWrapper";
import { FileSummariserSearchBar } from "./_FileSummariserSearchBar";
import _localStorageService from "../../../../Services/_localStorageService";
import { SSE } from "sse.js";
import { useSelector } from "react-redux";

export const FileSummariserMainWrapper: FC = () => {
  const { fileForChatWithDoc } = useSelector((state: any) => state.semanticSearchReducer);
  const [fileSummariserQuery, setFileSummariserQuery] = useState<string>("");
  const [isLoadingSummary , setIsLoadingForSummary]=useState<boolean>(false);
  const [fileSummary,setFileSummary]=useState({});
  const apiurl = process.env.REACT_APP_API_URL_FOR_SEMANTIC;
  const token = _localStorageService.getAccessToken();
  
  useEffect(() => {
    if (isLoadingSummary) {
      const accessToken=token||""
      // Define the payload
    const payload = {
      "user_prompt":fileSummariserQuery,
      "filename":fileForChatWithDoc,
      "user_id":0
    }
    const apiUrl = `${apiurl}/document/summarise`;
    const headers = {
      Authorization: accessToken,
      "Content-Type": "application/json",
    };
      // Define the SSE connection with token in the query parameter
        
          const sse = new SSE
          (apiUrl, {
          headers,
          payload: JSON.stringify(payload),
        });
    
        sse.addEventListener("end", (event: any) => {
        const data = JSON.parse(event.data);
        console.log("Data received: ", data);
        setFileSummary(data
      );
        setIsLoadingForSummary(false); // Stop loading once data is received
      });

       sse.onerror = (err:any) => {
        console.error("EventSource failed: ", err);
        sse.close();
        setIsLoadingForSummary(false); // Handle errors and stop loading
      };

      // Cleanup the connection on component unmount
      return () => {
        sse.close();
      };
    }
  }, [isLoadingSummary]); // Triggers when `isLoadingSummary` becomes true


  return (
    <>
      <Row style={{ padding: "6vh 12vh" }} gutter={[16, 16]}>
        <FileSummariserWrapper fileSummariserQuery={fileSummariserQuery} setFileSummariserQuery={setFileSummariserQuery} isLoadingSummary={isLoadingSummary} 
          setIsLoadingForSummary={setIsLoadingForSummary} fileSummary={fileSummary}/>
        <Col span={24} style={{ position: "fixed", bottom: "0px", width: "100%", zIndex: 1000 }}>
          <FileSummariserSearchBar fileSummariserQuery={fileSummariserQuery} setFileSummariserQuery={setFileSummariserQuery} isLoadingSummary={isLoadingSummary} 
          setIsLoadingForSummary={setIsLoadingForSummary}/>
        </Col>
      </Row>
    </>
  );
};
