import React from "react";
import { Card, Checkbox } from "antd";
import "./CardWithOverlayCheckbox.css";

interface CardWithOverlayCheckboxProps<T> {
  item: T;
  isSelected: boolean;
  isPreviewed: boolean;
  onCheck: () => void;
  onClick: () => void;
  checkboxPosition?: "top-left" | "top-right" | "bottom-left" | "bottom-right";
  borderOn: "selected" | "previewed";
  opacityOn: "selected" | "previewed";
  content?: React.ReactNode;
}

const CardWithOverlayCheckbox = <T extends object>({
  item,
  isSelected,
  isPreviewed,
  onCheck,
  onClick,
  checkboxPosition = "top-right",
  borderOn,
  opacityOn,
  content
}: CardWithOverlayCheckboxProps<T>) => {
  const borderColor = borderOn === "selected" && isSelected ? "blue" : borderOn === "previewed" && isPreviewed ? "green" : "transparent";

  const opacity = opacityOn === "selected" && isSelected ? 0.8 : opacityOn === "previewed" && isPreviewed ? 0.6 : 1;

  return (
    <div
      className={`card-with-overlay-checkbox card-container ${checkboxPosition}`}
      style={{
        border: `2px solid ${borderColor}`,
        opacity,
        position: "relative"
      }}
      onClick={onClick}
    >
      <Checkbox checked={isSelected} onChange={onCheck} className="overlay-checkbox" onClick={(e) => e.stopPropagation()} />
      <Card styles={{ body: { padding: 0 } }}>{content}</Card>
    </div>
  );
};

export default CardWithOverlayCheckbox;
