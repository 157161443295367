import React from "react";
import { Dropdown, MenuProps } from "antd";
import { LogoutOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ENUM_ROUTE_URLS } from "../../Utils";
import { useDispatch } from "react-redux";
import _localStorageService from "../../Services/_localStorageService";
import { PROJECT_ICONS } from "../../Assets/Img/_DIcons";

const AppHeader: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    _localStorageService.removeToken();
    dispatch({ type: "USER_LOGOUT" });
    navigate(ENUM_ROUTE_URLS.LOGIN);
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <div style={{ display: "flex", gap: "8px" }} onClick={handleLogout}>
          <LogoutOutlined />
          Logout
        </div>
      ),
      key: "0"
    }
  ];

  const handleLogoClick = () => {
    navigate(ENUM_ROUTE_URLS.DASHBOARD);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "18px 35px",
        background: "#fafafa",
        borderBottom: "1px solid #d1d5db"
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "2px", cursor: "pointer" }} onClick={handleLogoClick}>
        <img src={PROJECT_ICONS.CN_APP_LOGO} alt="Header Logo" />
        <div style={{ fontSize: "12px", textAlign: "left", fontWeight: 700 }}>
          <div>Content</div>
          <div>Super App</div>
        </div>
      </div>

      <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
        <img src={PROJECT_ICONS.CN_HEADER_NOTIFICATION} alt="Notification" style={{ height: "24px", cursor: "pointer" }} />
        <div style={{ color: "#767676", display: "flex", gap: "5px", cursor: "pointer" }}>
          <span style={{ fontSize: "14px" }}>Help</span>
          <QuestionCircleOutlined />
        </div>
        <Dropdown menu={{ items }}>
          <img src={PROJECT_ICONS.CN_HEADER_AVATAR} alt="Avatar" style={{ height: "24px", cursor: "pointer" }} />
        </Dropdown>
      </div>
    </div>
  );
};

export default AppHeader;
