import { Checkbox, Col, Row, Tooltip } from "antd";
import { mockSSEEndDataMessage } from "../mockData";
import { EyeOutlined } from "@ant-design/icons";
import "./ModulesListing.css";
import { useEffect, useState } from "react";
import { ModulesPreviewModal } from "../ModulesPreview/ModulesPreviewModal";
import { ModuleType } from "../types";
import { useAppDispatch } from "../../../../../Store/hooks";
import { setCNSelectedModuleIds } from "../../../../../Store/actions/_contentNewtonActions";

export const ModulesListing = ({ modules }: { modules: ModuleType[] }) => {
  // const modulesList = mockSSEEndDataMessage.data as ModuleType[];
  const [modulesList, setModulesList] = useState<ModuleType[]>([]);
  const [selectedModuleIds, setSelectedModuleIds] = useState<number[]>([]);
  const [openPreview, setOpenPreview] = useState(false);
  const [previewItem, setPreviewItem] = useState<ModuleType>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    setModulesList(modules);
  }, [modules]);
  const handleSelectDeselect = (id: number) => {
    if (selectedModuleIds.includes(id)) {
      setSelectedModuleIds(selectedModuleIds.filter((item) => item !== id));
      //Global state update
      dispatch(setCNSelectedModuleIds(selectedModuleIds.filter((item) => item !== id)));
    } else {
      setSelectedModuleIds([...selectedModuleIds, id]);
      //Global state update
      dispatch(setCNSelectedModuleIds([...selectedModuleIds, id]));
    }
  };

  // modulesList?.flatMap((item, index) =>
  //   Array.from({ length: 2 + 1 }).map((_, i) => ({
  //     ...item,
  //     id: `${item.module_id}-${i}`
  //   }))
  // );

  return (
    <Row style={{ display: "flex" }}>
            <Col span={24} /* style={{ display: "flex", flexDirection: "column" }} */>

      <Row gutter={8} style={{ display: "flex", flexWrap: "nowrap", overflowX: "auto", overflowY: "hidden", width: "100%", flex:1 }}>
        {modulesList?.map((item) => (
          <Col span={6} key={item.id}>
            <div className="checkbox-tile content-container" onClick={() => handleSelectDeselect(item.id)}>
              <Checkbox
                checked={selectedModuleIds.includes(item.id)}
                onClick={(e) => e.stopPropagation()}
                onChange={() => handleSelectDeselect(item.id)}
                className="checkbox-overlay"
              />
              {/* <img src={item.thumbnail} className={`item ${isSelected(key, item.asset_id) ? "selected-item" : ""}`} /> */}
              <div style={{ /* height: "250px", */ height: "100%", padding: "40px", background: "#2a0874", color: "white", borderRadius: "8px" }}>
                <div>
                  <span style={{ fontWeight: "bold" }}>Module ID: </span>
                  <span>{item.module_id}</span>
                </div>
                <div>
                  <span style={{ fontWeight: "bold" }}>Claim: </span>
                  <span>{item.claims.find((claim) => claim.type.toLowerCase() === "primary")?.text}</span>
                </div>
              </div>
              <Tooltip title="Preview">
                <EyeOutlined
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    fontSize: "18px",
                    color: "#fff",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    borderRadius: "50%",
                    padding: "3px",
                    cursor: "pointer"
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setPreviewItem(item as ModuleType);
                    setOpenPreview(true);
                  }}
                />
              </Tooltip>
            </div>
          </Col>
        ))}
      </Row>
      {previewItem && openPreview && (
        <ModulesPreviewModal open={openPreview} setOpen={setOpenPreview} previeweditem={previewItem} modulesList={modulesList} />
      )}
      </Col>
    </Row>
  );
};
