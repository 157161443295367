import { CRConversationArea } from "./components/CRConversationArea";
import { CRModuleFooter } from "./components/CRModuleFooter";
import { CRModuleHeader } from "./components/CRModuleHeader";

export const CRContentArea: React.FC = () => {
  return (
    <div
      className="CRContentArea"
      style={{ flex: 1, padding: "20px 20px 5px", display: "flex", flexDirection: "column" }}
    >
      <CRModuleHeader />
      <CRConversationArea />
      <CRModuleFooter />
    </div>
  );
};
