import { Button, Col, Modal, Row } from "antd";
import { mockAssebleData, mockLocalisedImages } from "../mockData";
import { ImageType, LocalisedAssetType, OriginalAssetType } from "../AssetLocalisation";
import { useEffect, useState } from "react";
import { CustomSelect } from "../../../components/common/Dropdowns/CustomSelect";
import { capitalizeFirstLetter } from "../../../../../Utils/_gFunctions/helpers";
import { useSyncedIframeScroll } from "../../../../../hooks/useSyncedIframeScroll";

const scrollBarStyles = `
<style>
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 12px;
  }
  ::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 12px;
  }
</style>
`;

export const AssembleModal: React.FC<{
  open: boolean;
  setOpen: Function;
  originalAsset: OriginalAssetType;
  localisedAssets: LocalisedAssetType[];
  translation?: boolean;
}> = ({ open, setOpen, originalAsset, localisedAssets, translation }) => {
  const [asset, setAsset] = useState<OriginalAssetType>();
  const [localisedAssetList, setLocalisedAssetList] = useState<LocalisedAssetType[]>([]);
  const [markets, setMarkets] = useState<string[]>([]);
  const [selectedMarket, setSelectedMarket] = useState<string>("");

  const { iframe1Ref, iframe2Ref } = useSyncedIframeScroll(open);

  //@ts-ignore
  //   localisedAssets = mockAssebleData;

  useEffect(() => {
    setAsset(originalAsset);
  }, [originalAsset]);

  useEffect(() => {
    setLocalisedAssetList(localisedAssets);
    const updatedMarkets = getMarkets();
    setMarkets(updatedMarkets);
    setSelectedMarket(updatedMarkets[0] || "");
  }, [localisedAssets]);

  useEffect(() => {
    const filteredData = localisedAssets.filter((asset) => asset.market?.toLowerCase() === selectedMarket?.toLowerCase());
    setLocalisedAssetList(filteredData);
  }, [selectedMarket]);

  function getMarkets() {
    return Array.from(new Set(localisedAssets.map((asset) => asset.market && capitalizeFirstLetter(asset.market))));
  }

  const getLocalisedAsset = () => {
    return localisedAssetList.find((asset) => asset.market?.toLowerCase() === selectedMarket?.toLowerCase());
  };

  const oncancel = () => {
    setOpen(false);
  };

  const onOk = () => {
    setOpen(false);
  };

  const getImageComponent = (url: string) => {
    return (
      <Col span={12}>
        <img src={url} width={"100%"} style={{ borderRadius: "10px", border: "1px lightgray solid" }} />
      </Col>
    );
  };

  return (
    <Modal
      open={open}
      className="cn-fullscreen-modal"
      title={
        <span style={{ fontSize: "large", paddingLeft: ".7rem" }}>
          <b>Assembly : </b>
          <span style={{ fontWeight: "lighter" }}>Localised Asset {translation ? "with" : "without"} Translation</span>
        </span>
      }
      onCancel={oncancel}
      onOk={onOk}
      onClose={oncancel}
      styles={{
        body: {
          overflow: "hidden",
          //   border: "1px lightgray solid",
          borderRadius: "10px",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "10px"
        }
      }}
    >
      <Row gutter={[16, 16]} style={{ width: "100%" }}>
        <Col xs={24} sm={12}>
          <CustomSelect
            w="20rem"
            selectedTag={selectedMarket}
            optionsList={markets}
            placeholder="Select"
            onChange={(val: string) => setSelectedMarket(val)}
            fieldName="Market"
          />
        </Col>
      </Row>
      <Row style={{ width: "100%", fontWeight: "bold" }}>
        <Col span={12}>Original Asset</Col>
        <Col span={12}>{translation ? "Translated" : "Localised"} Asset</Col>
      </Row>
      {/* <div style={{ overflow: "auto", width: "100%" }}> */}
      <Row gutter={[8, 8]} style={{ width: "100%", flex: 1 }}>
        <Col span={12}>
          <iframe
            ref={iframe1Ref}
            srcDoc={scrollBarStyles + originalAsset.html}
            style={{ flex: "1 1 auto", border: "solid gray 1px", borderRadius: "5px", width: "100%", height: "100%" }}
          />
        </Col>
        <Col span={12}>
          <iframe
            ref={iframe2Ref}
            srcDoc={scrollBarStyles + getLocalisedAsset()?.html}
            style={{ flex: "1 1 auto", border: "solid gray 1px", borderRadius: "5px", width: "100%", height: "100%" }}
          />
        </Col>
      </Row>
      {/* </div> */}
    </Modal>
  );
};
