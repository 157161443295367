import React from "react";

export const CRModuleFooter: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: "10px 20px",
        backgroundColor: "#f5f5f5",
        fontFamily: "Arial, sans-serif",
        fontSize: "12px",
        color: "#8c8c8c",
      }}
    >
      <span
        style={{
          fontSize: "12px",
          color: "#8c8c8c",
        }}
      >
        Powered By <b>NEXT AI</b>
      </span>
      <span
        style={{
          fontSize: "12px",
          color: "#8c8c8c",
          textAlign: "right",
          fontWeight: "bold",
        }}
      >
        © 2024 Indegene. All Rights Reserved.
      </span>
    </div>
  );
};
