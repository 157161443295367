import * as _authActions from "./_authActions";
import * as _commonActions from "./_commonActions";
import * as _contentAIActions from "./_contentAIActions";
import * as _contentTranscreationActions from "./_contentTranscreationActions";
import * as _semanticSearchActions from "./_semanticSearchActions";
import * as _contentNewtonActions from "./_contentNewtonActions";

export default {
  ..._authActions,
  ..._commonActions,
  ..._contentAIActions,
  ..._contentTranscreationActions,
  ..._semanticSearchActions,
  ..._contentNewtonActions
};
