// import "./style.css";
import { useState } from "react";
import React, { useEffect } from "react";
import { Button, Col, Divider, Modal, Row, Skeleton } from "antd";
import { CustomSelect } from "../../../components/common/Dropdowns/CustomSelect";
import { ClaimIdentificationWithouthTranslation } from "./ClaimIdentificationWithouthTranslation";
import { ClaimIdentificationWithouthTranslationDark } from "./ClaimIdentificationWithouthTranslationDark";
import { ClaimType, EquivalentClaimType } from "../AssetLocalisation";
import { ContentNewtonState } from "../../../../../Store/reducers/_contentNewtonReducer";
import { useAppSelector } from "../../../../../Store/hooks";
import { FilterOutlined } from "@ant-design/icons";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import { capitalizeFirstLetter } from "../../../../../Utils/_gFunctions/helpers";

interface Claim {
  id: string;
  name: string;
  key_message: string;
  reference: string[];
  footnote: string[];
  language: string;
}

export const LocalisedClaimsModal: React.FC<{
  open: boolean;
  setOpen: Function;
  headerAddOn?: string;
  localisedClaims: ClaimType[];
  translation?: boolean;
}> = ({ open, setOpen, headerAddOn, localisedClaims, translation }) => {
  //   const { localisedAssetData }: ContentNewtonState = useAppSelector((state) => state.contentNewtonReducer);

  const handleCancel = () => setOpen((prev: boolean) => !prev);
  const handleOk = () => setOpen((prev: boolean) => !prev);

  //   const localisedClaims = localisedAssetData?.claims || [];
  const [claims, setClaims] = useState<ClaimType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [markets, setMarkets] = useState<string[]>([]);
  const [selectedMarket, setSelectedMarket] = useState<string>("");
  const [selectedItemIndices, setSelectedItemIndices] = useState<number[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [selectedTag, setSelectedTag] = useState<string>("All");

  useEffect(() => {
    setClaims(localisedClaims);
    setTags(getTypeTags());

    const updatedMarkets = getMarkets();
    setMarkets(updatedMarkets);
    setSelectedMarket(updatedMarkets[0] || "");
  }, [localisedClaims]);

  function getTypeTags() {
    return Array.from(
      new Set(
        localisedClaims
          .filter((claim) => claim.keymessage)
          .map((claim) => capitalizeFirstLetter(claim.keymessage))
          .filter((type) => type !== undefined)
      )
    );
  }

  function getMarkets() {
    return Array.from(new Set(localisedClaims.map((claim) => claim.market && capitalizeFirstLetter(claim.market))));
  }

  useEffect(() => {
    if (selectedTag !== "All") {
      const filteredData = localisedClaims.filter(
        (claim) => claim.keymessage?.toLowerCase() === selectedTag?.toLowerCase() && claim.market?.toLowerCase() === selectedMarket?.toLowerCase()
      );
      setClaims(filteredData);
    } else {
      setClaims(localisedClaims.filter((claim) => claim.market?.toLowerCase() === selectedMarket?.toLowerCase()));
    }
  }, [selectedTag, selectedMarket]);

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    setSelectedTag(value);
  };

  return (
    <Modal
      className="cn-fullscreen-modal"
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button style={{ marginRight: "3rem" }} key="submit" onClick={handleOk} type="primary">
          Save
        </Button>
      ]}
      open={open}
      onCancel={handleCancel}
      title={
        <span style={{ fontSize: "large", paddingLeft: ".7rem" }}>
          <b>{translation ? "Translation" : "Localisation"} : </b>
          <span style={{ fontWeight: "lighter" }}>Equivalent Claim Identification {translation ? "with" : "without"} Translation</span>
        </span>
      }
    >
      <Row
        gutter={[10, 10]}
        style={{
          borderRadius: ".5rem",
          padding: ".5rem",
          width: "100%"
        }}
      >
        <Row gutter={[16, 16]} style={{ width: "full" }}>
          <Col xs={24} sm={12}>
            <CustomSelect
              w="20rem"
              optionsList={["All"]}
              placeholder="Select"
              icon={<img src={PROJECT_ICONS.CN_FILTER_ICON} />}
              onChange={handleChange}
              tagsDropDown={tags}
              selectedTag={selectedTag}
            />
          </Col>

          <Col xs={24} sm={12}>
            <CustomSelect
              w="20rem"
              selectedTag={selectedMarket}
              optionsList={markets}
              placeholder="Select"
              onChange={(val: string) => setSelectedMarket(val)}
              fieldName="Market"
              //   icon={<img src={langageIcon} />}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ width: "100%" }}>
          <Col style={{ flex: "1" }}>
            <b>
              Original Element{" "}
              <span style={{ fontWeight: 400, color: "gray", fontSize: "14px" }}>
                (Market : <span style={{ fontWeight: 500, color: "black" }}>US</span>)
              </span>
            </b>
          </Col>
          <Col style={{ flex: "1" }}>
            <b>
              Equivalent Elements{" "}
              <span style={{ fontWeight: 400, color: "gray", fontSize: "14px" }}>
                (Market : <span style={{ fontWeight: 500, color: "black" }}>{selectedMarket}</span>)
              </span>
            </b>
          </Col>
        </Row>
        <Skeleton loading={isLoading} paragraph={{ rows: 12 }}>
          <>
            {claims.map((item, index: number) => {
              return (
                <>
                  <Row gutter={[16, 16]} style={{ width: "100%" }}>
                    <Col style={{ flex: "1" }} key={item.id}>
                      <ClaimIdentificationWithouthTranslation
                        data={item}
                        setSelectedItemIndices={setSelectedItemIndices}
                        Index={index}
                        selectedItemIndices={selectedItemIndices}
                      />
                    </Col>

                    <Col style={{ flex: "1" }}>
                      <ClaimIdentificationWithouthTranslationDark
                        data={item.equivalent}
                        isSuggestionsAvailable={true}
                        setSelectedItemIndices={setSelectedItemIndices}
                        Index={index}
                        selectedItemIndices={selectedItemIndices}
                      />
                    </Col>
                  </Row>
                  <Divider />
                </>
              );
            })}
          </>
        </Skeleton>
      </Row>
    </Modal>
  );
};
