import { combineReducers } from "redux";

import authReducer from "./reducers/_authReducer";
import commonReducer from "./reducers/_commonReducer";
import contentTranscreationReducer from "./reducers/_contentTranscreationReducer";
import actionDrawerReducer from "../Components/ActionDrawer/State/_reducer";
import semanticSearchReducer from "./reducers/_semanticSearchReducer";
import contentAIReducer from "./reducers/_contentAIReducer";
import nonPersistedReducer from "./reducers/_nonPersistedReducer";
import iDetailExtractReducer from "./reducers/_iDetailExtractReducer";
import translationModuleReducer from "./reducers/_translationModuleReducer";
import playerReducer from "../Components/Player/_playerReducer";
import contentNewtonReducer from "./reducers/_contentNewtonReducer";

const appReducer = combineReducers({
  commonReducer,
  authReducer,
  contentTranscreationReducer,
  actionDrawerReducer,
  semanticSearchReducer,
  contentAIReducer,
  nonPersistedReducer,
  playerReducer,
  iDetailExtractReducer,
  translationModuleReducer,
  contentNewtonReducer
});

export const rootReducer = (state: any, action: any) => {
  if (action?.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
