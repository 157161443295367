import React from "react";
import { useDynamicSizeText } from "../../../../../hooks/useDynamicSizeText";
import { ModuleType } from "../types";

interface ModulePreviewTileProps {
  item: ModuleType;
  selectedItem?: ModuleType;
  setSelectedItem: Function;
}

export const ModulePreviewTile: React.FC<ModulePreviewTileProps> = ({ item, selectedItem, setSelectedItem }) => {
  const { ref } = useDynamicSizeText(6, 14);

  return (
    <div
      ref={ref}
      style={{
        height: "150px",
        width: "100%",
        padding: "10px",
        background: "#2a0874", //"#104862",
        color: "white",
        borderRadius: "8px",
        border: selectedItem?.id === item.id ? "3px solid #42a6c6" : "3px solid transparent",
        opacity: selectedItem?.id === item.id ? 0.7 : 1,
        userSelect: "none",
        cursor: "pointer"
      }}
      onClick={() => setSelectedItem(item)}
    >
      <div>
        <span style={{ fontWeight: "bold" }}>Module ID: </span>
        <span>{item.module_id}</span>
      </div>
      <div>
        <span style={{ fontWeight: "bold" }}>Claim: </span>
        <span>{item.claims.find((claim) => claim.type?.toLowerCase() === "primary")?.text}</span>
      </div>
    </div>
  );
};
