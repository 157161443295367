import { FC, useEffect, useState } from "react";
import { Row, Col, Button } from "antd";
import _localStorageService from "../../../../Services/_localStorageService";
import { SSE } from "sse.js";
import { useSelector, useDispatch } from "react-redux"; // Added useDispatch
import { setSearchQuery } from "../../../../Store/actions/_semanticSearchActions";

interface PromptSuggestionProps{
  handleAdvancedSearchClose: () => void;
}

export const PromptSuggestion: FC<PromptSuggestionProps> = ({ handleAdvancedSearchClose }) => {
  const { fileForChatWithDoc } = useSelector((state: any) => state.semanticSearchReducer);
  const dispatch = useDispatch(); // Hook to dispatch actions
  const [stepper, setStepper] = useState<number>(1);
  const [stepperForPrompting, setStepperForPrompting] = useState(0);
  const [selectedChoices, setSelectedChoices] = useState<{ [key: string]: string }>({});
  const [editableParagraph, setEditableParagraph] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [tempPrompt, setTempPrompt] = useState(""); // Local state for text area
  const [selectedPromptSuggestion,setSuggestedPromptSuggestion]=useState("");
  const [paragraph,setParagraph]=useState<any[]>([])

  const handleChoiceClick = (choiceKey: string, choiceValue: string) => {
    setSelectedChoices((prev) => {
      const newChoices = {
        ...prev,
        [choiceKey]: choiceValue,
      };
      if (Object.keys(newChoices).length === 6) {
        setStepperForPrompting(2);
      }
      return newChoices;
    });
  };

  const promptSuggestions = 
  [
    {title:"Commercial Strategy Overview",text:"This prompt guides you to summarize a strategy report for a specific therapeutic area, focusing on key aspects like market penetration, geographic expansion, and pricing strategy. Keep the summary concise, under 150 words, and ensure that critical insights on pharma commercialization and market positioning are emphasized",paragraph: [
      "Summarize the provided ",
      {
        key: "strategyType",
        choices: ["commercial", "market access", "financial"],
      },
      " strategy report for the ",
      {
        key: "therapeuticArea",
        choices: ["oncology", "cardiovascular", "CNS"],
      },
      " drug. Focus on the key points of ",
      {
        key: "focusArea",
        choices: ["market penetration", "geographic expansion", "pricing strategy"],
      },
      ". Keep the summary ",
      {
        key: "summaryLength",
        choices: ["concise", "detailed"],
      },
      ", under 150 words, and highlight critical insights relevant to ",
      {
        key: "relevanceArea",
        choices: ["pharma commercialization", "market positioning"],
      },
      "."
    ]},
    {title:"Regulatory Submission Report Summary",text:"Summarize the key regulatory milestones, submission dates, and compliance requirements for a regulatory submission. Highlight the feedback from authorities such as the FDA, EMA, or local regulatory bodies, with a formal tone suitable for an internal pharma compliance review.",paragraph:[
      "Provide a summary of the regulatory submission report for the ",
      {
        key: "productType",
        choices: ["oncology", "rare disease", "vaccine"],
      },
      " product. Emphasize the key ",
      {
        key: "milestones",
        choices: ["regulatory milestones", "submission dates", "compliance requirements"],
      },
      " and the implications of ",
      {
        key: "authority",
        choices: ["FDA", "EMA", "local authority"],
      },
      " feedback. Use a ",
      {
        key: "tone",
        choices: ["formal", "informative"],
      },
      " tone suitable for an internal pharma compliance review."
    ]},
    {title:"Sales Forecast Summary for Product Line",text:"Summarize the financial forecast or commercial report for a specified product line, focusing on revenue growth, market share, and product performance. The tone is informative, emphasizing key factors influencing sales, market entry, or competitor activity",paragraph:[
      "Summarize the ",
      {
        key: "forecastType",
        choices: ["financial forecast", "commercial forecast", "sales projections", "financial report"],
      },
      " for the ",
      {
        key: "productLine",
        choices: ["cardiology", "oncology", "immunology"],
      },
      " product line. Focus on the expected revenue growth, market share, and ",
      {
        key: "performanceMetrics",
        choices: ["regional sales breakdown", "product portfolio performance"],
      },
      ". The tone should be ",
      {
        key: "forecastTone",
        choices: ["optimistic", "cautious", "informative"],
      },
      ", highlighting major factors influencing ",
      {
        key: "influenceArea",
        choices: ["sales", "market entry", "competitor activity"],
      },
      "."
    ]}
  ]


  // Utility function to generate paragraph as string
  const generatePromptString = () => {
    return paragraph
      .map((part) => {
        if (typeof part === "string") {
          return part;
        } else {
          const selected = selectedChoices[part.key];
          return selected || "";
        }
      })
      .join("");
  };

  const renderParagraph = () => {
    return paragraph.map((part, index) => {
      if (typeof part === "string") {
        return (
          <span key={index} style={{ fontSize: "16px" }}>
            {part}
          </span>
        );
      } else {
        const selected = selectedChoices[part.key];
        if (selected) {
          return (
            <span key={index} style={{ fontWeight: "bold", fontSize: "16px" }}>
              {selected}
            </span>
          );
        } else {
          return (
            <span key={index}>
              {part.choices.map((choice:any, choiceIndex:any) => (
                <span key={choice}>
                  {choiceIndex > 0 && " / "} {/* Add slash before all but the first choice */}
                  <Button
                    type="link"
                    onClick={() => handleChoiceClick(part.key, choice)}
                    style={{ padding: "0 0px", fontSize: "16px", color: "#1E4DA1" }}
                  >
                    {choice}
                  </Button>
                </span>
              ))}
            </span>
          );
        }
      }
    });
  };

  const handleUsePrompt = () => {
    const promptString = generatePromptString();
    
    // Filter out all paragraph parts that are objects (i.e., dynamic selections)
    const dynamicParagraphKeys = paragraph.filter(part => typeof part === "object").map(part => part.key);
  
    // Check if the number of selected choices matches the number of dynamic parts in the paragraph
    if (Object.keys(selectedChoices).length === dynamicParagraphKeys.length) {
      dispatch(setSearchQuery(promptString)); // Dispatch the generated prompt
      handleAdvancedSearchClose();
    } else {
      // Handle case where not all choices are selected (you can show an alert or message if needed)
      console.log("Not all choices have been made.");
    }
  };
  

  return (
    <>
      <div className="multillm-container">
        <div style={{fontWeight:'bold',marginTop:'10px',marginLeft:'30px',fontSize:'16px'}}>
            Choose Prompt Suggestion Template
        </div>
        <Row>
          {stepper === 1 ? (
            <>  
              <Col span={24} style={{ backgroundColor: "white", padding: "30px", paddingRight: "10px", paddingLeft: "20px" ,height:'350px',overflowY:'scroll',paddingTop:'20px'}}>
                <Row gutter={[16, 16]} wrap={true} style={{display:'flex',gap:'30px'}}>
                {promptSuggestions.map((suggestion, index) => (
  <Col
    key={index}
    span={5}
    style={{
      borderRadius: '5px',
      border: '1px solid #DBDBDB',
      padding: '10px',
      marginLeft: '10px',
      cursor:'pointer'
    }}
    onClick={() => {setSuggestedPromptSuggestion(suggestion.title); setStepper(2);setParagraph(suggestion.paragraph

    )
      }
    }
  >
    <div style={{ color: '#1E4DA1', fontSize: '14px', fontWeight: 'bold' }}>
      {suggestion.title}
    </div>
    <div style={{ color: '#5F5F5F', fontSize: '12px', marginTop: '10px' }}>
      {suggestion.text}
    </div>
  </Col>
))}

                </Row>
              </Col>
              <div style={{display:'flex',justifyContent:'flex-end',marginTop:'10px',padding:'20px',width:'100%'}}>
                <Button style={{color:'white',background:'#1E4DA1'}} onClick={handleAdvancedSearchClose}>CANCEL</Button>
              </div>
            </>
          ) : (
            <>
              <Row>
                <Col span={24} style={{ backgroundColor: "white", padding: "30px", paddingRight: "10px", paddingLeft: "20px" ,height:'350px',overflowY:'scroll',paddingTop:'20px'}}>
                  <p style={{ fontSize: "14px", cursor: "pointer", paddingLeft: "10px" }}>{renderParagraph()}</p>
                </Col>
              </Row>
              <Row style={{display:'flex',justifyContent:'space-between',marginTop:'10px',padding:'20px',width:'100%'}}>
                <div>
                  <Button style={{color:'#1E4DA1',border:'1px solid #1E4DA1'}} onClick={handleAdvancedSearchClose}>CANCEL</Button>
                </div>
                <div>
                  <Button type="link" style={{color:'#1E4DA1',marginRight:'10px',fontSize:'bold'}} onClick={() => { setStepper(1); setSelectedChoices({}) }}>GO BACK</Button>
                  <Button style={{color:'white',background:'#1E4DA1'}} onClick={handleUsePrompt}>USE PROMPT</Button>
                </div>
              </Row>
            </>
          )}
        </Row>
      </div>
    </>
  );
};

