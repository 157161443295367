import { Button, Menu } from "antd";
import { PROJECT_ICONS } from "../../../Assets/Img/_DIcons";
import { siteConfig } from "../../../Services";
import { resetCNStates } from "../../../Store/actions/_contentNewtonActions";
import { useAppDispatch } from "../../../Store/hooks";
import DropdownWithCopy from "./DropdownWithCopy";
import { useState } from "react";

const CN_DEV_URL = process.env.REACT_APP_API_URL_FOR_CONTENT_NEWTON_DEV;
const CN_LOCAL_URL = process.env.REACT_APP_API_URL_FOR_CONTENT_NEWTON_LOCAL;

const isDevMode = () => {
  return process.env.NODE_ENV === "development";
};

const isSourceURLDev = () => {
  return localStorage.getItem("contentNewtonURL") === CN_DEV_URL;
};

export const CRModuleHeader = () => {
  const [selectedSourceURL, setSelectedSourceURL] = useState(localStorage.getItem("contentNewtonURL") || CN_DEV_URL);
  const dispatch = useAppDispatch();

  const updateSourceURL = (url: string) => {
    setSelectedSourceURL(url);
    localStorage.setItem("contentNewtonURL", url);
    siteConfig.BASE_URL = url;
    // dispatch(resetCNStates());
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div
        style={{
          fontSize: "24px",
          fontWeight: 700,
          display: "flex",
          alignItems: "center",
          gap: "5px"
        }}
      >
        <img src={PROJECT_ICONS.CN_CONTENT_NEWTON_MODULE_ICON} height={30} width={30} alt="Content Newton Module Icon" />
        Content AI
      </div>
      {isDevMode() && <DropdownWithCopy />}

      {/* TODO: Remove this */}
      <div>
        {isDevMode() && (
          <>
            <Button type={!isSourceURLDev() ? "primary" : "link"} onClick={() => updateSourceURL(CN_LOCAL_URL || "http://127.0.0.1:8000/api")}>
              Local
            </Button>
            <Button type={isSourceURLDev() ? "primary" : "link"} onClick={() => updateSourceURL(CN_DEV_URL || "http://newton-dev.devkraft.in")}>
              Dev
            </Button>
          </>
        )}
        <Button
          type="link"
          onClick={() => {
            dispatch(resetCNStates());
          }}
        >
          Reset
        </Button>
      </div>

      {/* <div style={{ color: "#00A739", fontSize: "12px", display: "flex", alignItems: "center" }}>ID: 00795</div> */}
    </div>
  );
};
