import { useState, useEffect, useRef } from "react";

export const useDynamicSizeText = (minFontSize: number = 10, maxFontSize: number = 100) => {
  const ref = useRef<HTMLDivElement>(null);
  const [fontSize, setFontSize] = useState(maxFontSize);

  useEffect(() => {
    const adjustFontSize = () => {
      const container = ref.current;
      if (!container) return;

      let currentFontSize = maxFontSize;
      container.style.fontSize = `${currentFontSize}px`;

      while ((container.scrollHeight > container.clientHeight || container.scrollWidth > container.clientWidth) && currentFontSize > minFontSize) {
        currentFontSize -= 1;
        container.style.fontSize = `${currentFontSize}px`;
      }

      setFontSize(currentFontSize);
    };

    adjustFontSize();

    window.addEventListener("resize", adjustFontSize);
    return () => window.removeEventListener("resize", adjustFontSize);
  }, [maxFontSize, minFontSize]);

  return { ref, fontSize };
};
