import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ContentListItem } from "./_ContentListItem";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import { apiResponse } from "../../../Utils/_gTypes";
import { PROJECT_ICONS } from "../../../Assets/Img/_DIcons";
import { I18N_MODULE_KEYS } from "../../../Components/i18n/core/constants";
import _iDetailExtractRequests from "../../../Store/requests/_iDetailExtractRequests";
import { Button, Card, Checkbox, Col, Flex, Image, Modal, Radio, RadioChangeEvent, Row, Select, Space, Tooltip, Typography } from "antd";
import {
  setAssetMainDataIDXAction,
  setClickedPageNumberIDXAction,
  setOpenCategoryModalStateIDXAction,
  setOpenHtmlOutputModalIDXAction
} from "../../../Store/actions/_iDetailExtractActions";
import { AssetTypeListTypes, CoreClaimContentTypes, CoreClaimPageOccurence, CoreClaimsTypes, ListKeyDataTypes } from "../core/modal";
import { siteConfig } from "../../../Services";
import { SSE } from "sse.js";

const { Text } = Typography;

type IProps = {
  coreClaims: CoreClaimsTypes[] | null;
  assetGuideId: number | null;
};

const enumCurrentTab = {
  ASSET_TYPE: "assetTypeList",
  CLAIM_TYPE: "selectedCategories"
};

const getCustomSSE = (url: string, isJson: boolean = true, payload?: any) => {
  var headers: Record<string, string> = {
    Authorization: localStorage.getItem(siteConfig.ACCESS_TOKEN) || ("" as string)
  };

  const sse = new SSE(siteConfig.BASE_URL + url, {
    headers,
    payload: isJson ? JSON.stringify(payload) : payload
  });

  return sse;
};

export const CoreClaimPdfDocEditor: FC<IProps> = ({ coreClaims, assetGuideId }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { selectedCategories, assetGuideData } = useSelector((state: any) => state?.iDetailExtractReducer);

  const [maxView, setMaxView] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [proceedLoading, setProceedLoading] = useState<boolean>(false);
  const [coreClaimData, setCoreClaimData] = useState<CoreClaimsTypes[]>([]);
  const [assetTypeList, setAssetTypeList] = useState<AssetTypeListTypes[]>([]);
  const [categoriesLocal, setCategoriesLocal] = useState<ListKeyDataTypes[]>([]);
  const [currentTab, setCurrentTab] = useState<string>(enumCurrentTab.ASSET_TYPE);
  const [openDimensionModal, setOpenDimensionModal] = useState(false);
  const [selectedDimension, setSelectedDimension] = useState(undefined);

  useEffect(() => {
    getAssetTypeList();
  }, []);

  useEffect(() => {
    setCategoriesLocal(selectedCategories);
  }, [selectedCategories]);

  useEffect(() => {
    if (coreClaims && coreClaims?.length) setCoreClaimData(createPageOccurence(coreClaims));
    else setCoreClaimData([]);
  }, [coreClaims]);

  const getTranslatedText = (value: string) => {
    //@ts-ignore
    return `${t(I18N_MODULE_KEYS[`idetailextract._CoreClaimMain._CoreClaimPdfDocEditor.${value as string}`])}`;
  };

  const getAssetTypeList = async () => {
    const res: apiResponse = await _iDetailExtractRequests.getAssetTypes();

    if (res?.data?.length) {
      res.data[0]["is_selected"] = true;
      setAssetTypeList(res?.data);
    }
  };

  const handleCurrentStep = () => {
    if (currentStep === 1) setCurrentStep(2);
    else setCurrentStep(1);
  };

  const createPageOccurence = (coreClaimData: CoreClaimsTypes[]) => {
    const arrCoreClaimData: CoreClaimsTypes[] = [...coreClaimData];

    let arrNew: any[] = [];

    arrCoreClaimData?.forEach((coreClaimItem: CoreClaimsTypes) => {
      let objOccurrence: CoreClaimPageOccurence = {};

      arrNew.push(coreClaimItem.content);

      coreClaimItem?.content?.forEach((contentItem: CoreClaimContentTypes) => {
        if (objOccurrence?.hasOwnProperty(contentItem?.page_number)) {
          objOccurrence[contentItem?.page_number] = objOccurrence[contentItem?.page_number] + 1;
        } else {
          objOccurrence[contentItem?.page_number] = 1;
        }
      });

      coreClaimItem["occurence"] = objOccurrence;
    });

    return [...coreClaimData];
  };

  const handleAddContent = (index: number) => {
    const arrCoreClaimData: CoreClaimsTypes[] = [...coreClaimData];

    arrCoreClaimData[index]["content"]?.push({
      content: "",
      page_number: arrCoreClaimData[index]["content"][0]?.page_number,
      id: null,
      is_active: true
    });

    setCoreClaimData([...arrCoreClaimData]);
  };

  const handleCheckbox = (e: any, index: number, isLabel: boolean, item: ListKeyDataTypes) => {
    const arrCurrentlist: any[] = getArrayWRTTab();

    //Reset arrCurrentList and mark only the current index. Mimic Radio Box.
    if (currentTab === enumCurrentTab.ASSET_TYPE) {
      arrCurrentlist.forEach((item) => (item["is_selected"] = false));
    }

    if (isLabel) {
      arrCurrentlist[index]["is_selected"] = !arrCurrentlist[index]["is_selected"];
    } else {
      arrCurrentlist[index]["is_selected"] = e.target.checked;
    }

    if (currentTab === enumCurrentTab.ASSET_TYPE) setAssetTypeList([...arrCurrentlist]);
    else setCategoriesLocal([...arrCurrentlist]);
  };

  const getArrayWRTTab = () => {
    if (currentTab === enumCurrentTab.ASSET_TYPE) return assetTypeList;
    else return categoriesLocal;
  };

  const isBannerSelected = () => {
    return assetTypeList.find((asset) => asset.is_selected && asset.name.toLowerCase() === "ESMO Banner".toLowerCase());
  };

  const handleProceedClick = () => {
    if (isBannerSelected()) {
      setOpenDimensionModal(true);
    } else handleProceed();
  };

  const handleProceed = async () => {
    setProceedLoading(true);

    const sse = getCustomSSE(siteConfig.IDX_CREATE_MAIN_ASSET, true, {
      assetguide_id: assetGuideData?.assetguide_id,
      keymessages: categoriesLocal?.filter((i) => i.is_selected === true)?.map((i) => i.id),
      asset_types: assetTypeList?.filter((i) => i.is_selected === true)?.map((i) => i.id),
      dimension_id: isBannerSelected() ? selectedDimension : undefined,
      user_id: "11"
    });

    sse.addEventListener("end", (res: apiResponse) => {
      sse.close();

      if (res?.data) {
        getAssetMainData(JSON.parse(res?.data)?.id);
      }
    });

    sse.onerror = () => {
      sse.close();
      setProceedLoading(false);
    };
  };

  const getAssetMainData = async (assetId: number) => {
    const res: apiResponse = await _iDetailExtractRequests.getAssetMainData(assetId);
    setProceedLoading(false);

    if (res?.data?.id) {
      dispatch(setAssetMainDataIDXAction(res?.data));
      dispatch(setOpenHtmlOutputModalIDXAction(true));
    }
  };

  const handleOnChangeSelect = (arrValues: string[]) => {
    if (coreClaims) {
      let arrCoreClaimData: CoreClaimsTypes[] = [...coreClaims];

      if (arrValues?.length) {
        arrCoreClaimData = [...arrCoreClaimData?.filter((item: CoreClaimsTypes) => arrValues.includes(item.key_message))];
      }

      setCoreClaimData([...arrCoreClaimData]);
    }
  };

  return (
    <Col xs={{ span: 24 }} md={{ span: maxView ? 24 : 12 }} className="coreclaimdoceditor responsive-div">
      <Row gutter={[0, 24]} style={{ background: "rgb(224 224 224 / 35%)", padding: "10px" }}>
        <Col xs={{ span: 24 }}>
          <Flex justify="space-between" align="center">
            <Text style={{ fontSize: "16px", fontWeight: "700", color: "rgba(3, 78, 162, 1)" }}>{getTranslatedText("PDF > Document Copy")}</Text>
            <Image
              src={maxView ? PROJECT_ICONS.C_AI_MINIMIZE : PROJECT_ICONS.C_AI_MAXIMIZE}
              preview={false}
              style={{ height: "15px", cursor: "pointer" }}
              onClick={() => setMaxView(!maxView)}
            />
          </Flex>
        </Col>
        <Col xs={{ span: 24 }}>
          <Card bodyStyle={{ padding: "14px", overflowY: "auto", height: "78vh" }}>
            <Flex vertical gap="middle">
              <Card style={{ background: "rgba(249, 249, 249, 1)" }} bodyStyle={{ padding: "10px" }}>
                <Flex vertical gap="small">
                  <Flex align="center" gap="small">
                    <Text style={{ fontSize: "14px", fontWeight: "400", color: "rgba(26, 26, 26, 1)" }}>
                      {getTranslatedText("Selected Categories")} ({selectedCategories?.length})
                    </Text>
                    <Text style={{ fontSize: "14px", fontWeight: "400", color: "rgba(26, 26, 26, 1)" }}>|</Text>
                    <Text
                      style={{ fontSize: "14px", fontWeight: "400", color: "rgba(3, 78, 162, 1)", cursor: "pointer" }}
                      onClick={() => dispatch(setOpenCategoryModalStateIDXAction(true))}
                    >
                      {getTranslatedText("ADD CATEGORIES")}
                    </Text>
                  </Flex>

                  <Select
                    placeholder="Select Category"
                    mode="multiple"
                    options={[
                      ...selectedCategories?.map((item: ListKeyDataTypes) => {
                        return { value: item.name };
                      })
                    ]}
                    style={{ width: "225px" }}
                    onChange={handleOnChangeSelect}
                    maxTagPlaceholder={(omittedValues) => (
                      <Tooltip title={omittedValues.map(({ label }) => label).join(", ")}>
                        <Text>{`+ ${omittedValues?.length}...`}</Text>
                      </Tooltip>
                    )}
                    maxTagCount="responsive"
                  />

                  <Flex>
                    <Text style={{ fontSize: "11px", fontWeight: "400", color: "rgba(203, 49, 0, 1)" }}>
                      {getTranslatedText("This is a preview version and you can edit and create a copy out of it.")}
                    </Text>
                  </Flex>
                </Flex>
              </Card>

              <Flex justify="space-between">
                <Flex vertical>
                  <Text style={{ fontSize: "14px", fontWeight: "400", color: "rgba(26, 26, 26, 1)" }}>
                    {getTranslatedText("Step")} {currentStep}
                  </Text>
                  <Text style={{ fontSize: "16px", fontWeight: "700", color: "rgba(26, 26, 26, 1)" }}>
                    {currentStep === 1 ? getTranslatedText("Core Claims") : getTranslatedText("Asset Creation Recommendation ")}
                  </Text>
                </Flex>

                <Space>
                  <Button
                    key="submit-step"
                    type="primary"
                    ghost
                    style={{ color: "rgba(3, 78, 162, 1)", borderRadius: "0px" }}
                    onClick={handleCurrentStep}
                  >
                    {currentStep === 1 ? getTranslatedText("Next Step") : getTranslatedText("Previous Step")}
                  </Button>
                </Space>
              </Flex>

              {currentStep === 1 && (
                <>
                  {coreClaimData && coreClaimData?.length ? (
                    <>
                      {coreClaimData?.map((item: CoreClaimsTypes, index: number) => {
                        return (
                          <Flex vertical key={`CoreClaim_${index}`}>
                            <Text style={{ fontSize: "15px", fontWeight: "700", color: "rgba(95, 95, 95, 1)" }}>
                              {item?.key_message} | Page No:{" "}
                              {Object.keys(item?.occurence)?.map((pageNumberItem: string, pageNumberIndex: number) => {
                                return (
                                  <Text
                                    key={`pageNumber_${pageNumberIndex}`}
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "500",
                                      color: "rgba(3, 78, 162, 1)",
                                      textDecoration: "underline",
                                      cursor: "pointer"
                                      // margin: "0px 4px"
                                    }}
                                    onClick={() => dispatch(setClickedPageNumberIDXAction(parseInt(pageNumberItem)))}
                                  >
                                    {pageNumberIndex + 1 < Object.keys(item?.occurence)?.length ? `${pageNumberItem},` : `${pageNumberItem}`}
                                  </Text>
                                );
                              })}{" "}
                              <Text
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "400"
                                }}
                              >
                                {getTranslatedText("of CVA")}
                              </Text>
                            </Text>

                            <Flex style={{ marginLeft: "15px", padding: "10px" }}>
                              <ul style={{ width: "100%" }}>
                                <Flex vertical gap="small">
                                  {item?.content?.map((contentItem: CoreClaimContentTypes, contentIndex: number) => {
                                    if (contentItem.is_active === true)
                                      return (
                                        <ContentListItem
                                          key={`CoreClaimContent_${contentItem.id}`}
                                          contentItem={contentItem}
                                          coreClaimIndex={index}
                                          coreClaimData={coreClaimData}
                                        />
                                      );
                                  })}
                                </Flex>
                              </ul>
                            </Flex>

                            <Button type="link" onClick={() => handleAddContent(index)}>
                              <PlusOutlined />
                              {getTranslatedText("Add")}
                            </Button>
                          </Flex>
                        );
                      })}
                    </>
                  ) : (
                    <Text style={{ fontSize: "13px", fontWeight: "400", color: "grey" }}>{getTranslatedText("No Data Found")}!</Text>
                  )}
                </>
              )}

              {currentStep === 2 && (
                <Flex vertical gap="middle">
                  <Flex gap="middle">
                    <Text
                      style={{
                        color: "rgba(3, 78, 162, 1)",
                        fontSize: "13px",
                        fontWeight: currentTab === enumCurrentTab.ASSET_TYPE ? "700" : "400",
                        cursor: "pointer"
                      }}
                      onClick={() => setCurrentTab(enumCurrentTab.ASSET_TYPE)}
                    >
                      {getTranslatedText("CHOOSE ASSET TYPE")} ({assetTypeList?.length})
                    </Text>
                    <Text
                      style={{
                        fontSize: "13px",
                        fontWeight: currentTab === enumCurrentTab.CLAIM_TYPE ? "700" : "400",
                        cursor: "pointer",
                        color: "rgba(3, 78, 162, 1)"
                      }}
                      onClick={() => setCurrentTab(enumCurrentTab.CLAIM_TYPE)}
                    >
                      {getTranslatedText("CHOOSE CLAIM CATEGORY")} ({selectedCategories?.length})
                    </Text>
                  </Flex>
                  <Flex vertical justify="space-between">
                    {getArrayWRTTab()?.length ? (
                      <ul
                        style={{
                          listStyleType: "none",
                          display: "flex",
                          flexDirection: "column",
                          flexWrap: "wrap",
                          height: "200px",
                          overflowX: "scroll",
                          gap: "12px 30px"
                        }}
                      >
                        {getArrayWRTTab()?.map((item: ListKeyDataTypes, index: number) => {
                          return (
                            <li key={`assettypelist_${index}`}>
                              <Flex gap={"small"} align="center">
                                <Checkbox
                                  checked={item?.is_selected}
                                  disabled={!item?.is_active}
                                  onChange={(e) => handleCheckbox(e, index, false, item)}
                                />
                                <Text
                                  style={{ fontSize: "12px", fontWeight: "400", cursor: item.is_active ? "pointer" : "not-allowed" }}
                                  onClick={(e) => {
                                    item.is_active ? handleCheckbox(e, index, true, item) : <></>;
                                  }}
                                >
                                  {item?.name}
                                </Text>
                              </Flex>
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      <Flex justify="center" align="center" style={{ height: "40vh" }}>
                        <Flex justify="center" align="center">
                          <Text style={{ fontSize: "13px", fontWeight: "400", color: "grey" }}>{getTranslatedText("No Data Found")}!</Text>
                        </Flex>
                      </Flex>
                    )}

                    <Flex justify="space-between" align="center">
                      <div>
                        <Image src={PROJECT_ICONS.IDX_BULB_ICON} style={{ height: "15px", marginRight: "10px" }} preview={false} />
                        <Text style={{ fontSize: "12px", fontWeight: "400", cursor: "pointer" }}>
                          {getTranslatedText("Choose ‘Asset & Claim category to proceed")}
                        </Text>
                      </div>

                      <Button
                        key="submit-proceed"
                        type="primary"
                        style={{
                          background: getArrayWRTTab()?.filter((item) => item?.is_selected === true)?.length
                            ? "rgba(3, 78, 162, 1)"
                            : "rgb(196 198 202)"
                        }}
                        onClick={handleProceedClick}
                        disabled={getArrayWRTTab()?.filter((item) => item?.is_selected === true)?.length ? false : true}
                        loading={proceedLoading}
                      >
                        {t(I18N_MODULE_KEYS["content.button.proceed"])}
                      </Button>
                    </Flex>
                  </Flex>
                  <Modal
                    open={openDimensionModal}
                    title="Select Banner Dimension"
                    centered
                    okText="Create Banner"
                    onOk={handleProceed}
                    onCancel={() => setOpenDimensionModal(false)}
                  >
                    <Radio.Group
                      onChange={(e: RadioChangeEvent) => {
                        setSelectedDimension(e.target.value);
                      }}
                      value={selectedDimension}
                    >
                      <Radio value={"160x600"}>160x600</Radio>
                      <Radio value={"300x250"}>300x250</Radio>
                      <Radio value={"300x600"} disabled>
                        300x600
                      </Radio>
                      <Radio value={"728x90"} disabled>
                        728x90
                      </Radio>
                    </Radio.Group>
                  </Modal>
                </Flex>
              )}
            </Flex>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

{
  /* <Row gutter={[24, 24]} style={{ marginBottom: "20px" }}>
                    {getArrayWRTTab()?.length ? (
                      <>
                        {getArrayWRTTab()?.map((item: any, index: number) => {
                          return (
                            <Col xs={{ span: 24 }} md={{ span: 8 }} key={`assettypelist_${index}`}>
                              <Flex gap={"small"} align="center">
                                <Checkbox checked={item?.is_selected} disabled={!item?.is_active} onChange={(e) => handleCheckbox(e, index)} />
                                <Text style={{ fontSize: "12px", fontWeight: "400", cursor: "pointer" }}>{item?.name}</Text>
                              </Flex>
                            </Col>
                          );
                        })}
                      </>
                    ) : (
                      <Col xs={{ span: 24 }}>
                        <Flex justify="center" align="center">
                          <Text style={{ fontSize: "13px", fontWeight: "400", color: "grey" }}>No Data Found!</Text>
                        </Flex>
                      </Col>
                    )}
                  </Row> */
}
