import { useState } from "react";

type CardItem<T> = T & { id: string | number };

export const useSelectablePreviewableCards = <T extends object>() => {
  const [checkedItems, setCheckedItems] = useState<CardItem<T>[]>([]);
  const [previewedItem, setPreviewedItem] = useState<CardItem<T> | null>(null);

  const toggleCheckedItem = (item: CardItem<T>) => {
    setCheckedItems((prevItems) =>
      prevItems.some((checked) => checked.id === item.id) ? prevItems.filter((checked) => checked.id !== item.id) : [...prevItems, item]
    );
  };

  const selectPreviewItem = (item: CardItem<T>) => {
    setPreviewedItem(item);
  };

  return {
    checkedItems,
    previewedItem,
    toggleCheckedItem,
    selectPreviewItem
  };
};
