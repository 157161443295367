import { FC } from "react";
import { Col } from "antd";
import TextArea from "antd/es/input/TextArea";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";

interface FileSummariserBarProps {
  fileSummariserQuery: string;
  setFileSummariserQuery: (query: string) => void;
  isLoadingSummary: boolean;
  setIsLoadingForSummary: (loading: boolean) => void;
}

export const FileSummariserSearchBar: FC<FileSummariserBarProps> = ({
  fileSummariserQuery,
  setFileSummariserQuery,
  isLoadingSummary,
  setIsLoadingForSummary,
}) => {
  return (
    <>
      <Col span={20} style={{ paddingLeft: "60px" }}>
        <div style={{ color: "black", borderRadius: "40px", boxShadow: "0px 0px 14px grey" }}>
          <div style={{ background: "white", borderRadius: "50px", padding: '10px' }}>
            {/* Text Icon */}
            <img
              src={PROJECT_ICONS.TEXTREFERENCE}
              style={{ marginRight: '16px', height: '14px', color: '#ccc', marginLeft: '10px', marginTop: '0px', marginBottom: '2px' }}
            />
            {/* Input Field */}
            <TextArea
              placeholder="Describe what you want to summarise from the document"
              style={{
                border: 'none',
                outline: 'none',
                flex: 1,
                fontSize: '14px',
                width: '85%',
                resize: 'none',
              }}
              value={fileSummariserQuery}
              draggable={false}
              autoSize={false}
              rows={1}
              onChange={(e) => setFileSummariserQuery(e.target.value)} // Update query on change
            />
            {/* Submit Button */}
            <button
              style={{
                backgroundColor: '#f1f1f1',
                borderRadius: '20px',
                padding: '5px 15px',
                cursor: 'pointer',
                fontSize: '14px',
                color: '#66676B',
                border: 'none',
                fontWeight: 'bold',
              }}
              onClick={() => {
                setIsLoadingForSummary(true); // Set loading state if needed
                // Add your submission logic here
              }}
            >
              SUMMARIES
            </button>
          </div>
        </div>
      </Col>
    </>
  );
};
