import { useEffect, useState } from "react";
import { RootState } from "../../../../Store";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { sseGetTemplates, sseListModules } from "../../../../Store/requests/_contentNewtonRequests";
import { Button, notification, Skeleton } from "antd";
import { SSEMessage, SSEMessageList } from "../../components/common/DisplaySSEMessages";
import { setCNCurrentIndexDoneNextIndexProcess, setCNFinalAssetTemplatesList } from "../../../../Store/actions/_contentNewtonActions";
import { ContentNewtonState } from "../../../../Store/reducers/_contentNewtonReducer";
import { TemplateSelectionSSEResponse } from "../Module Selection/types";
import { TemplatesListing } from "./TemplatesListing/TemplatesListing";
import { ConversationStepsMapping, ConversationStepsName } from "../../resources/constants";
// import { Message, SSEMessageList } from "./DisplayAdminMessages";

export const TemplateSelection = () => {
  const { currentStep, orderId, finalAssetTemplatesList, selectedTemplateId }: ContentNewtonState = useAppSelector(
    (state) => state.contentNewtonReducer
  );

  const [sseMessages, setSSEMessages] = useState<SSEMessage[]>([]);
  const [templatesList, setTemplatesList] = useState<any[]>([]);
  const [loading, setLoading] = useState(!finalAssetTemplatesList.length);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    finalAssetTemplatesList && setTemplatesList(finalAssetTemplatesList);
  }, [finalAssetTemplatesList]);

  useEffect(() => {
    if (finalAssetTemplatesList.length > 0) {
      return;
    }

    const sse = sseGetTemplates(orderId);

    sse.addEventListener("pending", async (data: { data: string }) => {
      console.log(`pending`, data?.data);
      setLoading(false);

      let parsedData: TemplateSelectionSSEResponse;
      try {
        parsedData = JSON.parse(data.data);
        // parsedData = sseMessages[messageNumber];
        // setMessageNumber(messageNumber + 1);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        sse.close();
        return;
      }

      if (parsedData) {
        if (parsedData.message) {
          setSSEMessages((prev) => [...prev, parsedData.message]);
        }
      }
    });

    sse.addEventListener("end", async (data: { data: string }) => {
      console.log(`sse.addEventListener("end"`, data?.data);
      sse.close();
      setLoading(false);

      let parsedData: TemplateSelectionSSEResponse;
      try {
        parsedData = JSON.parse(data.data);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        sse.close();
        return;
      }

      if (parsedData) {
        if (parsedData.error) {
          setErrorMessage(parsedData.message.text);
        } else if (parsedData.data) {
          setSSEMessages((prev) => [...prev, { type: "main" }]); //Blank message to finish the last step
          //setTemplatesList(parsedData.data);
          dispatch(setCNFinalAssetTemplatesList(parsedData.data));
        }
      }
    });

    sse.onerror = (err) => {
      notification.error({ message: `SSE Templates Listing Error!\n ${err}` });
      sse.close();
      setLoading(false);
    };
  }, [finalAssetTemplatesList]);

  const isTemplateSelectionStep = ConversationStepsMapping[currentStep] === ConversationStepsName.TEMPLATES_LISTING;

  return (
    <div>
      {loading ? (
        <Skeleton active />
      ) : errorMessage ? (
        <div>{errorMessage}</div>
      ) : (
        <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "5px" }}>
          <SSEMessageList sseMessages={sseMessages} />
          <TemplatesListing templateList={templatesList} />
          <div
            style={{
              display: isTemplateSelectionStep && templatesList?.length && selectedTemplateId ? "flex" : "none",
              // justifyContent: "flex-end"
              marginTop: "5px"
            }}
          >
            <Button type="primary" onClick={() => dispatch(setCNCurrentIndexDoneNextIndexProcess())}>
              Proceed
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
