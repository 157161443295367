import React, { useEffect } from "react";
import {
  ConversationStatus,
  ConversationStepsMapping,
  ConversationStepsMessages,
  ConversationStepsName,
  FooterButtonsText,
  LastConversationStep,
  UserType
} from "../resources/constants";
import AdminMessage from "./Conversation/AdminMessage/AdminMessage";
import UserMessage from "./Conversation/UserMessage/UserMessage";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import {
  setCNCurrentIndexDone,
  setCNCurrentIndexDoneNextIndexProcess,
  setCNCurrentStepDone,
  setCNCurrentStepDoneNextStepProcess,
  setCNCurrentStepperStepFinish,
  setCNCurrentStepTimestamp
} from "../../../Store/actions/_contentNewtonActions";
import { ConversationStep } from "../resources/types";
import { ChatBotGIF } from "./Conversation/ChatBotGIF/ChatBotGIF";
import { DetailsInputPrompt } from "../steps/Input Prompt/DetailsInputPrompt";
import { ModuleSelection } from "../steps/Module Selection/ModuleSelection";
import { SystemSuggestedModules } from "../steps/Module Selection/SystemSuggestedModules/SystemSuggestedModules";
import { TemplateSelection } from "../steps/Template Selection/TemplateSelection";
import { FinalAsset } from "../steps/Asset Creation/FinalAsset";
import { ContentNewtonState } from "../../../Store/reducers/_contentNewtonReducer";
import { MarketAndLanguageInputPrompt } from "../steps/Localisation/MarketAndLanguageInputPrompt";
import { AssetLocalisation } from "../steps/Localisation/AssetLocalisation";
import ErrorBoundary from "../../../Components/Error/ErrorBoundary";
import { TranslationLanguageInputPrompt } from "../steps/Translation/TranslationLanguageInputPrompt";
import { AssetTranslation } from "../steps/Translation/AssetTranslation";

export const CRConversationArea = () => {
  const { currentIndex, currentStep, conversationSteps }: ContentNewtonState = useAppSelector((state) => state.contentNewtonReducer);

  const dispatch = useAppDispatch();

  console.log("{ currentIndex, currentStep }", { currentIndex, currentStep });

  useEffect(() => {
    console.log("useEffect currentstep", currentStep);
    const currentStepData = conversationSteps.find((cs, i) => i === currentIndex);

    //If Static User message then proceed automatically
    if (currentStepData?.type === UserType.USER && ConversationStepsMessages[ConversationStepsMapping[currentStep]]) {
      setTimeout(() => {
        dispatch(setCNCurrentIndexDoneNextIndexProcess());
      }, 800);
    }

    const autoSkipAdminSteps = [ConversationStepsName.LOCALISATION_MARKET_AND_LANGUAGE, ConversationStepsName.TRANSLATION_LANGUAGE];
    if (autoSkipAdminSteps.includes(ConversationStepsMapping[currentStep])) {
      setTimeout(() => {
        dispatch(setCNCurrentIndexDoneNextIndexProcess());
      }, 800);
    }
  }, [currentStep]);

  useEffect(() => {
    //First load of the component sets this timestamp
    if (currentStep === 1) {
      dispatch(setCNCurrentStepTimestamp());
      // dispatch(setCNCurrentStepDoneNextStepProcess()); //Since first is just a message for the user
      dispatch(setCNCurrentIndexDoneNextIndexProcess()); //Since first is just a message for the user
    }
    // if (currentStep === LastConversationStep) {
    //   dispatch(setCNCurrentStepDone());
    //   dispatch(setCNCurrentStepperStepFinish());
    //   console.log("Last step reached");
    // }
    if (currentIndex === conversationSteps.length - 1) {
      // dispatch(setCNCurrentIndexDone());
      dispatch(setCNCurrentStepperStepFinish());
      console.log("Last step reached");
    }
  }, [currentStep]);

  const ConversationStepComponents: Record<string, (props: any) => JSX.Element> = {
    [ConversationStepsName.USER_PROMPT_INPUT]: DetailsInputPrompt,
    [ConversationStepsName.MODULES_LISTING]: ModuleSelection,
    [ConversationStepsName.MODULES_SYSTEM_SUGGESTED]: SystemSuggestedModules,
    [ConversationStepsName.TEMPLATES_LISTING]: TemplateSelection,
    [ConversationStepsName.CREATING_FINAL_ASSET]: FinalAsset,
    [ConversationStepsName.USER_PROMPT_MARKET_AND_LANGUAGE]: MarketAndLanguageInputPrompt,
    [ConversationStepsName.LOCALISATION_WITHOUT_TRANSLATION]: AssetLocalisation,
    [ConversationStepsName.USER_PROMPT_TRANSLATION_LANGUAGE]: TranslationLanguageInputPrompt,
    [ConversationStepsName.LOCALISATION_WITH_TRANSLATION]: AssetTranslation
  };

  const footerButtonsOnClick = {
    [FooterButtonsText.NEXT]: () => {
      console.log("Next");
      dispatch(setCNCurrentIndexDoneNextIndexProcess());
    },
    [FooterButtonsText.UPLOAD_INSTEAD]: () => console.log("Upload Instead"),
    [FooterButtonsText.PROCEED]: () => {
      console.log("Proceed");
      dispatch(setCNCurrentIndexDoneNextIndexProcess());
    },
    [FooterButtonsText.SKIP]: () => {
      console.log("Skip");
    },
    [FooterButtonsText.CANCEL]: () => console.log("Cancel"),
    [FooterButtonsText.DEFINE_FOR_ENGLISH]: () => {
      console.log("Define for English");
      dispatch(setCNCurrentIndexDoneNextIndexProcess());
    },
    [FooterButtonsText.GENERATE]: () => {
      console.log("Generate");
      dispatch(setCNCurrentIndexDoneNextIndexProcess());
    },
    [FooterButtonsText.PUBLISH]: () => {
      console.log("Publish");
      dispatch(setCNCurrentIndexDoneNextIndexProcess());
    },
    [FooterButtonsText.SUBMIT_SELECTED_ASSETS]: () => {
      console.log("Submit Selected Assets");
      dispatch(setCNCurrentIndexDoneNextIndexProcess());
    }
  };

  return (
    <div style={{ flex: 1, overflow: "auto" }}>
      <div style={{ paddingTop: "20px" }}>
        <ChatBotGIF />
      </div>
      {conversationSteps.map((chatData, index) => {
        if (chatData.status === ConversationStatus.WAIT) return null;

        const Component = ConversationStepComponents[ConversationStepsMapping[chatData.step]];
        if (chatData.type === "admin") {
          return (
            <AdminMessage
              key={index}
              chatData={chatData}
              footerButtonsOnClick={footerButtonsOnClick}
              // children={ConversationStepComponents[ConversationStepsMapping[chatData.step]]}
            >
              <ErrorBoundary>{Component && <Component index={index} />}</ErrorBoundary>
            </AdminMessage>
          );
        } else {
          return (
            <UserMessage
              key={index}
              chatData={chatData}
              // children={ConversationStepComponents[ConversationStepsMapping[chatData.step]]?.({ index })}
            >
              {Component && <Component index={index} />}
            </UserMessage>
          );
        }
      })}
    </div>
  );
};
