import { useEffect, useState } from "react";
import { ContentNewton } from "./ContentNewton";
// import ReplicatorAIWelcome from "../../ss-dev/slide2/ReplicatorAIWelcome";
// import Index from "../../ss-dev/slide2/Page";
import { useAppDispatch } from "../../Store/hooks";
import { resetCNStates } from "../../Store/actions/_contentNewtonActions";
import { PROJECT_ICONS } from "../../Assets/Img/_DIcons";
// import { resetCNStates } from "../../Store/actions/_contentNewtonActions";
import "./resources/ContentNewton.css";
import { siteConfig } from "../../Services";

export const ContentNewtonModule = () => {
  const [hasStarted, setHasStarted] = useState(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    siteConfig.BASE_URL = process.env.REACT_APP_API_URL_FOR_CONTENT_NEWTON;
  }, []);

  const handleStart = () => {
    dispatch(resetCNStates());
    setHasStarted(true);
  };

  if (hasStarted) return <ContentNewton />;
  else
    return (
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
        <div
          style={{
            fontSize: "30px",
            fontWeight: 700,
            display: "flex",
            alignItems: "center",
            gap: "5px",
            justifyContent: "center",
            paddingTop: "20px"
          }}
        >
          <img src={PROJECT_ICONS.CN_CONTENT_NEWTON_MODULE_ICON} height={30} width={30} alt="Content Newton Module Icon" />
          Content Newton
        </div>
        {/* <Index onStart={handleStart} /> */}
      </div>
    );
};
