import React from "react";
import AppHeader from "./Headers/CNHearder";
import Sidebar from "./Sidebars/CNSidebar/Sidebar";
import Layout from "antd/es/layout/layout";

const ContentNewtonLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <AppHeader />
      <Layout>
        <Sidebar />
        <div style={{ height: "100%", width: "100%", overflow: "auto" }}>{children}</div>
      </Layout>
    </div>
  );
};

export default ContentNewtonLayout;
