export const SET_SEARCH_QUERY = "semanticSearch/SET_SEARCH_QUERY";
export const SET_ENCODED_SEARCH_QUERY = "semanticSearch/SET_ENCODED_SEARCH_QUERY";
export const SET_SEARCH_BAR_STATUS = "semanticSearch/SET_SEARCH_BAR_STATUS";
export const SET_CARDS_DATA = "semanticSearch/SET_CARDS_DATA";
export const SET_TAB_LIST = "semanticSearch/SET_TAB_LIST";
export const SET_ADVANCED_FILTER = "semanticSearch/SET_ADVANCED_FILTER";

export const SET_SELECTED_REPORTS = "semanticSearch/SET_SELECTED_REPORTS";
export const SET_REFETCH_LIST = "semanticSearch/SET_REFETCH_LIST";
export const SET_ADVANCEDFILTER_LIST = "semanticSearch/SET_ADVANCEDFILTER_LIST";
export const SET_BUTTON_TEXT = "semanticSearch/SET_BUTTON_TEXT";
export const SET_ADVANCEDFILTER_STATUS = "semanticSearch/SET_ADVANCEDFILTER_STATUS";
export const SET_INITIAL_FOLDERS = "semanticSearch/SET_INITIAL_FOLDERS";
export const SET_SEMANTIC_SEARCH_ID = "semanticSearch/SET_SEMANTIC_SEARCH_ID";
export const SET_ENCODED_SEMANTIC_SEARCH_ID = "semanticSearch/SET_ENCODED_SEMANTIC_SEARCH_ID";
export const SET_CARD_DATA = "semanticSearch/SET_CARD_DATA";
export const SET_K_VALUE = "semanticSearch/SET_K_VALUE";
export const SET_FORMAT = "semanticSearch/SET_FORMAT";
export const SET_STATISTICS = "semanticSearch/SET_STATISTICS";
export const SET_ACTIVE_MODULE = "semanticSearch/SET_ACTIVE_MODULE";
export const SET_REGENERATE_STATUS = "semanticSearch/SET_REGENERATE_STATUS";

export const SET_FILE_LENGTH = "semanticSearch/SET_FILE_LENGTH";
export const SET_CREATE_NEW_FOLDER = "semanticSearch/SET_CREATE_NEW_FOLDER";
export const SET_UPLOAD_FILE_STATUS = "semanticSearch/SET_UPLOAD_FILE_STATUS";

export const SET_IMAGE_CARDS_DATA = "semanticSearch/SET_IMAGE_CARDS_DATA";
export const SET_AUDIO_CARDS_DATA = "semanticSearch/SET_AUDIO_CARDS_DATA";
export const SET_VIDEO_CARDS_DATA = "semanticSearch/SET_VIDEO_CARDS_DATA";

export const SET_CARD_TYPE = "semanticSearch/SET_CARD_TYPE";
export const SET_FILE_CARD_TYPE = "semanticSearch/SET_FILE_CARD_TYPE";
export const SET_SEMANTIC_SEARCH_IMAGE_ID = "semanticSearch/SET_SEMANTIC_SEARCH_IMAGE_ID";

//semantic Conversational UI

export const SET_CARD_DATA_COLLECTION = "semanticSearch/SET_CARDS_DATA_COLLECTION";
export const SET_LANDING_SCREEN = "semanticSearch/SET_LANDING_SCREEN";
export const SET_CONVERSATION_ID = "semanticSearch/SET_CONVERSATION_ID";
export const SET_PREVIOUS_ID = "semanticSearch/SET_PREVIOUS_ID";
export const SET_NEXT_ID = "semanticSearch/SET_NEXT_ID";
export const SET_PRO_MODE_STATUS = "semanticSearch/SET_PRO_MODE_STATUS";
export const SET_PRO_MODE_SCREEN = "semanticSearch/SET_PRO_MODE_SCREEN";
export const SET_MESSAGE_ID = "semanticSearch/SET_MESSAGE_ID";
export const SET_ENCODED_SEMANTIC_SEARCH_ID_KVALUE = "semanticSearch/SET_ENCODED_SEMANTIC_SEARCH_ID_KVALuE";
export const SET_LOADING_FOR_REPORT_GENERATION = "semanticSearch/SET_LOADING_FOR_REPORT_GENERATION";
export const SET_EDITABLE_CONTENT = "semanticSearch/SET_EDITABLE_CONTENT";
export const SET_TYPE_FOR_REPORT_GENERATION = "semanticSearch/SET_TYPE_FOR_REPORT_GENERATION";
export const SET_REPORT_SLIDES = "semanticSearch/SET_REPORT_SLIDES";
export const SET_PDF_REPORTS = "semanticSearch/SET_PDF_REPORTS";
export const SET_LOADING_FOR_SWITCHING = "semanticSearch/SET_LOADING_FOR_SWITCHING";
export const SET_SEARCH_TYPE = "semanticSearch/SET_SEARCH_TYPE";
export const SET_LLM_MODEL = "semanticSearch/SET_LLM_MODEL";

export const SET_SYNCED_NOTIFICATIONS = "semanticSearch/SET_SYNCED_NOTIFICATIONS";
export const SET_FILES_FOR_CHAT = "semanticSearch/SET_FILES_FOR_CHAT";
export const SET_BATCH_QA_STATUS = "semanticSearch/SET_BATCH_QA_STATUS";
export const SET_BATCH_QA_DATA = "semanticSearch/SET_BATCH_QA_DATA";
export const SET_BATCH_QA_FOLDERS = "semanticSearch/SET_BATCH_QA_FOLDERS";
export const SET_SUMMARISER = "semanticSearch/SET_SUMMARISER";
export const SET_GPT_RESEARCHER_STATUS = "semanticSearch/SET_GPT_RESEARCHER_STATUS";

export const SET_UPDATE_RESEARCH_AGENT = "semanticSearch/SET_UPDATE_RESEARCH_AGENT";
export const SET_INITIAL_RESEARCH_AGENT = "semanticSearch/ SET_INITIAL_RESEARCH_AGENT";
