export const SEMANTIC_SEARCH_MODULES = {
  SEMANTIC_SEARCH: "SEMANTIC_SEARCH",
  FILE_MANAGEMENT_SYSTEM: "FILE_MANAGEMENT_SYSTEM",
  USER_HISTORY: "USER_HISTORY",
  USAGE_BILLINGS_DETAILS: "USAGE_BILLINGS_DETAILS",
  COMMON_ARTEFACTS: "COMMON_ARTEFACTS",
  USE_CASE_SELECTION: "USE_CASE_SELECTION"
};

export const SEMANTIC_SEARCH_MODULE_NAMES = {
  [SEMANTIC_SEARCH_MODULES.SEMANTIC_SEARCH]: "Search", //"Content Creator",
  [SEMANTIC_SEARCH_MODULES.FILE_MANAGEMENT_SYSTEM]: "File Management",
  [SEMANTIC_SEARCH_MODULES.USER_HISTORY]: "User History",
  [SEMANTIC_SEARCH_MODULES.USAGE_BILLINGS_DETAILS]: "Usage and Billing Details",
  [SEMANTIC_SEARCH_MODULES.COMMON_ARTEFACTS]: "Common Artefacts",
  [SEMANTIC_SEARCH_MODULES.USE_CASE_SELECTION]: "Use Case Selection"
};
export const SEMANTIC_SEARCH_CARD = {
  DOCUMENTS: "Documents",
  VIDEOS: "Videos",
  IMAGES: "Images",
  AUDIOS: "Audios",
  WEBURLS: "Web URLs"
};
export const SEMANTIC_SEARCH_CARD_NAMES = {
  [SEMANTIC_SEARCH_CARD.DOCUMENTS]: "Documents",
  [SEMANTIC_SEARCH_CARD.VIDEOS]: "Videos",
  [SEMANTIC_SEARCH_CARD.AUDIOS]: "Audios",
  [SEMANTIC_SEARCH_CARD.IMAGES]: "Images",
  [SEMANTIC_SEARCH_CARD.WEBURLS]: "Web URLs"
};

export const MED_USER_EMAIL = "meduser@indegene.com";

export interface SSEData {
  id: any;
  created_at: string;
  question: string;
  answer: string;
  interested: any | null;
  page_number: number | null;
  k: number;
  score_threshold: number;
  status: string;
}
export interface ComponentChatWithDocProps {
  drawervisible: boolean;
  filenamefromnextinsight: string;
  setdrawervisible: React.Dispatch<React.SetStateAction<boolean>>;
  setfilename: React.Dispatch<React.SetStateAction<string>>;
}

export interface Folder {
  id: number;
  name: string;
  document: Array<{ filename: string; s3_url: string; created_at: string; user: string; document_type: string }>; // A record of file name to file URL
  image: Array<{ filename: string; s3_url: string; created_at: string; user: string; document_type: string }>; // A record of file name to file URL
  video: Array<{ filename: string; s3_url: string; created_at: string; user: string; document_type: string }>; // A record of file name to file URL
  audio: Array<{ filename: string; s3_url: string; created_at: string; user: string; document_type: string }>; // A record of file name to file URL
  folders: Array<{ name: string; s3_url: string; created_at: string; user: string; document_type: string }>; // A record of file name to file URL
}

export interface ComponentFileProps {
  folderData: any[];
  setFolderData: React.Dispatch<React.SetStateAction<any[]>>;
  foldersLength: Number;
  setFoldersLength: React.Dispatch<React.SetStateAction<Number>>;
}

export interface ComponentAdvancedFilterProps {
  internalVarB: string;
  internalVarA: number;
  stepperForinternalVarB: number;
  columns: string[];
}

export interface MessageType {
  text: string;
  image: string;
}

export interface ComponentNextInsightProps {
  columns: string[];
  setColumns: React.Dispatch<React.SetStateAction<string[]>>;
  internalVarA: number;
  setInternalVarA: React.Dispatch<React.SetStateAction<number>>;
  internalVarB: string;
  setInternalVarB: React.Dispatch<React.SetStateAction<string>>;
  setStepperForInternalVarb: React.Dispatch<React.SetStateAction<number>>;
  stepperForinternalVarB: number;
}
export interface ReferenceFilesDrawerProps {
  drawerVisibleforReferenceFiles: any;
  setDrawerVisibleforReferenceFiles: (visible: any) => void;
  openS3LinkInNewTab: (url: any) => void;
  showDrawer: (string: any) => void;
}

export type ResearchAgentType = {
  is_gpt_search: boolean; // for research agent
  is_internet_search: boolean; // for web search
  data: any[];
  isLoading: boolean;
};

export const initialResearchAgent: ResearchAgentType = {
  is_gpt_search: true,
  is_internet_search: true,
  data: [{output: '🪄 Working on AI magic '}],
  isLoading: false
};
