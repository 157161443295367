import { ResearchAgentType } from "../../Pages/Semantic Search/Components/Core/constants";
import {
  SET_CARDS_DATA,
  SET_ENCODED_SEARCH_QUERY,
  SET_SEARCH_BAR_STATUS,
  SET_SEARCH_QUERY,
  SET_TAB_LIST,
  SET_ADVANCED_FILTER,
  SET_SELECTED_REPORTS,
  SET_REFETCH_LIST,
  SET_ADVANCEDFILTER_STATUS,
  SET_ADVANCEDFILTER_LIST,
  SET_BUTTON_TEXT,
  SET_INITIAL_FOLDERS,
  SET_SEMANTIC_SEARCH_ID,
  SET_CARD_DATA,
  SET_K_VALUE,
  SET_FORMAT,
  SET_STATISTICS,
  SET_ENCODED_SEMANTIC_SEARCH_ID,
  SET_ACTIVE_MODULE,
  SET_CREATE_NEW_FOLDER,
  SET_FILE_LENGTH,
  SET_REGENERATE_STATUS,
  SET_UPLOAD_FILE_STATUS,
  SET_IMAGE_CARDS_DATA,
  SET_CARD_TYPE,
  SET_FILE_CARD_TYPE,
  SET_SEMANTIC_SEARCH_IMAGE_ID,
  SET_CARD_DATA_COLLECTION,
  SET_LANDING_SCREEN,
  SET_CONVERSATION_ID,
  SET_NEXT_ID,
  SET_PREVIOUS_ID,
  SET_PRO_MODE_SCREEN,
  SET_PRO_MODE_STATUS,
  SET_MESSAGE_ID,
  SET_ENCODED_SEMANTIC_SEARCH_ID_KVALUE,
  SET_LOADING_FOR_REPORT_GENERATION,
  SET_EDITABLE_CONTENT,
  SET_TYPE_FOR_REPORT_GENERATION,
  SET_REPORT_SLIDES,
  SET_PDF_REPORTS,
  SET_LOADING_FOR_SWITCHING,
  SET_SEARCH_TYPE,
  SET_LLM_MODEL,
  SET_AUDIO_CARDS_DATA,
  SET_VIDEO_CARDS_DATA,
  SET_SYNCED_NOTIFICATIONS,
  SET_BATCH_QA_STATUS,
  SET_BATCH_QA_DATA,
  SET_BATCH_QA_FOLDERS,
  SET_SUMMARISER,
  SET_FILES_FOR_CHAT,
  SET_GPT_RESEARCHER_STATUS,
  SET_UPDATE_RESEARCH_AGENT,
  SET_INITIAL_RESEARCH_AGENT
} from "../constants/_semanticSearchConstants";

export const setActiveModuleActions = (data: string) => {
  return { type: SET_ACTIVE_MODULE, payload: data };
};

export const setSearchQuery = (state: string) => {
  return { type: SET_SEARCH_QUERY, payload: state };
};

export const setEncodedSearchQueryAction = (state: string) => {
  return { type: SET_ENCODED_SEARCH_QUERY, payload: state };
};

export const setCardsDataAction = (state: any[]) => {
  return { type: SET_CARDS_DATA, payload: state };
};
export const setCardData = (state: any[]) => {
  return { type: SET_CARD_DATA, payload: state };
};

export const setTabListAction = (state: any[]) => {
  return { type: SET_TAB_LIST, payload: state };
};

export const setSearchBarStatus = (state: any) => {
  return { type: SET_SEARCH_BAR_STATUS, payload: state };
};

export const setAdvancedFilter = (state: any) => {
  return { type: SET_ADVANCED_FILTER, payload: state };
};

export const setSelectedReportAction = (state: number[]) => {
  return { type: SET_SELECTED_REPORTS, payload: state };
};

export const setRefetchListAction = (state: boolean) => {
  return { type: SET_REFETCH_LIST, payload: state };
};

export const setFilterListAction = (state: string[]) => {
  return { type: SET_ADVANCEDFILTER_LIST, payload: state };
};
export const setAdvancedFilterStatus = (state: any) => {
  return { type: SET_ADVANCEDFILTER_STATUS, payload: state };
};
export const setButtonText = (state: any) => {
  return { type: SET_BUTTON_TEXT, payload: state };
};
export const setInitialFolders = (state: any) => {
  return { type: SET_INITIAL_FOLDERS, payload: state };
};
export const setSemanticSearchId = (state: Number) => {
  return { type: SET_SEMANTIC_SEARCH_ID, payload: state };
};
export const setEncodedSemanticSearchId = (state: Number) => {
  return { type: SET_ENCODED_SEMANTIC_SEARCH_ID, payload: state };
};
export const setKvalue = (state: Number) => {
  return { type: SET_K_VALUE, payload: state };
};
export const setFormat = (state: any) => {
  return { type: SET_FORMAT, payload: state };
};
export const setStatistisc = (state: any[]) => {
  return { type: SET_STATISTICS, payload: state };
};
export const setFileLength = (state: any[]) => {
  return { type: SET_FILE_LENGTH, payload: state };
};
export const setCreateNewFolder = (state: Boolean) => {
  return { type: SET_CREATE_NEW_FOLDER, payload: state };
};
export const setUploadFileStatus = (state: Boolean) => {
  return { type: SET_UPLOAD_FILE_STATUS, payload: state };
};
export const setRegenerateStatus = (state: any) => {
  return { type: SET_REGENERATE_STATUS, payload: state };
};
export const setimageCardsDataAction = (state: any[]) => {
  return { type: SET_IMAGE_CARDS_DATA, payload: state };
};

export const setActiveCardType = (data: string) => {
  return { type: SET_CARD_TYPE, payload: data };
};
export const setFileActiveCardType = (data: string) => {
  return { type: SET_FILE_CARD_TYPE, payload: data };
};
export const setSemanticSearchImageId = (state: Number) => {
  return { type: SET_SEMANTIC_SEARCH_IMAGE_ID, payload: state };
};
export const setCardDataCollection = (state: any[]) => {
  return { type: SET_CARD_DATA_COLLECTION, payload: state };
};
export const setLandingScreen = (state: boolean) => {
  return { type: SET_LANDING_SCREEN, payload: state };
};
export const setPreviousId = (state: Number) => {
  return { type: SET_PREVIOUS_ID, payload: state };
};
export const setNextId = (state: Number) => {
  return { type: SET_NEXT_ID, payload: state };
};
export const setConversationId = (state: Number) => {
  return { type: SET_CONVERSATION_ID, payload: state };
};
export const setProModeStatus = (state: boolean) => {
  return { type: SET_PRO_MODE_STATUS, payload: state };
};
export const setProModeScreen = (state: boolean) => {
  return { type: SET_PRO_MODE_SCREEN, payload: state };
};
export const setMessageIId = (state: Number) => {
  return { type: SET_MESSAGE_ID, payload: state };
};
export const setEncodedSemanticSearchIdKvalue = (state: Number) => {
  return { type: SET_ENCODED_SEMANTIC_SEARCH_ID_KVALUE, payload: state };
};
export const setLoadingforReportGeneration = (state: boolean) => {
  return { type: SET_LOADING_FOR_REPORT_GENERATION, payload: state };
};
export const setEditableScreen = (state: boolean) => {
  return { type: SET_EDITABLE_CONTENT, payload: state };
};
export const setReportGenerationType = (state: string) => {
  return { type: SET_TYPE_FOR_REPORT_GENERATION, payload: state };
};
export const setSlidesForReports = (state: {}) => {
  return { type: SET_REPORT_SLIDES, payload: state };
};
export const setPdfForReports = (state: {}) => {
  return { type: SET_PDF_REPORTS, payload: state };
};
export const setLoadingforSwitching = (state: boolean) => {
  return { type: SET_LOADING_FOR_SWITCHING, payload: state };
};
export const setSearchType = (state: string) => {
  return { type: SET_SEARCH_TYPE, payload: state };
};
export const setLLMModel = (state: string) => {
  return { type: SET_LLM_MODEL, payload: state };
};
export const setAudioCardsDataAction = (state: any) => {
  return { type: SET_AUDIO_CARDS_DATA, payload: state };
};
export const setVideoCardsDataAction = (state: any) => {
  return { type: SET_VIDEO_CARDS_DATA, payload: state };
};

export const setSyncedNotifications = (state: any[]) => {
  return { type: SET_SYNCED_NOTIFICATIONS, payload: state };
};
export const FileForChatWithDoc = (state: string[]) => {
  return { type: SET_FILES_FOR_CHAT, payload: state };
};
export const setBatchQAStatus = (state: boolean) => {
  return { type: SET_BATCH_QA_STATUS, payload: state };
};
export const setBatchQAData = (state: any[]) => {
  return { type: SET_BATCH_QA_DATA, payload: state };
};
export const setBatchQAFolders = (state: any[]) => {
  return { type: SET_BATCH_QA_FOLDERS, payload: state };
};
export const setFileSummariserStatus = (state: boolean) => {
  return { type: SET_SUMMARISER, payload: state };
};
export const setGPTResearcher = (state: boolean) => {
  return { type: SET_GPT_RESEARCHER_STATUS, payload: state };
};

export const setUpdateResearchAgentAction = (state: ResearchAgentType) => {
  return { type: SET_UPDATE_RESEARCH_AGENT, payload: state };
};

export const setInitialResearchAgentAction = () => {
  return { type: SET_INITIAL_RESEARCH_AGENT };
};
