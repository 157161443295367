import { useEffect, useState } from "react";
import { RootState } from "../../../../Store";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { sseListModules } from "../../../../Store/requests/_contentNewtonRequests";
import { Button, notification, Skeleton } from "antd";
import { SSEMessage, SSEMessageList } from "../../components/common/DisplaySSEMessages";
import { mockSSEMessages } from "./mockData";
import { ModulesListing } from "./ModulesListing/ModulesListing";
import { ModuleType } from "./types";
import {
  setCNAvailableModulesList,
  setCNCurrentIndexData,
  setCNCurrentIndexDoneNextIndexProcess,
  setCNPutStepsWithGivenNamesAfterCurrentIndex
} from "../../../../Store/actions/_contentNewtonActions";
import { ContentNewtonState } from "../../../../Store/reducers/_contentNewtonReducer";
import { ConversationStepsMapping, ConversationStepsName } from "../../resources/constants";
import { setSessionExpiredFlagAction } from "../../../../Store/actions/_authActions";
// import { Message, SSEMessageList } from "./DisplayAdminMessages";

type ListingModuleSSEData = {
  message: { text: string; type: "main" | "step" | "substep" };
  data: ModuleType[];
  error: boolean;
};

export const ModuleSelection = ({ index }: { index: number }) => {
  const { currentStep, conversationSteps, detailsInputPrompt, availableModulesList, selectedModuleIds }: ContentNewtonState = useAppSelector(
    (state) => state.contentNewtonReducer
  );

  const initialState = {
    loading: !availableModulesList, //false,
    errorMessage: conversationSteps[index].data ?? "", //"",
    fatalError: false,
    retry: false,
    sseMessages: [],
    modulesList: []
  };

  const [sseMessages, setSSEMessages] = useState<SSEMessage[]>(initialState.sseMessages);
  const [modulesList, setModulesList] = useState<any[]>(initialState.modulesList);
  const [loading, setLoading] = useState(initialState.loading /* !availableModulesList */ /* true */);
  const [errorMessage, setErrorMessage] = useState(initialState.errorMessage);
  const [fatalError, setFatalError] = useState(false);
  const [retry, setRetry] = useState(initialState.retry);
  const dispatch = useAppDispatch();

  const handleRetry = () => {
    setRetry((p) => !p);
    resetComponentStates();
  };

  const resetComponentStates = () => {
    setLoading(initialState.loading);
    setErrorMessage(initialState.errorMessage);
    // setRetry(initialState.retry);
    setSSEMessages(initialState.sseMessages);
    setModulesList(initialState.modulesList);
  };

  useEffect(() => {
    setModulesList(availableModulesList);
  }, [availableModulesList]);

  // Simulate SSE receiving new messages one by one
  // useEffect(() => {
  //   // const mockSSEData: Message[] = [
  //   //   { type: "main", text: "Main message without steps" },
  //   //   { type: "step", text: "Step 1" },
  //   //   { type: "substep", text: "Sub-step 1.1" },
  //   //   { type: "substep", text: "Sub-step 1.2" },
  //   //   { type: "step", text: "Step 2" },
  //   //   { type: "substep", text: "Step 2.1" },
  //   //   { type: "main", text: "Main Step" },
  //   //   { type: "step" } // Message without text, used to signal completion of last step/substep
  //   // ];

  //   const mockSSEData: Message[] = mockSSEMessages.map(({ message }) => ({
  //     type: (message?.type || "main") as Message["type"],
  //     text: message?.text || ""
  //   }));

  //   mockSSEData.forEach((msg, index) => {
  //     setTimeout(() => {
  //       setSSEMessages((prev) => [...prev, msg]);
  //       //@ts-ignore
  //       if (msg.data) {
  //         //@ts-ignore
  //         setModulesList(msg.data);
  //       }
  //     }, index * 1000 * 0); // Simulate delay between SSE events
  //   });
  // }, []);

  useEffect(() => {
    if (availableModulesList.length > 0 || conversationSteps[index].status === "Done") {
      return;
    }

    fatalError && setFatalError(false);

    const sse = sseListModules(detailsInputPrompt);

    sse.addEventListener("pending", async (data: { data: string }) => {
      console.log(`pending`, data?.data);
      setLoading(false);

      let parsedData: ListingModuleSSEData;
      try {
        parsedData = JSON.parse(data.data);
        // parsedData = sseMessages[messageNumber];
        // setMessageNumber(messageNumber + 1);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        sse.close();
        return;
      }

      if (parsedData) {
        if (parsedData.message) {
          setSSEMessages((prev) => [...prev, parsedData.message]);
        }
      }
    });

    sse.addEventListener("end", async (data: { data: string }) => {
      console.log(`sse.addEventListener("end"`, data?.data);
      sse.close();
      setLoading(false);

      let parsedData: ListingModuleSSEData;
      try {
        parsedData = JSON.parse(data.data);
        // parsedData = {
        //   message: {
        //     text: "Prompt does not contain valid language, market, keymessage, brand. Please enter valid details and re-write your requirements.",
        //     type: "main"
        //   },
        //   data: null,
        //   error: true
        // };
      } catch (error) {
        console.error("Error parsing JSON:", error);
        sse.close();
        return;
      }

      if (parsedData) {
        //Logic

        if (parsedData.error) {
          setErrorMessage(parsedData.message.text);
          dispatch(setCNCurrentIndexData(parsedData.message.text));
          dispatch(setCNPutStepsWithGivenNamesAfterCurrentIndex([ConversationStepsName.USER_PROMPT_INPUT, ConversationStepsName.MODULES_LISTING]));
          dispatch(setCNCurrentIndexDoneNextIndexProcess());
        }
        //Show the Modules Listing
        else if (parsedData.data) {
          setSSEMessages((prev) => [...prev, { type: "main" }]); //Blank message to finish the last step
          setModulesList(parsedData.data);
          dispatch(setCNAvailableModulesList(parsedData.data));
        }
      }
    });

    const originalErrorHandler = sse.onerror;

    sse.onerror = (err) => {
      if (originalErrorHandler) {
        originalErrorHandler.call(sse, err);
      }

      notification.error({ message: `SSE Module Listing Error!\n ${err}` });

      // try {
      //   let error = JSON.parse(err.data) as { detail: string };
      //   if (error?.detail?.toLowerCase().includes("session")) {
      //     dispatch(setSessionExpiredFlagAction(true));
      //   }
      // } catch (err) {}
      console.log("sse.onerror", { err });
      sse.close();
      setLoading(false);
      setFatalError(true);
    };
  }, [availableModulesList, retry]);

  const isModuleSelectionStep = ConversationStepsMapping[currentStep] === ConversationStepsName.MODULES_LISTING;

  return (
    <div>
      {!fatalError &&
        (loading ? (
          <Skeleton active />
        ) : errorMessage ? (
          <div>{errorMessage}</div>
        ) : (
          <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "5px" }}>
            <SSEMessageList sseMessages={sseMessages} />
            <ModulesListing modules={modulesList} />
            <div style={{ display: modulesList.length > 0 && isModuleSelectionStep ? "flex" : "none", justifyContent: "flex-end" }}>
              <Button type="primary" disabled={!selectedModuleIds.length} onClick={() => dispatch(setCNCurrentIndexDoneNextIndexProcess())}>
                Proceed
              </Button>
            </div>
          </div>
        ))}
      {fatalError && (
        <div>
          <div>Error occured!</div>
          <Button type="link" onClick={handleRetry}>
            Retry
          </Button>
        </div>
      )}
    </div>
  );
};
