import { Card, Col, Row } from "antd";
import { FC, useEffect } from "react";
import { DashBoardCard } from "./_DashboardCard";
import { PROJECT_ICONS } from "../../Assets/Img/_DIcons";
import { DashBoardBanner } from "./_DashboardBanner";
import { useNavigate } from "react-router-dom";
import { ENUM_ROUTE_URLS } from "../../Routes/_routesConfig";
import { useDispatch, useSelector } from "react-redux";
import { setLayoutSyncAction } from "../../Store/actions/_commonActions";
import { LayoutSyncTypes } from "../../Utils/_gTypes";
import { getDefaultLayout } from "../../Utils";
import { Authorizer, siteConfig } from "../../Services";
import { setNavigatedFlagAction } from "../../Store/actions/_contentAIActions";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../Components/i18n/core/constants";
import _localStorageService from "../../Services/_localStorageService";
import { resetTMData } from "../Translation Module/Core/constant";
import { resetTranscreation } from "../Content Transcreation/Core/constants";

const authorizer = new Authorizer();

const Dashboard: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { i18n, t } = useTranslation();

  const { layoutSync }: { layoutSync: LayoutSyncTypes } = useSelector((state: any) => state?.commonReducer);

  useEffect(() => {
    initiate();
  }, []);

  const initiate = async () => {
    const isValid = await authorizer.verifyAuth(_localStorageService.getAccessToken() || "", dispatch);

    if (!isValid) return;

    const objNewLayoutSync: LayoutSyncTypes = getDefaultLayout(layoutSync);

    dispatch(
      setLayoutSyncAction({
        ...objNewLayoutSync,
        CONTENT_TRANSCREATION: true
      })
    );

    siteConfig.BASE_URL = process.env.REACT_APP_API_URL;
  };

  const handleNavigation = (route: string) => {
    const objNewLayoutSync: LayoutSyncTypes = getDefaultLayout(layoutSync);

    navigate(route);

    switch (route) {
      case ENUM_ROUTE_URLS.CONTENT_TRANSCREATION: {
        dispatch(
          setLayoutSyncAction({
            ...objNewLayoutSync,
            CONTENT_TRANSCREATION: true
          })
        );

        resetTranscreation(dispatch);
        siteConfig.BASE_URL = process.env.REACT_APP_API_URL;
        break;
      }
      case ENUM_ROUTE_URLS.SEMANTIC_SEARCH: {
        dispatch(setLayoutSyncAction({ ...objNewLayoutSync, SEMANTIC_SEARCH: true }));

        siteConfig.BASE_URL = process.env.REACT_APP_API_URL_FOR_SEMANTIC;
        break;
      }
      case ENUM_ROUTE_URLS.CONTENT_AI: {
        dispatch(setLayoutSyncAction({ ...objNewLayoutSync, CONTENT_AI: true }));
        dispatch(setNavigatedFlagAction(true));

        siteConfig.BASE_URL = process.env.REACT_APP_API_URL_FOR_CONTENT_AI;
        break;
      }
      case ENUM_ROUTE_URLS.TRANSLATION_MODULE: {
        dispatch(setLayoutSyncAction({ ...objNewLayoutSync, TRANSLATION_MODULE: true }));
        dispatch(setNavigatedFlagAction(true));
        resetTMData(dispatch);

        siteConfig.BASE_URL = process.env.REACT_APP_API_URL_FOR_TRANSLATION_MODULE;
        break;
      }

      default:
        dispatch(
          setLayoutSyncAction({
            ...objNewLayoutSync,
            CONTENT_TRANSCREATION: true
          })
        );
        siteConfig.BASE_URL = process.env.REACT_APP_API_URL;
        break;
    }
  };

  return (
    <Row gutter={[16, 8]} style={{ margin: "0px 10px", overflow: "auto" }}>
      <Col className="gutter-row" xs={{ span: 24 }}>
        <DashBoardBanner
          bgImage={PROJECT_ICONS.DASHBOARD_GROUP_8242}
          heading={t(I18N_MODULE_KEYS["content.dashboard.card.generativeai.title"])}
          description={t(I18N_MODULE_KEYS["content.dashboard.card.generativeai.desc"])}
        />
      </Col>
      <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
        <DashBoardCard
          image={PROJECT_ICONS.IDX_LOGO}
          heading={t(I18N_MODULE_KEYS["content.dashboard.card.idetail.title"])}
          description={t(I18N_MODULE_KEYS["content.dashboard.card.idetail.desc"])}
          handleSeeMoreAction={() => handleNavigation(ENUM_ROUTE_URLS.IDETAIL_EXTRACT)}
          viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.IDETAIL_EXTRACT)}
          imageStyle={{ height: "14px", width: "14px" }}
        />
      </Col>
      <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
        <DashBoardCard
          image={PROJECT_ICONS.IDX_LOGO}
          heading="Content Newton"
          description={t(I18N_MODULE_KEYS["content.dashboard.card.idetail.desc"])}
          handleSeeMoreAction={() => handleNavigation(ENUM_ROUTE_URLS.CONTENT_NEWTON)}
          viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.CONTENT_NEWTON)}
          imageStyle={{ height: "14px", width: "14px" }}
        />
      </Col>
      <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
        <DashBoardCard
          image={PROJECT_ICONS.TM_CONTENT_TRANSLATION_ICON}
          heading={t(I18N_MODULE_KEYS["content.dashboard.card.translation-module.title"])}
          description={t(I18N_MODULE_KEYS["content.dashboard.card.translation-module.desc"])}
          handleSeeMoreAction={() => {
            handleNavigation(ENUM_ROUTE_URLS.TRANSLATION_MODULE);
          }}
          viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.TRANSLATION_MODULE)}
          imageStyle={{ height: "20px", width: "20px" }}
        />
      </Col>
      <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
        <DashBoardCard
          image={PROJECT_ICONS.DASHBOARD_GROUP_8235}
          heading={t(I18N_MODULE_KEYS["content.dashboard.card.transcreation.title"])}
          description={t(I18N_MODULE_KEYS["content.dashboard.card.transcreation.desc"])}
          handleSeeMoreAction={() => handleNavigation(ENUM_ROUTE_URLS.CONTENT_TRANSCREATION)}
          viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.CONTENT_TRANSCREATION)}
        />
      </Col>
      <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
        <DashBoardCard
          image={PROJECT_ICONS.DASHBOARD_GROUP_8236}
          heading={t(I18N_MODULE_KEYS["content.dashboard.card.semantic.title"])}
          description={t(I18N_MODULE_KEYS["content.dashboard.card.semantic.desc"])}
          handleSeeMoreAction={() => handleNavigation(ENUM_ROUTE_URLS.SEMANTIC_SEARCH)}
          viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.SEMANTIC_SEARCH)}
        />
      </Col>
      <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
        <DashBoardCard
          image={PROJECT_ICONS.DASHBOARD_GROUP_8237}
          // heading="Intelligent Assistant"
          heading={t(I18N_MODULE_KEYS["content.dashboard.card.contentai.title"])}
          description={t(I18N_MODULE_KEYS["content.dashboard.card.contentai.desc"])}
          handleSeeMoreAction={() => handleNavigation(ENUM_ROUTE_URLS.CONTENT_AI)}
          // viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.INTELLIGENT_ASSISTANT)}
          viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.CONTENT_AI)}
        />
      </Col>
      <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
        <DashBoardCard
          image={PROJECT_ICONS.DASHBOARD_GROUP_8238}
          heading={t(I18N_MODULE_KEYS["content.dashboard.card.fieldcopilot.title"])}
          description={t(I18N_MODULE_KEYS["content.dashboard.card.fieldcopilot.desc"])}
          handleSeeMoreAction={() => null}
          viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.FIELD_COPILOT)}
        />
      </Col>
      <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
        <DashBoardCard
          image={PROJECT_ICONS.DASHBOARD_GROUP_8239}
          heading={t(I18N_MODULE_KEYS["content.dashboard.card.kolvideo.title"])}
          description={t(I18N_MODULE_KEYS["content.dashboard.card.kolvideo.desc"])}
          handleSeeMoreAction={() => null}
          viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.KOL_VIDEO)}
        />
      </Col>
      <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
        <DashBoardCard
          image={PROJECT_ICONS.DASHBOARD_GROUP_8240}
          // heading="Gen AI Dashboard Assistant"
          heading={t(I18N_MODULE_KEYS["content.dashboard.card.assistant.title"])}
          description={t(I18N_MODULE_KEYS["content.dashboard.card.assistant.desc"])}
          handleSeeMoreAction={() => null}
          viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.GEN_AI_DASHBOARD_ASSISTANT)}
        />
      </Col>
      <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
        <DashBoardCard
          image={PROJECT_ICONS.DASHBOARD_GROUP_8239}
          // heading="Hyper Personalisation"
          heading={t(I18N_MODULE_KEYS["content.dashboard.card.hyper.title"])}
          description={t(I18N_MODULE_KEYS["content.dashboard.card.hyper.desc"])}
          handleSeeMoreAction={() => null}
          viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.HYPER_PERSONALISATION)}
        />
      </Col>
      <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
        <DashBoardCard
          image={PROJECT_ICONS.DASHBOARD_GROUP_8235}
          // heading="Patient Journey Mapping"
          heading={t(I18N_MODULE_KEYS["content.dashboard.card.patientjourney.title"])}
          description={t(I18N_MODULE_KEYS["content.dashboard.card.patientjourney.desc"])}
          handleSeeMoreAction={() => null}
          viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.PATIENT_JOURNEY_MAPPING)}
        />
      </Col>
      <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
        <DashBoardCard
          image={PROJECT_ICONS.DASHBOARD_GROUP_8236}
          // heading="Content Translation & Localisation"
          heading={t(I18N_MODULE_KEYS["content.dashboard.card.translationlocalisation.title"])}
          description={t(I18N_MODULE_KEYS["content.dashboard.card.translationlocalisation.desc"])}
          handleSeeMoreAction={() => null}
          viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.TRANSLATION_LOCALISATION)}
        />
      </Col>
      <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
        <DashBoardCard
          image={PROJECT_ICONS.DASHBOARD_GROUP_8238}
          // heading="Personalized Videos"
          heading={t(I18N_MODULE_KEYS["content.dashboard.card.personalizedvideo.title"])}
          description={t(I18N_MODULE_KEYS["content.dashboard.card.personalizedvideo.desc"])}
          handleSeeMoreAction={() => null}
          viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.PERSONALIZED_VIDEO)}
        />
      </Col>
      <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
        <DashBoardCard
          image={PROJECT_ICONS.DASHBOARD_GROUP_8240}
          // heading="Sentiment Analysis"
          heading={t(I18N_MODULE_KEYS["content.dashboard.card.sentimentanalysis.title"])}
          description={t(I18N_MODULE_KEYS["content.dashboard.card.sentimentanalysis.desc"])}
          handleSeeMoreAction={() => null}
          viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.SENTIMENT_ANALYSIS)}
        />
      </Col>
      <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
        <DashBoardCard
          image={PROJECT_ICONS.DASHBOARD_GROUP_8237}
          // heading="Social Media Content Creation"
          heading={t(I18N_MODULE_KEYS["content.dashboard.card.contentcreation.title"])}
          description={t(I18N_MODULE_KEYS["content.dashboard.card.contentcreation.desc"])}
          handleSeeMoreAction={() => null}
          viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.CONTENT_CREATION)}
        />
      </Col>
    </Row>
  );
};

export { Dashboard };

{
  /* <Row gutter={[16, 8]} style={{ margin: "0px 10px", overflow: "auto" }}>
<Col className="gutter-row" xs={{ span: 24 }}>
  <DashBoardBanner
    bgImage={PROJECT_ICONS.DASHBOARD_GROUP_8242}
    heading="Generative AI"
    description="Generative artificial intelligence (AI) is artificial intelligence capable of generating text, images, or other media, using generative models. Generative AI models learn the patterns and structure of their input training data and then generate new data that has similar characteristics."
  />
</Col>
<Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
  <DashBoardCard
    image={PROJECT_ICONS.DASHBOARD_GROUP_8235}
    heading="Content Transcreation"
    description="Process of creating and adapting content from one language to another, while maintaining it's intent, context and style, tone."
    handleSeeMoreAction={() => handleNavigation(ENUM_ROUTE_URLS.CONTENT_TRANSCREATION)}
    viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.CONTENT_TRANSCREATION)}
  />
</Col>
<Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
  <DashBoardCard
    image={PROJECT_ICONS.DASHBOARD_GROUP_8236}
    heading="Semantic Search"
    description="Semantic search seeks contextually relevant results, understanding the query's meaning, unlike lexical search, which relies on word matching."
    handleSeeMoreAction={() => handleNavigation(ENUM_ROUTE_URLS.SEMANTIC_SEARCH)}
    viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.SEMANTIC_SEARCH)}
  />
</Col>
<Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
  <DashBoardCard
    image={PROJECT_ICONS.DASHBOARD_GROUP_8237}
    // heading="Intelligent Assistant"
    heading="Content AI"
    description="AI provides personalized support with cognitive computing skills, leveraging its capacity to understand, learn, and adapt to individual needs."
    handleSeeMoreAction={() => handleNavigation(ENUM_ROUTE_URLS.CONTENT_AI)}
    // viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.INTELLIGENT_ASSISTANT)}
    viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.CONTENT_AI)}
  />
</Col>
<Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
  <DashBoardCard
    image={PROJECT_ICONS.DASHBOARD_GROUP_8238}
    heading="Field Copilot"
    description="Empowering Reps with Unparalleled HCP, Patient Insights, and Advanced Pharma & Product Recommendations."
    handleSeeMoreAction={() => null}
    viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.FIELD_COPILOT)}
  />
</Col>
<Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
  <DashBoardCard
    image={PROJECT_ICONS.DASHBOARD_GROUP_8239}
    heading="KOL Video"
    description="Create KOL videos seamlessly with AI-generated KOL avatars, GenAI powered script generation, and a comprehensive editing suite."
    handleSeeMoreAction={() => null}
    // viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.FIELD_COPILOT)}
  />
</Col>
<Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
  <DashBoardCard
    image={PROJECT_ICONS.DASHBOARD_GROUP_8240}
    heading="Gen AI Dashboard Assistant"
    description="Simplifying data analysis with natural language conversational inputs, interactive visual insights, and easy narratives for informed and quick decision-making."
    handleSeeMoreAction={() => null}
    // viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.FIELD_COPILOT)}
  />
</Col>
<Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
  <DashBoardCard
    image={PROJECT_ICONS.DASHBOARD_GROUP_8239}
    heading="Hyper Personalisation"
    description="Tailoring experiences to the core of individual preferences and needs, creating a deeply personalized and meaningful interaction."
    handleSeeMoreAction={() => null}
    viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.HYPER_PERSONALISATION)}
  />
</Col>
<Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
  <DashBoardCard
    image={PROJECT_ICONS.DASHBOARD_GROUP_8235}
    heading="Patient Journey Mapping"
    description="Revolutionizing patient care in pharma through Gen AI-driven patient journey mapping, understanding and personalizing healthcare experiences."
    handleSeeMoreAction={() => null}
    // viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.FIELD_COPILOT)}
  />
</Col>
<Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
  <DashBoardCard
    image={PROJECT_ICONS.DASHBOARD_GROUP_8236}
    heading="Content Translation & Localisation"
    description="Adapting content for global audiences through translation & localization, ensuring cultural relevance, linguistic accuracy, and regional appeal."
    handleSeeMoreAction={() => null}
    // viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.FIELD_COPILOT)}
  />
</Col>
<Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
  <DashBoardCard
    image={PROJECT_ICONS.DASHBOARD_GROUP_8238}
    heading="Personalized Videos"
    description="Revolutionize healthcare communication with personalized videos to tailor information delivery  for Healthcare Professionals (HCPs) and patients."
    handleSeeMoreAction={() => null}
    // viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.FIELD_COPILOT)}
  />
</Col>
<Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
  <DashBoardCard
    image={PROJECT_ICONS.DASHBOARD_GROUP_8240}
    heading="Sentiment Analysis"
    description="Using cognitive computing to identify and categorize user opinions in text for enhanced sentiment analysis and comprehension."
    handleSeeMoreAction={() => null}
    viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.SENTIMENT_ANALYSIS)}
  />
</Col>
<Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 8 }}>
  <DashBoardCard
    image={PROJECT_ICONS.DASHBOARD_GROUP_8237}
    heading="Social Media Content Creation"
    description="Craft Social media content and transcreate messages effortlessly to redefine communication for HCPs, patients, and HCP marketing."
    handleSeeMoreAction={() => null}
    // viewUseCaseLibrary={() => handleNavigation(ENUM_ROUTE_URLS.USE_CASES + ENUM_ROUTE_URLS.FIELD_COPILOT)}
  />
</Col>
</Row> */
}
