import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Col, Row } from "antd";

import { CTFooter, Stepper } from "./Components";
import { CONTENT_TRANSCREATION_STEPS, ENUM_CONTENT_TRANSCREATION_BUTTON_TXT, handleCancelApiRequest } from "../../Utils";
import { CTOptimize, CTProcess, CTSource } from "./Steppers";
import "../../Assets/Css/Style.css";
import {
  setActiveStepperStateAction,
  setButtonTextStateAction,
  setCTFinalProductAction,
  setCTSourceAction,
  setResponseCtAction
} from "../../Store/actions/_contentTranscreationActions";
import { setLoadingStatusAction, setNotificationMsgAction } from "../../Store/actions/_commonActions";
import { SelectSource } from "./_SelectSource";
import { useNavigate } from "react-router-dom";
import { ENUM_ROUTE_URLS } from "../../Routes/_routesConfig";
import { setActionDrawerDataAction } from "../../Components/ActionDrawer/State";
import { CT_FINAL_PRODUCTS, CT_SOURCE_TYPES, ENUM_PROCESS_SCREENS } from "./Core/constants";
import { Authorizer, siteConfig } from "../../Services";
import { SelectFinalProduct } from "./Steppers/sources/SelectFinalProduct";
import { SelectVideoSource } from "./Steppers/sources/SelectVideoSource";
import { BlogModal } from "./Steppers/sources/BlogModal";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../Components/i18n/core/constants";
import _localStorageService from "../../Services/_localStorageService";
import CreateNewPostModal from "./Content Translation/components/CreateNewPostModal";

const authorizer = new Authorizer();

export const ContentTranscreationWrapper: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { activeStepper, source, finalProduct, responseCT, regenerate } = useSelector((state: any) => state?.contentTranscreationReducer);

  const [showOverlay, setShowOverlay] = useState(false);
  const [openBlogURLModal, setOpenBUM] = useState(false);
  const [openCreateNewPostModal, setOpenCreateNewPostModal] = useState(false);

  useEffect(() => {
    initiate();

    return () => {
      dispatch(setActiveStepperStateAction(CONTENT_TRANSCREATION_STEPS.STEP_0));
      dispatch(setResponseCtAction({}));
      dispatch(setButtonTextStateAction(ENUM_CONTENT_TRANSCREATION_BUTTON_TXT.GET_STARTED));

      dispatch(setCTSourceAction(CT_SOURCE_TYPES.EMAIL));
      dispatch(setCTFinalProductAction(CT_FINAL_PRODUCTS.VIDEO));

      dispatch(setLoadingStatusAction(false));
      dispatch(setActionDrawerDataAction({}));

      handleCancelApiRequest();
    };
  }, []);

  const initiate = async () => {
    const isValid = await authorizer.verifyAuth(_localStorageService.getAccessToken() || "", dispatch);
  };

  const getActiveStepper = () => {
    switch (activeStepper) {
      case CONTENT_TRANSCREATION_STEPS.STEP_0:
        return <SelectFinalProduct />;
      // return <SelectSource />;
      case CONTENT_TRANSCREATION_STEPS["STEP_0.5"]:
        //return <SelectFinalProduct />;
        return <SelectVideoSource />;

      case CONTENT_TRANSCREATION_STEPS.STEP_1:
        return <CTSource />;
      // case CONTENT_TRANSCREATION_STEPS.STEP_2:
      // return <CTOptimize />;
      case CONTENT_TRANSCREATION_STEPS.STEP_3:
        return <CTProcess setOpenBUM={setOpenBUM} />;
      case CONTENT_TRANSCREATION_STEPS.STEP_4:
        return <CTProcess />;
      default:
        break;
    }
  };

  const openPredisAIPopup = () => {
    setShowOverlay(true);
    dispatch(setLoadingStatusAction(true));

    //@ts-ignore
    const predis = new window.Predis();

    predis.initialize({
      appId: "2ac15VN8a3F3S0AGHNVI8cjV7mU4V99N",
      embedToken:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiIyYWMxNVZOOGEzRjNTMEFHSE5WSThjalY3bVU0Vjk5TiIsInVzZXJfaWQiOiJwcmF0ZWVrLmphaW5AaW5kZWdlbmUuY29tIiwiaWF0IjoxNzAxMTUxMDU3fQ.rDThcHWFyAlGfxoKAUPyRjVKJQ-nqWtw8Hwk11I4OmU",
      //@ts-ignore
      mode: window.Predis.modes.DETAILED
    });

    predis.on("ready", () => {
      // open Predis.ai post creator
      console.log("Ready!");
      setShowOverlay(false);
      dispatch(setLoadingStatusAction(false));

      activatePredisFullScreen();

      predis.createPost({
        onPostPublish: function (err: any, data: any) {
          if (err) {
            console.log("Error Occurred in Posting", err);
            dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["content.transcreation.wrapper.notification.postpublicationfail"])));
            return;
          }
          dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["content.transcreation.wrapper.notification.postpublicationsuccess"])));
          // published posts' data
          console.log({ data });
        }
      });
    });

    predis.on("error", (err: any) => {
      console.log({ err });
      // catch if there is any error SDK initialization
    });
  };

  const activatePredisFullScreen = () => {
    let timer = setInterval(() => {
      const elmParentDiv = document.getElementsByTagName("iframe")?.[0]?.parentElement;

      if (elmParentDiv) {
        elmParentDiv.className = "predis-dialog";
        clearInterval(timer);
      }
    }, 1000);
  };

  return (
    <>
      <Row id="content-transcreation-root" gutter={[16, 16]} className="content-trans-font-style content-transcreation">
        <Col xs={{ span: 24 }}>
          <Stepper />
        </Col>
        <Col xs={{ span: 24 }}>{getActiveStepper()}</Col>
        <Col xs={{ span: 24 }}>
          {activeStepper === CONTENT_TRANSCREATION_STEPS.STEP_0 || activeStepper === CONTENT_TRANSCREATION_STEPS["STEP_0.5"] ? (
            <Row gutter={[8, 8]}>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Button
                  className="ant-btn-lg"
                  type="primary"
                  style={{ marginRight: "5px", background: "#0054CF" }}
                  onClick={() => {
                    activeStepper === CONTENT_TRANSCREATION_STEPS["STEP_0.5"]
                      ? dispatch(setActiveStepperStateAction(CONTENT_TRANSCREATION_STEPS.STEP_0))
                      : navigate(ENUM_ROUTE_URLS.DASHBOARD);
                  }}
                >
                  {/* {activeStepper === CONTENT_TRANSCREATION_STEPS["STEP_0.5"] ? "Go Back" : "View Dashboard"} */}
                  {activeStepper === CONTENT_TRANSCREATION_STEPS["STEP_0.5"]
                    ? `${t(I18N_MODULE_KEYS["content.button.goback"])}`
                    : `${t(I18N_MODULE_KEYS["content.transcreation.footer.button.viewdashboard"])}`}
                </Button>
                <Button
                  className="ant-btn-lg"
                  type="primary"
                  style={{ marginRight: "5px", background: "#0054CF" }}
                  onClick={() => {
                    if (finalProduct === CT_FINAL_PRODUCTS.SOCIAL_MEDIA_POST) {
                      setOpenCreateNewPostModal(true);
                      // openPredisAIPopup();
                    } else if (finalProduct === CT_FINAL_PRODUCTS.VIDEO) {
                      activeStepper === CONTENT_TRANSCREATION_STEPS.STEP_0 &&
                        dispatch(setActiveStepperStateAction(CONTENT_TRANSCREATION_STEPS["STEP_0.5"]));

                      if (activeStepper === CONTENT_TRANSCREATION_STEPS["STEP_0.5"]) {
                        if (source === CT_SOURCE_TYPES.EMAIL) {
                          dispatch(setActiveStepperStateAction(CONTENT_TRANSCREATION_STEPS.STEP_1));
                        } else if (source === CT_SOURCE_TYPES.BLOG) {
                          setOpenBUM(true);
                        }
                      }
                    }
                  }}
                >
                  {/* Submit */}
                  {t(I18N_MODULE_KEYS["content.button.submit"])}
                </Button>
              </Col>
            </Row>
          ) : (
            <CTFooter />
          )}
        </Col>
      </Row>
      {openCreateNewPostModal && (
        <CreateNewPostModal
          open={openCreateNewPostModal}
          setOpen={setOpenCreateNewPostModal}
          openPredisAIPopup={openPredisAIPopup}
        />
      )}
      {showOverlay && <div style={{ position: "absolute", inset: 0, backgroundColor: "#d9d9d9", opacity: 0.3 }}></div>}
      <BlogModal open={openBlogURLModal} setOpen={setOpenBUM} />
    </>
  );
};
