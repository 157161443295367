import { useSelector } from "react-redux";
import React, { FC, useState, useMemo, useEffect, useRef } from "react";
import debounce from "lodash/debounce";
import { Row, Col, Input, Button, Avatar, Image, Modal, Checkbox, Card, Space, Typography, Spin, Tooltip, Radio, InputNumber, List, ConfigProvider } from "antd";
import { Select } from "antd";
import "./StylesFolderForNextInsight/_ModalComponentForFormat.css";
import { useDispatch } from "react-redux";
import {
  setAdvancedFilter,
  setFormat,
  setRegenerateStatus,
  setActiveCardType,
  setLandingScreen,
  setEncodedSemanticSearchIdKvalue,
} from "../../../../Store/actions/_semanticSearchActions";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import { setLoadingStatusAction, setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { DynamicInputs } from "./_DynamicInputs";

import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
import { PDFViewer } from "../../../Content Newton/components/pdf/PDFViewer";
import { Document } from "react-pdf";
import MSOfficeDocsPreview from "../../../Translation Module/Components/Upload Documents/MSOfficeDocsPreview";
interface ComponentAProps {
  columns: string[];
  setColumns: React.Dispatch<React.SetStateAction<string[]>>;
  internalVarA: number;
  setInternalVarA: React.Dispatch<React.SetStateAction<number>>;
  internalVarB: string;
  setInternalVarB: React.Dispatch<React.SetStateAction<string>>;
  setStepperForInternalVarb: React.Dispatch<React.SetStateAction<number>>;
  stepperForinternalVarB: number;
  onHide: () => void;
  componentFormatVisible: boolean;
}

const { Option } = Select;
const choicestyles = {
  background: "#F3F6FA",
  border: "1px solid #034EA2",
  borderRadius: "24px",
  opacity: 1,
  color: "#034EA2",
};

export const ModalComponentForExport: React.FC<ComponentAProps> = ({
  onHide,
  stepperForinternalVarB,
  setStepperForInternalVarb,
  internalVarA,
  setInternalVarA,
  internalVarB,
  setInternalVarB,
  columns,
  setColumns,
  componentFormatVisible,
}) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const {
    searchQuery,
    encodedSearchQuery,
    selectedReports,
    semanticEncodedSearchidkvalue,
    advancedFilterStatus,
    regeneratestatus,
    advancedFilterList,
    advancedFilter,
    Format,
    kvalue,
    semanticSearchid,
    semanticEncodedSearchid,
    cardData,
  } = useSelector((state: any) => state?.semanticSearchReducer);
  const [items, setItems] = useState<string[]>([""]);
  const [selectedFormat, setSelectedFormat] = useState("Export as PPT");
  const [stepper, setStepper] = useState(1);
  const [selectedScope, setSelectedScope] = useState(cardData?.k?.toString() ?? "10");
  const [numColumns, setNumColumns] = useState<number>(0);
  const [numRows, setNumRows] = useState<number>(5);
  const [generatedSchema, setGeneratedSchema] = useState<boolean>(false);

  const [selectedCardId, setSelectedCardId] = useState<number | null>(0);
  const [selectedCardPdfId,setSelectedCardPdfId]=useState<number|null>(0);
  const [imageWidth, setImageWidth] = useState(910); // Initial width is set to 910
  const [imageHeight , setImageHeight] = useState(450)

  function calculateWidth() {
    if (typeof window !== 'undefined') {
      const innerWidth = window.innerWidth || 910; // Fallback to 910 if undefined
      if (innerWidth >= 1470 && innerWidth <= 1500) {
        return 880; // Adjust the multiplier as per your requirements
      }
     else if (innerWidth >= 1440 && innerWidth <= 1470) {
        return 860; // Adjust the multiplier as per your requirements
      }
      else if (innerWidth >= 1410 && innerWidth <= 1440) {
        return 840; // Adjust the multiplier as per your requirements
      }
      else if (innerWidth >= 1380 && innerWidth <= 1410) {
        return 820; // Adjust the multiplier as per your requirements
      }
      else if (innerWidth >= 1350 && innerWidth <= 1380) {
        return 800; // Adjust the multiplier as per your requirements
      }
      else if (innerWidth >= 1320 && innerWidth <= 1350) {
        return 780; // Adjust the multiplier as per your requirements
      }
      else if (innerWidth >= 1290 && innerWidth <= 1320) {
        return 760; // Adjust the multiplier as per your requirements
      }
    }
    return 910; // Default width
  }


  function calculateHeight() {
    if (typeof window !== 'undefined') {
      const innerHeight = window.innerHeight || 910; // Fallback to 910 if undefined
      if (innerHeight >= 601 && innerHeight <= 700) {
        return 390; // Adjust the multiplier as per your requirements
      }
    }
    return 450; // Default width
  }


  useEffect(() => {
    const handleResize = () => {
      const newWidth = calculateWidth();
      const newHeight = calculateHeight();
      setImageHeight(newHeight)
      setImageWidth(newWidth);

    };
    handleResize(); // Call on mount to set initial width based on window size
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

const handleCardSelection = (cardId: number) => {
  setSelectedCardId(cardId);
};
const handleCardPDFSelection = (cardId:number) =>{
  setSelectedCardPdfId(cardId)
}


  const PPTData = [
    [
      { id: 1, card: "main", backgroundImage: PROJECT_ICONS.PPTMAIN },
      { id: 2, card: "1", backgroundImage: PROJECT_ICONS.PPT1 },
      { id: 3, card: "2", backgroundImage: PROJECT_ICONS.PPT2 },
    ],
    [
    
      { id: 4, card: "3", backgroundImage: PROJECT_ICONS.PPT3 },
      { id: 5, card: "4", backgroundImage: PROJECT_ICONS.PPT4 },
      { id: 6, card: "5", backgroundImage: PROJECT_ICONS.PPT5 },
    ]
  ];

  const PDFData = [
    [
      { id: 1, card: "1", backgroundImage: PROJECT_ICONS.PDF1 },
      { id: 2, card: "2", backgroundImage: PROJECT_ICONS.PDF2 },
      { id: 3, card: "main", backgroundImage: PROJECT_ICONS.PDFMAIN },

    ],
    [
      { id: 4, card: "3", backgroundImage: PROJECT_ICONS.PDF3 },
      { id: 5, card: "4", backgroundImage: PROJECT_ICONS.PDF4 },
    ],
  ];

  const pptFileUrl =
    "https://devkraftgenai.s3.ap-south-1.amazonaws.com/semantic_documents/Oncology/Bavarian%20Nordic_Bladder_Cancer_Treatment_Landscape_Ver1.00_SN.pptx"; // URL to your .pptx file

  console.log(internalVarA + "this is the ultimate hehe" + selectedScope.toString());

  const handleRadioChange = (e: any) => {
    setStepper(1);
    setSelectedFormat(e);
    setSelectedCardId(0);
    setSelectedCardPdfId(0);
  };

  const otherFunction = async () => {
    if (selectedFormat === "Export as PPT") {
      // Logic to download a URL as PPT using fetch and blob
      const pptUrl = "https://devkraftgenai.s3.ap-south-1.amazonaws.com/semantic_documents/Hiv/GIST+Gilead_.pptx"; // Replace with your PPT URL
      await downloadFile(pptUrl, "ExportedFile.pptx");
    } else {
      // Logic to download a URL for other cases using fetch and blob
      const otherUrl = "https://devkraftgenai.s3.ap-south-1.amazonaws.com/semantic_documents/Hiv/Messaging+framework+1.pdf"; // Replace with your desired URL
      await downloadFile(otherUrl, "ExportedFile.pdf");
    }
  };

  const downloadFile = async (url: any, fileName: any) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href); // Free up memory
      onHide();
      dispatch(setNotificationMsgAction("File downloaded successfully"));

    } catch (error) {
      console.error("Error downloading the file:", error);
      dispatch(setNotificationMsgAction("Error downloading the file"));
    }
  };

  return (
    <>
    
      <div className="format-overlay">
        <div className="format-close-button" onClick={onHide}>
          X
        </div>
        <Col span={21} className="format-content-container">
          <Card className="cardStyle">
            <Row>
              <Col span={5} className="format-colStyle">
                <div className="format-padding-container" style={{ paddingTop: "10px" }}>
                  <div className="format-title">Choose Export Format</div>
                  <div className="format-radio-group" style={{ marginLeft: "10px" }}>
                    {["Export as PPT", "Export as PDF", "Export as DOCX", "Export as TXT", "Export as HTML", "Export as Images"].map((format) => (
                      <div
                        key={format}
                        className={`format-radioContainer ${selectedFormat === format ? "selected" : ""}`}
                        onClick={() => handleRadioChange(format)}
                      >
                        <Image src={selectedFormat === format ? PROJECT_ICONS.RADIOCHECK : PROJECT_ICONS.RADIO} />
                        <div className="format-radioLabel">{format.charAt(0).toUpperCase() + format.slice(1).toLowerCase()}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
              <Col span={18} className="format-colStyle2" >
                <div className="format-colStyle2-container" >
                  {stepper == 1 ? (
                    <>
                      {selectedFormat === "Export as PPT" ? (
                        <>
                         <div style={{ padding: "20px", display: "flex", flexDirection: "column", gap: "20px" }}>
                            {PPTData.map((row, rowIndex) => (
                              <Row key={rowIndex} gutter={16}>
                                {row.map((card: any, colIndex: any) => (
                                  <Col key={colIndex} span={8}>
                                    {/* <Card
                                      style={{
                                        height: "200px",
                                        backgroundImage: `url(${card.backgroundImage})`,
                                        backgroundSize: "cover", // This will make the image cover the entire card
                                        backgroundPosition: "center", // Optional: centers the image
                                        backgroundRepeat: "no-repeat", // Optional: prevents repeating the image
                                        border: card.id === selectedCardId ? "4px solid #034EA2" : "1px solid black", // Corrected condition
                                        cursor:'pointer'
                                      
                                      }}
                                      onClick={()=>{handleCardSelection(card.id)}}
                                    ></Card> */}
                                    <div style={{ height: "200px",border: card.id === selectedCardId ? "4px solid #034EA2" : "1px solid black", justifyContent:'center',
                                        cursor:'pointer',borderRadius:'5px'}}>
                                     <Image style={{height:'160px',zIndex:9999999999999999999999999}} src={card.backgroundImage}
                                      preview={{
                                        getContainer: () => document.body, // This ensures the preview modal renders at the body level
                                      }}
                                     />
                                     <div style={{display:'flex',justifyContent:'flex-end',marginTop:card.id==selectedCardId?'0px':'5px'}} onClick={()=>{handleCardSelection(card.id)}}>
                                     <Button type="link" style={{fontWeight:'bold',color:card.id===selectedCardId?"grey":'#034EA2',}}>{card.id===selectedCardId?"Selected":"Select"}</Button>
                                     </div>
                                    </div>
                                  </Col>
                                ))}
                              </Row>
                            ))}
                          </div>
                        </>
                      ) : selectedFormat === "Export as PDF" ? (
                        <>
                          <div style={{ padding: "20px", display: "flex", flexDirection: "column", gap: "20px" }}>
                            {PDFData.map((row, rowIndex) => (
                              <Row key={rowIndex} gutter={16}>
                                {row.map((card: any, colIndex: any) => (
                                  <Col key={colIndex} span={8}>
                                    {/* <Card
                                      style={{
                                        height: "200px",
                                        backgroundImage: `url(${card.backgroundImage})`,
                                        backgroundSize: "cover", // This will make the image cover the entire card
                                        backgroundPosition: "center", // Optional: centers the image
                                        backgroundRepeat: "no-repeat", // Optional: prevents repeating the image
                                        border: card.id === selectedCardId ? "4px solid #034EA2" : "1px solid black", // Corrected condition
                                        cursor:'pointer'
                                      
                                      }}
                                      onClick={()=>{handleCardSelection(card.id)}}
                                    ></Card> */}
                                    <div style={{ height: "200px",border: card.id === selectedCardPdfId ? "4px solid #034EA2" : "1px solid black", justifyContent:'center',
                                        cursor:'pointer',borderRadius:'5px'}}>
                                          <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                     <Image style={{height:'160px',zIndex:9999999999999999999999999,width:'100%'}} src={card.backgroundImage}
                                      preview={{
                                        getContainer: () => document.body, // This ensures the preview modal renders at the body level
                                      }}
                                     />
                                     </div>
                                     <div style={{display:'flex',justifyContent:'flex-end',marginTop:card.id==selectedCardPdfId?'0px':'5px'}} onClick={()=>{handleCardPDFSelection(card.id)}}>
                                     <Button type="link" style={{fontWeight:'bold',color:card.id===selectedCardPdfId?"grey":'#034EA2',}}>{card.id===selectedCardPdfId?"Selected":"Select"}</Button>
                                     </div>
                                    </div>
                                  </Col>
                                ))}
                              </Row>
                            ))}
                          </div>
                        
                        </>
                      ) : (
                        <>
                          <div className="format-nottableformat">
                            <div className="format-nottableformat-content">
                              <div className="format-nottableformat-text">There is no template for the selected Export Option</div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {selectedFormat === "Export as PPT" ? (
                        <>
                          {/* <iframe
                            src="C:/Users/Lenovo/Downloads/Screenshot+2024-11-14+212752.png.html"
                            style={{
                              width: "100%",
                              height: "450px",
                              border: "none",
                              marginTop: "10px",
                              marginLeft: "10px",
                              marginRight: "10px",
                              borderRadius: "10px",
                            }}
                            title="PowerPoint Viewer"
                          ></iframe> */}
                          <Image src={PROJECT_ICONS.PPTFORDEMO} style={{
                              width: imageWidth,
                              height: imageHeight,
                              marginTop: "20px",
                              marginLeft: "10px",
                              marginRight: "10px",
                              borderRadius: "10px",
                              border:'1px black solid'
                            }}
                            preview={false}
                            
                            />
                        </>
                      ) : selectedFormat === "Export as PDF" ? (
                        <>
                          <iframe
                            src="https://devkraftgenai.s3.ap-south-1.amazonaws.com/semantic_documents/Hiv/Messaging+framework+1.pdf"
                            style={{
                              width: imageWidth,
                              height: imageHeight,
                              border: "none",
                              marginTop: "10px",
                              marginLeft: "10px",
                              marginRight: "10px",
                              borderRadius: "10px",
                            }}
                            title="PowerPoint Viewer"
                          ></iframe>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>

                <div className="format-generate-button">
                  <Button
                    className="format-generate-main-button"
                    onClick={() => {
                      stepper === 1 ? setStepper(2) : otherFunction(); // Replace 'otherFunction' with the desired function for the 'DOWNLOAD' scenario
                    }}
                  >
                    {stepper == 1 ? <>GENERATE</> : <>DOWNLOAD</>}
                  </Button>
                </div>
              </Col> 
            </Row>
          </Card>
        </Col>
      </div>
    </>
  );
};
