import React, { FC, useState } from "react";
import { Col, Card, Button, Image } from "antd";
import TextArea from "antd/es/input/TextArea";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import Markdown from "markdown-to-jsx";
import { useSelector } from "react-redux";

interface FileSummariserWrapperProps {
  fileSummariserQuery: string;
  setFileSummariserQuery: (query: string) => void;
  isLoadingSummary: boolean; // Add this line
  setIsLoadingForSummary: (loading: boolean) => void; // Add this line
  fileSummary : any;
}

const FileSummariserWrapper: FC<FileSummariserWrapperProps> = ({ fileSummariserQuery, setFileSummariserQuery,isLoadingSummary,setIsLoadingForSummary,fileSummary }) => {
  const { fileForChatWithDoc } = useSelector((state: any) => state.semanticSearchReducer);
  const [stepperForPrompting, setStepperForPrompting] = useState(0);
  const [selectedChoices, setSelectedChoices] = useState<{ [key: string]: string }>({});
  const [editableParagraph, setEditableParagraph] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [tempPrompt, setTempPrompt] = useState(""); // Local state for text area

  const handleChoiceClick = (choiceKey: string, choiceValue: string) => {
    setSelectedChoices((prev) => {
      const newChoices = {
        ...prev,
        [choiceKey]: choiceValue,
      };
      if (Object.keys(newChoices).length === 6) {
        setStepperForPrompting(2);
      }
      return newChoices;
    });
  };

  const paragraph = [ 
    "Provide a concise summary of the ",
    {
      key: "topic",
      choices: ["study", "paper", "report"],
    },
    " in ",
    {
      key: "field",
      choices: ["one sentence", "a brief paragraph", "a few sentences"],
    },
    ". Emphasize the ",
    {
      key: "conclusion",
      choices: ["core findings", "key insights", "critical data points"],
    },
    " and include relevant ",
    {
      key: "method",
      choices: ["methodologies", "clinical outcomes", "statistical significance"],
    },
    ". Ensure the summary is ",
    {
      key: "type",
      choices: ["precise", "informative", "comprehensive"],
    },
    " and ",
    {
      key: "phrase",
      choices: ["maintains scientific rigor", "remains objective", "avoids interpretation bias"],
    },
    "."
  ];

  const handleDoubleClick = () => {
    setIsEditing(true); // Open the edit state
  };

  const handleEditChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditableParagraph(e.target.value);
  };

  const handleEditSubmit = () => {
    setIsEditing(false); // Close edit state
  };

  const renderParagraph = () => {
    return paragraph.map((part, index) => {
      if (typeof part === "string") {
        return (
          <span key={index} style={{ fontSize: "16px" }}>
            {part}
          </span>
        );
      } else {
        const selected = selectedChoices[part.key];
        if (selected) {
          return (
            <span key={index} style={{ fontWeight: "bold", fontSize: "16px" }}>
              {selected}
            </span>
          );
        } else {
          return (
            <span key={index}>
              {part.choices.map((choice, choiceIndex) => (
                <span key={choice}>
                  {choiceIndex > 0 && " / "} {/* Add slash before all but the first choice */}
                  <Button
                    type="link"
                    onClick={() => handleChoiceClick(part.key, choice)}
                    style={{ padding: "0 0px", fontSize: "16px", color: "#1E4DA1" }}
                  >
                    {choice}
                  </Button>
                </span>
              ))}
            </span>
          );
        }
      }
    });
  };

  const getGeneratedQuery = () => {
    return paragraph.map(part => {
      if (typeof part === "string") return part;
      const selected = selectedChoices[part.key];
      return selected || '';
    }).join(' ');
  };

  return (
    <Col span={24} style={{ paddingLeft: "20px" }}>
      <Card style={{ height: "575px" }}>
        <div style={{ display: "flex", gap: "10px" }}>
          <Image src={PROJECT_ICONS.PDF} preview={false} />
          <p style={{fontWeight:'bold',fontSize:'16px'}}>{fileForChatWithDoc}</p>
        </div>
        <div style={{ height: "475px", display: "flex", flexDirection: "column", justifyContent: isLoadingSummary?"center":"flex-end", alignItems: "center" }}>

          {isLoadingSummary ?(<>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "5px", color: "#949494" }}>
            <Image src={PROJECT_ICONS.FILESUMMARISE} />
            <p>Please Wait...</p>
            <p>Document Summarise is in progress</p>
          </div>
          </>):
          fileSummary!==null && fileSummariserQuery?(<>
        
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "5px", color: "#949494" }}>
           <Markdown>{fileSummary?fileSummary.description:"No Summary available"}</Markdown>
          </div>
          </>):
          (
            
          <div style={{ width: "60%", height: "60%", border: "1px solid #E2E2E2", borderRadius: "10px", padding: "10px", position: "relative" }}>
            {stepperForPrompting === 0 ? (
              <>
                <TextArea
                  placeholder="Enter your prompt here"
                  rows={10}
                  style={{ border: "none", resize: "none" }}
                  value={tempPrompt} // Bind to temporary state
                  onChange={(e) => setTempPrompt(e.target.value)} // Update temporary state on change
                />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ display: "flex", gap: "10px", marginTop: "10px", alignItems: "center", cursor: "pointer" }}>
                    <img src={PROJECT_ICONS.MAGICPEN} alt="Magic Pen" />
                    <p style={{ margin: 0 }} onClick={() => setStepperForPrompting(1)}>
                      Show Suggestion
                    </p>
                  </div>
                  <Button
                    style={{ color: '#1E4DA1', fontWeight: 'bold', border: '#1E4DA1' }}
                    onClick={() => {
                      setFileSummariserQuery(tempPrompt); // Use the value from the temporary state
                      setSelectedChoices({});
                      
                    }}
                  >
                    USE PROMPT
                  </Button>
                </div>
              </>
            ) : stepperForPrompting === 1 ? (
              <>
                {isEditing ? (
                  <TextArea
                    value={editableParagraph}
                    onChange={handleEditChange}
                    onBlur={handleEditSubmit}
                    autoFocus
                    rows={4}
                  />
                ) : (
                  <>
                    <p style={{ fontSize: "16px", cursor: "pointer", paddingLeft: "10px", height: '230px' }}>{editableParagraph || renderParagraph()}</p>
                    <Button style={{ color: 'white', background: '#1E4DA1' }} onClick={() => {
                      setSelectedChoices({});
                      setStepperForPrompting(0);
                    }}>BACK</Button>
                  </>
                )}
              </>
            ) : stepperForPrompting === 2 ? (
              <>
                <p style={{ fontSize: "16px", cursor: "pointer", paddingLeft: "10px", height: '230px' }}>{editableParagraph || renderParagraph()}</p>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ display: "flex", gap: "0px", marginTop: "10px", alignItems: "center", cursor: "pointer" }}>
                    <img src={PROJECT_ICONS.REFRESH} alt="Magic Pen" />
                    <Button type="link"
                      style={{ color: '#1E4DA1', fontWeight: 'bold' }}
                      onClick={() => {
                        setSelectedChoices({});
                        setStepperForPrompting(1);
                      }}
                    >
                      RESET
                    </Button>
                  </div>
                  <Button
                    style={{ color: '#1E4DA1', fontWeight: 'bold', border: '#1E4DA1' }}
                    onClick={() => {
                      const generatedQuery = getGeneratedQuery();
                      setFileSummariserQuery(generatedQuery); // Set generated query from paragraph
                    }}
                  >
                    USE PROMPT
                  </Button>
                </div>
              </>
            ) : null}
          </div>)}
        </div>
      </Card>
    </Col>
  );
};

export { FileSummariserWrapper };
