import EmailAnimation from "../Animation/Emailanin.json";
import VideoGenAnime from "../Animation/video_generation_anime.json";
import ssLoadingZeroStateAnime from "../Animation/29738.json";
import semanticLoadingAnimation from "../Animation/semantic-loader.json";
import success from "../Animation/Anim8_.json";
import Coffeeload from "../Animation/10358.json";
import Cup from "../Animation/91070.json";
import VideoClipAnimation from "../Animation/video_clip_animation.json";
import { siteConfig } from "../../Services";
import CreateFolder from "../Animation/1.json";
import CreateFoldersFile from "../Animation/2.json";
import SearchingQuery from "../Animation/SearchingQuery.json";
import TranslationAnimation from "../Animation/translation-animation.json";
import UploadFile from "../Animation/UploadFile.json";
import Coffee from "../Animation/Coffee.json";
import UploadBatchQA from "../Animation/UploadBatchQA.json";
const PROJECT_ICONS = Object.freeze({
  EMAIL_ANIMATION: EmailAnimation,
  VIDEO_GENERATION_ANIME: VideoGenAnime,
  SUCCESS: success,
  CUP: Cup,
  CREATE_FOLDER: CreateFolder,
  CREATE_FOLDERS: CreateFoldersFile,
  CT_VIDEO_CLIP_ANIMATION: VideoClipAnimation,
  SS_LOADING_ZERO_STATE: ssLoadingZeroStateAnime,
  COFFEE_LOAD: Coffeeload,
  SEARCH_QUERY: SearchingQuery,
  TRANSLATION_ANIMATION: TranslationAnimation,
  UPLOAD_FILE: UploadFile,
  COFFEE: Coffee,
  UPLOADBATCHQA: UploadBatchQA,
  INDEGENE_LOGO: siteConfig.S3_BASE_URL + "/indegenelogo.png",
  NEXTGEN_LOGO: siteConfig.S3_BASE_URL + "/nextgen.png",
  USERICON_LOGO: siteConfig.S3_BASE_URL + "/userlogo.png",
  MULTILANGUAGE_ICON: "/media/icons/multilanguage_icon.webp",
  GLOBE_ICON: "/media/icons/globe_icon.png",
  DASHBOARD_GROUP_8221: siteConfig.S3_BASE_URL + "/dashboard/Group_8221.svg",
  DASHBOARD_GROUP_8235: siteConfig.S3_BASE_URL + "/dashboard/Group_8235.svg",
  DASHBOARD_GROUP_8236: siteConfig.S3_BASE_URL + "/dashboard/Group_8236.svg",
  DASHBOARD_GROUP_8237: siteConfig.S3_BASE_URL + "/dashboard/Group_8237.svg",
  DASHBOARD_GROUP_8238: siteConfig.S3_BASE_URL + "/dashboard/Group_8238.svg",
  DASHBOARD_GROUP_8239: siteConfig.S3_BASE_URL + "/dashboard/Group_8239.svg",
  DASHBOARD_GROUP_8240: siteConfig.S3_BASE_URL + "/dashboard/Group_8240.svg",
  DASHBOARD_GROUP_8242: siteConfig.S3_BASE_URL + "/dashboard/Group_8242.png",
  SIDEBAR_GROUP_7166: siteConfig.S3_BASE_URL + "/sidebar/Group 7166.png",
  SIDEBAR_GROUP_7165: siteConfig.S3_BASE_URL + "/sidebar/Group_7165.png",
  SIDEBAR_GROUP_7532: siteConfig.S3_BASE_URL + "/sidebar/Group 7532.svg",
  NEXT_LOGO_CURRENT_COLOR: "/media/icons/sidebar/NextLogoCurrentColor.svg",
  SIDEBAR_GROUP_7531: siteConfig.S3_BASE_URL + "/sidebar/Group 7531.png",
  DRAWER_THUMBS_UP: siteConfig.S3_BASE_URL + "/media/drawer/thumbsup.svg",
  DRAWER_THUMBS_DOWN: siteConfig.S3_BASE_URL + "/media/drawer/thumbsdown.svg",
  CT_DALLE: siteConfig.S3_BASE_URL + "/content transcreation/dalle.png",
  // CT_DALLE: siteConfig.S3_BASE_URL + "/content transcreation/dalle-new.png",
  CT_MIDJOURNEY: siteConfig.S3_BASE_URL + "/content transcreation/Midjourney-logo.png",
  // CT_MIDJOURNEY: siteConfig.S3_BASE_URL + "/content transcreation/Midjourney-logo-new.png",
  CT_STABLE_DIFFUSION: siteConfig.S3_BASE_URL + "/content transcreation/Stable-Diffusion-Logo.png",
  // CT_STABLE_DIFFUSION: siteConfig.S3_BASE_URL + "/content transcreation/Stable-Diffusion-Logo-new.png",
  CT_VIDEO_CLIP_ICON: siteConfig.S3_BASE_URL + "/content transcreation/Stable-Diffusion-Logo.png",
  CT_PLAY_ICON: siteConfig.S3_BASE_URL + "/content transcreation/PlayIcon.svg",
  CT_PAUSE_ICON: siteConfig.S3_BASE_URL + "/content transcreation/PauseIcon.svg",
  CT_FULLSCREEN_ICON: siteConfig.S3_BASE_URL + "/content transcreation/FullscreenIcon.svg",
  CH_GRID_ACTIVE_ICON: "/media/icons/content transcreation/content history/grid_active_icon.svg",
  CH_LIST_INACTIVE_ICON: "/media/icons/content transcreation/content history/list_inactive_icon.svg",
  CH_GRID_INACTIVE_ICON: "/media/icons/content transcreation/content history/grid_inactive_icon.svg",
  CH_LIST_ACTIVE_ICON: "/media/icons/content transcreation/content history/list_active_icon.svg",
  CH_THREE_DOTS_HORIZONTAL: "/media/icons/content transcreation/content history/3_dots_horizontal.svg",
  CH_THREE_DOTS_VERTICAL: "/media/icons/content transcreation/content history/3_dots_vertical.svg",
  CT_RESTART_ICON: "/media/icons/content transcreation/content translation/restart_icon.svg",
  CT_HEADPHONES: "https://app.elai.io/static/media/phoneme.5a5a017c.svg",
  CT_TIMER: "https://app.elai.io/static/media/pause.abc29289.svg",

  //Semantic
  SEMANTIC_FILELOGO: siteConfig.S3_BASE_URL + "/semantic/icons8-file.png",
  SEMANTIC_LOGO_GROUP_2971: siteConfig.S3_BASE_URL + "/semantic/Group 2971.svg",
  // SEMANTIC_HEADER_LOGO: "/semantic/nextGenLogo.svg",
  SEMANTIC_HEADER_LOGO: "/media/icons/semantic/Next_Gist.svg",
  SEMANTIC_LOADING_LOGO: semanticLoadingAnimation,
  THUMBS_UP: siteConfig.S3_BASE_URL + "/semantic/thumbsup.svg",
  THUMBS_UP_SOLID: siteConfig.S3_BASE_URL + "/semantic/thumbsupsolid.svg",
  THUMBS_DOWN: siteConfig.S3_BASE_URL + "/semantic/thumbsdown.svg",
  COPY_ICON: siteConfig.S3_BASE_URL + "/semantic/copy-line.svg",
  DOWNLOAD_ICON: siteConfig.S3_BASE_URL + "/semantic/cloud-download.svg",
  SHARE_ICON: siteConfig.S3_BASE_URL + "/semantic/share.svg",
  SEND_ICON: siteConfig.S3_BASE_URL + "/semantic/send.svg",
  SEARCH_ICON: siteConfig.S3_BASE_URL + "/semantic/search.svg",
  PLUS_ICON: siteConfig.S3_BASE_URL + "/semantic/plus.jpg",
  DELETE_ICON: siteConfig.S3_BASE_URL + "/semantic/delete.jpg",
  SEMANTIC_FILTER: "/media/icons/semantic/filter.png",
  STARS: "/media/icons/semantic/Vector.png",
  CHAT: "/media/icons/semantic/chat.png",
  IMAGE1: "/media/icons/semantic/image 1.png",
  MESSAGE: "/media/icons/semantic/Chat icon.png",
  UNION: "/media/icons/semantic/Union.png",
  PERSON: "/media/icons/semantic/Person.png",
  UNIONCREATEFOLDER: "/media/icons/semantic/Unioncreatefolder.png",
  FOLDERFILE: "/media/icons/semantic/Vector (Stroke).png",
  COPY: "/media/icons/semantic/copy-outline.png",
  REFRESH: "/media/icons/semantic/refresh_duotone.png",
  DOTS: "/media/icons/semantic/menu-vertical_broken.png",
  ARROW: "/media/icons/semantic/Vector (2).png",
  MAGICPEN: "/media/icons/semantic/magicpen.png",
  DOCS: "/media/icons/semantic/docs.png",
  IMAGE: "/media/icons/semantic/Image.png",
  INSIGHTCARD1: "/media/icons/semantic/InsightCard1.png",
  INSIGHTCARD2: "/media/icons/semantic/InsightCard2.png",
  INSIGHTCARD3: "/media/icons/semantic/InsightCard3.png",
  ELLIPSE: "/media/icons/semantic/Ellipse.png",
  AUDIO: "/media/icons/semantic/audio.png",
  VIDEO: "/media/icons/semantic/video.png",
  PDF: "/media/icons/semantic/pdf.png",
  PLAYOUTLINE: "/media/icons/semantic/play2.png",
  SPEAKEROUTLINE: "/media/icons/semantic/speaker2.png",
  IMAGEOUTLINE: "/media/icons/semantic/image2.png",
  AUDIOWHITE: "/media/icons/semantic/audio2white.png",
  CHATMESSANGER: "/media/icons/semantic/ChatMessanger.png",
  COMPASS: "/media/icons/semantic/compass.png",
  VECTOR6: "/media/icons/semantic/Vector6.png",
  VECTOR7: "/media/icons/semantic/Vector7.png",
  GROUP2: "/media/icons/semantic/Group2.png",
  GROUP11913: "/media/icons/semantic/Group11913.png",
  RADIO: "/media/icons/semantic/radio.png",
  RADIOCHECK: "/media/icons/semantic/radiocheck.png",
  IDEA: "/media/icons/semantic/idea.png",
  VECTOR8: "/media/icons/semantic/Vector (8).png",
  FRAME: "/media/icons/semantic/Frame.png",
  PPT: "/media/icons/semantic/Ppt.png",
  IMAGEICON: "/media/icons/semantic/imageicon1.png",
  RADIOSELECT: "/media/icons/semantic/radioselect.png",
  RADIOUNSELECT: "/media/icons/semantic/radiounselect.png",
  NOTIFICATIONSOUND: "/media/icons/semantic/Notificationsound.mp3",
  DATATABLE: "/media/icons/semantic/Datatable.png",
  IMAGES: "/media/icons/semantic/Images.png",
  SUMMARY: "/media/icons/semantic/Summary.png",
  IMAGEICONLAND: "/media/icons/semantic/ImageIcon.png",
  VIDEOFRAMEIMAGE: "/media/icons/semantic/VideoFrameImage.png",
  FLOATINGICON: "/media/icons/semantic/FloatingIconCircle.png",
  DROPBOX: "/media/icons/semantic/DropBox.png",
  GOOGLEDRIVE: "/media/icons/semantic/GoogleDrive.png",
  COMPUTERSOLID: "/media/icons/semantic/ComputerSolid.png",
  BOXLINK: "/media/icons/semantic/BoxLink.png",
  TRASH: "/media/icons/semantic/Trash.png",
  HOME: "/media/icons/semantic/Home.png",
  ARTEFACTS: "/media/icons/semantic/Artefacts.png",
  FILES: "/media/icons/semantic/Files.png",
  TEXTREFERENCE: "/media/icons/semantic/TextReference.png",
  FILESUMMARISE: "/media/icons/semantic/FileSummariser.png",
  ANSWERS: "/media/icons/semantic/Answers.png",
  PERSONA: "/media/icons/semantic/Persona.png",
  KNOWLEDGEGRAPH: "/media/icons/semantic/KnowledgeGraph.png",
  USECASE_COMPETITIVE_INTELLIGENCE: "/media/icons/semantic/CompetitiveIntelligence.png",
  USECASE_LOCALISER_ICON: "/media/icons/semantic/LocalizerIcon.png",
  USECASE_BRAND_INSIGHT: "/media/icons/semantic/BrandInsight.png",
  USECASE_ML_REVIEW: "/media/icons/semantic/MLReview.png",
  USECASE_ML_SUMMARY: "/media/icons/semantic/MLSummary.png",
  CONTINUE: "/media/icons/semantic/Continue.png",
  WEBSEARCH: "/media/icons/semantic/WEBSearch.jpeg",
  PPTMAIN:"/media/icons/semantic/ThumbnailPPTmain.png",
  PPT1:"/media/icons/semantic/ThumbnailPPT1.jpg",
  PPT2:"/media/icons/semantic/ThumbnailPPT2.jpg",
  PPT3:"/media/icons/semantic/ThumbnailPPT3.png",
  PPT4:"/media/icons/semantic/ThumbnailPPT4.png",
  PPT5:"/media/icons/semantic/ThumbnailPPT5.png",
  PDFMAIN:"/media/icons/semantic/PDFMain.png",
  PDF1:"/media/icons/semantic/PDF1.png",
  PDF2:"/media/icons/semantic/PDF2.png",
  PDF3:"/media/icons/semantic/PDF3.jpg",
  PDF4:"/media/icons/semantic/PDF4.jpg",
  PPTFORDEMO:"/media/icons/semantic/PPTfordemo.png",

  //Content AI
  C_AI_BANNER: siteConfig.S3_BASE_URL + "/Content AI/banner.png",
  C_AI_CHAT_BOT_IMAGE: "/media/icons/Content AI/chat-bot-image.png",
  C_AI_CHAT_BOT_INPUT_CURSOR: "/media/icons/Content AI/chat-bot-input-cursor.svg",
  C_AI_BOT: siteConfig.S3_BASE_URL + "/Content AI/bot.svg",
  C_AI_DISLIKE: siteConfig.S3_BASE_URL + "/Content AI/dislike.svg",
  C_AI_INFO: siteConfig.S3_BASE_URL + "/Content AI/info.svg",
  C_AI_LEFT_ARROW: siteConfig.S3_BASE_URL + "/Content AI/leftArrow.svg",
  C_AI_DOWN_ARROW: "/media/icons/Content AI/chevron-down.svg",
  C_AI_DOWN_ARROW_GRAY: "/media/icons/Content AI/chevron-down-gray.svg",
  C_AI_UP_ARROW: "/media/icons/Content AI/chevron-up.svg",
  C_AI_UP_ARROW_GRAY: "/media/icons/Content AI/chevron-up-gray.svg",
  C_AI_LIKE: siteConfig.S3_BASE_URL + "/Content AI/like.svg",
  C_AI_LOGO: siteConfig.S3_BASE_URL + "/Content AI/logo.svg",
  C_AI_MAXIMIZE: siteConfig.S3_BASE_URL + "/Content AI/maximize.svg",
  C_AI_MINIMIZE: siteConfig.S3_BASE_URL + "/Content AI/minimize.svg",
  C_AI_NOTIFICATION: siteConfig.S3_BASE_URL + "/Content AI/notification.svg",
  C_AI_RIGHT_ARROW: siteConfig.S3_BASE_URL + "/Content AI/rightArrow.svg",
  C_AI_SHARE: siteConfig.S3_BASE_URL + "/Content AI/share.svg",
  C_AI_TOGGLE: siteConfig.S3_BASE_URL + "/Content AI/toggle.svg",
  C_AI_ACTION_MENU_ICON: "/media/icons/Content AI/action-menu.svg",
  C_AI_ACTION_MENU_GRAY_ICON: "/media/icons/Content AI/action-menu-gray.svg",
  C_AI_UNLIKE: siteConfig.S3_BASE_URL + "/Content AI/unlike.svg",
  C_AI_USER: siteConfig.S3_BASE_URL + "/Content AI/user.png",
  C_AI_CHECKBOX_ICON: "/media/icons/Content AI/checkbox.svg",
  C_AI_CHECKBOX_CHECKED_ICON: "/media/icons/Content AI/checkbox-checked.svg",
  C_AI_EDIT_PENCIL_ICON: "/media/icons/Content AI/edit-pencil.svg",
  C_AI_DELETE_TRASH_ICON: "/media/icons/Content AI/trash-empty.svg",
  C_AI_CLOSE_SQUARE_ICON: "/media/icons/Content AI/close-square-icon.svg",
  C_AI_SAVE_ICON: "/media/icons/Content AI/save-icon.svg",
  C_AI_FILE_UPLOAD_GIF: "/media/icons/Content AI/file-upload-gif.gif",
  C_AI_FILE_PLACEHOLDERE_ICON: "/media/icons/Content AI/file-placeholder-icon.svg",
  C_AI_CHECK_ICON: "/media/icons/Content AI/check-icon.svg",

  INDEGENE_COMING_SOON_IMG: siteConfig.S3_BASE_URL + "/indegeneComingSoon.png",

  //Use Case Library
  UC_DEMO_ICON: siteConfig.S3_BASE_URL + "/usecase library/demoIcon.png",
  //Field Copilot
  //Description
  UC_FC_DESC_ACCESS_POINT_ICON: siteConfig.S3_BASE_URL + "/usecase library/field-copilot/description/accessPointIcon.svg",
  UC_FC_DESC_COMPLIANCE_ICON: siteConfig.S3_BASE_URL + "/usecase library/field-copilot/description/complianceIcon.svg",
  UC_FC_DESC_FAVORITES_ICON: siteConfig.S3_BASE_URL + "/usecase library/field-copilot/description/favoritesIcon.svg",
  UC_FC_DESC_INDEGENE_WELCOME_FILED_COPILOT_IMAGE:
    siteConfig.S3_BASE_URL + "/usecase library/field-copilot/description/indegeneWelcomeFiledCopilot.png",
  UC_FC_DESC_KNOWLEDGE_BASE_ICON: siteConfig.S3_BASE_URL + "/usecase library/field-copilot/description/knowledgeBaseIcon.svg",
  UC_FC_DESC_RESPONSE_ICON: siteConfig.S3_BASE_URL + "/usecase library/field-copilot/description/responseIcon.svg",
  UC_FC_DESC_TASK_MANAGEMENT_ICON: siteConfig.S3_BASE_URL + "/usecase library/field-copilot/description/taskManagementIcon.svg",
  UC_FC_DESC_VIEW360_ICON: siteConfig.S3_BASE_URL + "/usecase library/field-copilot/description/view360Icon.svg",
  //Business Value Proposition
  UC_FC_BVP_FEEDBACK_ICON: siteConfig.S3_BASE_URL + "/usecase library/field-copilot/business value proposition/feedbackIcon.svg",
  UC_FC_BVP_AI_ARCHITECTURE_ICON: siteConfig.S3_BASE_URL + "/usecase library/field-copilot/business value proposition/aiArchitectureIcon.svg",
  UC_FC_BVP_INTEGRATED_KNOWLEDGE_ICON:
    siteConfig.S3_BASE_URL + "/usecase library/field-copilot/business value proposition/integratedKnowledgeIcon.svg",
  UC_FC_BVP_COMPLIANCE_REPORTING_ICON:
    siteConfig.S3_BASE_URL + "/usecase library/field-copilot/business value proposition/complianceReportingIcon.svg",
  UC_FC_BVP_COMPREHENSIVE_LIBRARY_ICON:
    siteConfig.S3_BASE_URL + "/usecase library/field-copilot/business value proposition/comprehensiveLibraryIcon.svg",
  //Return on Investment
  UC_FC_ROI_ENHANCED_ENGAGEMENTS_ICON: siteConfig.S3_BASE_URL + "/usecase library/field-copilot/return on investment/enhancedEngagementsIcon.svg",
  UC_FC_ROI_FINANCIAL_IMPACT_ICON: siteConfig.S3_BASE_URL + "/usecase library/field-copilot/return on investment/financialImpactIcon.svg",
  UC_FC_ROI_SALES_OPS_EXCELLENCE_ICON: siteConfig.S3_BASE_URL + "/usecase library/field-copilot/return on investment/salesOpsExcellenceIcon.svg",
  //Success Metrics
  UC_FC_SM_HOURGLASS_ICON: siteConfig.S3_BASE_URL + "/usecase library/field-copilot/success metrics/hourglassIcon.svg",
  UC_FC_SM_SALES_IMROVEMENT_ICON: siteConfig.S3_BASE_URL + "/usecase library/field-copilot/success metrics/salesImprovementIcon.svg",
  UC_FC_SM_INFORMATION_ACCURACY_ICON: siteConfig.S3_BASE_URL + "/usecase library/field-copilot/success metrics/informationAccuracyIcon.svg",
  UC_FC_SM_CUSTOMER_FEEDBACK_ICON: siteConfig.S3_BASE_URL + "/usecase library/field-copilot/success metrics/customerFeedbackIcon.svg",
  UC_FC_SM_ENGAGEMENT_RATE_ICON: siteConfig.S3_BASE_URL + "/usecase library/field-copilot/success metrics/engagementRateIcon.svg",
  UC_FC_SM_PERSONALIZATION_ICON: siteConfig.S3_BASE_URL + "/usecase library/field-copilot/success metrics/personalizationIcon.svg",
  UC_FC_SM_COMPLIANCE_REGULATION_ICON: siteConfig.S3_BASE_URL + "/usecase library/field-copilot/success metrics/complianceRegulationIcon.svg",
  UC_FC_SM_COST_SAVINGS_ICON: siteConfig.S3_BASE_URL + "/usecase library/field-copilot/success metrics/costSavingsIcon.svg",
  //Future Potential
  UC_FC_FP_CONTENT_GENERATION_ICON: siteConfig.S3_BASE_URL + "/usecase library/field-copilot/future potential/contentGenerationIcon.svg",
  UC_FC_FP_TRAINING_SIMULATIONS_ICON: siteConfig.S3_BASE_URL + "/usecase library/field-copilot/future potential/trainingSimulationsIcon.svg",
  UC_FC_FP_LANGUAGE_TRANSLATION_ICON: siteConfig.S3_BASE_URL + "/usecase library/field-copilot/future potential/languageTranslationIcon.svg",
  UC_FC_FP_PREDICTIVE_ANALYTICS_ICON: siteConfig.S3_BASE_URL + "/usecase library/field-copilot/future potential/predictiveAnalyticsIcon.svg",
  UC_FC_FP_VOICE_INTERACTION_ICON: siteConfig.S3_BASE_URL + "/usecase library/field-copilot/future potential/voiceInteractionIcon.svg",
  UC_FC_FP_ETHICAL_CONSIDERATIONS_ICON: siteConfig.S3_BASE_URL + "/usecase library/field-copilot/future potential/ethicalConsiderationsIcon.svg",

  //Content AI | Content Creator
  //Description
  UC_CA_DESC_PROMPT_ENRICHMENT_ICON: siteConfig.S3_BASE_URL + "/usecase library/content-ai/description/promptEnrichmentIcon.svg",
  UC_CA_DESC_REGULATORY_COMPLIANCE_CHECK_ICON: siteConfig.S3_BASE_URL + "/usecase library/content-ai/description/regulatoryComplianceCheckIcon.svg",
  UC_CA_DESC_LOCALIZATION_OPTIMIZATION_ICON: siteConfig.S3_BASE_URL + "/usecase library/content-ai/description/localizationOptimizationIcon.svg",
  UC_CA_DESC_RESPONSIVE_DESIGN_ICON: siteConfig.S3_BASE_URL + "/usecase library/content-ai/description/responsiveDesignIcon.svg",
  UC_CA_DESC_SCALABLE_MEGAPHONE_ICON: siteConfig.S3_BASE_URL + "/usecase library/content-ai/description/scalableMegaPhoneIcon.svg",
  UC_CA_DESC_SEARCH_TITLE_HERE_IMAGE: siteConfig.S3_BASE_URL + "/usecase library/content-ai/description/searchTitleHereImage.svg",
  //Business Value Proposition
  UC_CA_BVP_TIME_TO_MARKET_ICON: siteConfig.S3_BASE_URL + "/usecase library/content-ai/business value proposition/timeToMarketIcon.svg",
  UC_CA_BVP_COST_EFFICIENCY_ICON: siteConfig.S3_BASE_URL + "/usecase library/content-ai/business value proposition/costEfficiencyIcon.svg",
  UC_CA_BVP_MARKET_EXPANSION_ICON: siteConfig.S3_BASE_URL + "/usecase library/content-ai/business value proposition/marketExpansionIcon.svg",
  UC_CA_BVP_INCREASED_ENGAGEMENTS_ICON:
    siteConfig.S3_BASE_URL + "/usecase library/content-ai/business value proposition/increasedEngagementsIcon.svg",
  //Return on Investment
  UC_CA_ROI_FINANCIAL_BENEFITS_ICON: siteConfig.S3_BASE_URL + "/usecase library/content-ai/return on investment/financialBenefitsIcon.svg",
  UC_CA_ROI_OPERATIONAL_BENEFITS_ICON: siteConfig.S3_BASE_URL + "/usecase library/content-ai/return on investment/operationalBenefitsIcon.svg",
  UC_CA_ROI_MARKETING_BENEFITS_ICON: siteConfig.S3_BASE_URL + "/usecase library/content-ai/return on investment/marketingBenefitsIcon.svg",
  //Success Metrics
  UC_CA_SM_CONTENT_PRODUCTION_SPEED_ICON: siteConfig.S3_BASE_URL + "/usecase library/content-ai/success metrics/contentProductionSpeedIcon.svg",
  UC_CA_SM_COST_PER_CONTENT_CALCULATOR_ICON: siteConfig.S3_BASE_URL + "/usecase library/content-ai/success metrics/costPerContentCalculatorIcon.svg",
  UC_CA_SM_CAC_CONTENT_VIEW_ICON: siteConfig.S3_BASE_URL + "/usecase library/content-ai/success metrics/cacContentViewIcon.svg",
  UC_CA_SM_ROI_GRAPH_ICON: siteConfig.S3_BASE_URL + "/usecase library/content-ai/success metrics/roiGraphIcon.svg",
  UC_CA_SM_CONVERSION_RATE_ICON: siteConfig.S3_BASE_URL + "/usecase library/content-ai/success metrics/conversionRateIcon.svg",
  UC_CA_SM_ERROR_RATES_ICON: siteConfig.S3_BASE_URL + "/usecase library/content-ai/success metrics/errorRatesIcon.svg",
  UC_CA_SM_REVISION_RATE_ICON: siteConfig.S3_BASE_URL + "/usecase library/content-ai/success metrics/revisionRateIcon.svg",

  //I-Detail Extraxt
  IDX_LOGO: "/media/icons/IDetail Extract/idetail_logo.svg",
  IDX_UPLOAD_LOGO: "/media/icons/IDetail Extract/upload_duotone.svg",
  IDX_LINK_ICON: "/media/icons/IDetail Extract/link.svg",
  IDX_INFORMATION_ICON: "/media/icons/IDetail Extract/information.svg",
  IDX_BULB_ICON: "/media/icons/IDetail Extract/android-bulb.svg",
  IDX_FILE_ICON: "/media/icons/IDetail Extract/file-earmark-pdf.svg",

  //translation module icons
  TM_LOGO: "/media/icons/Translation Module/translation-module-logo.svg",
  TM_EXCHANGE_ICON: "/media/icons/Translation Module/exchange.svg",
  TM_CONTENT_TRANSLATION_ICON: "/media/icons/Translation Module/content-translation-icon.svg",

  //Content Newton Module
  CN_APP_LOGO: "/media/icons/Content Newton/header/IntelReg.svg",
  CN_HEADER_NOTIFICATION: "/media/icons/Content Newton/header/NotificationIcon.svg",
  CN_HEADER_AVATAR: "/media/icons/Content Newton/header/HeaderAvatarImage.png",
  CN_SIDEBAR_CONTENT_NEWTON_ICON: "/media/icons/Content Newton/sidebar/ContentReplicatorIcon.svg",
  CN_SIDEBAR_REPORTS_ICON: "/media/icons/Content Newton/sidebar/ReportsIcon.svg",
  CN_SIDEBAR_REARRANGE_ICON: "/media/icons/Content Newton/sidebar/RearrangeIcon.svg",
  CN_SIDEBAR_SETTINGS_ICON: "/media/icons/Content Newton/sidebar/SettingsIcon.svg",
  CN_CONTENT_NEWTON_MODULE_ICON: "/media/icons/Content Newton/DynamicFeed.svg",
  CN_ADMIN_NEXT_ICON: "/media/icons/Content Newton/AdminNextIcon.svg",
  CN_EDIT_MESSAGE_ICON: "/media/icons/Content Newton/EditMessageIcon.svg",
  CN_PIN_MESSAGE_ICON: "/media/icons/Content Newton/PinMessageIcon.svg",
  CN_USER_AVATAR_ICON: "/media/icons/Content Newton/UserAvatarIcon.png",
  CN_FILTER_ICON: "/media/icons/Content Newton/FilterListIcon.svg"
});

export { PROJECT_ICONS };
