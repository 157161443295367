import { Breadcrumb, Layout, Menu, theme } from "antd";
import { FC } from "react";
import { LayoutSyncTypes } from "../../Utils/_gTypes";
import { useSelector } from "react-redux";
import { getCurrentLayout } from "../../Utils";
import { useCurrentModulePath } from "../hooks/useCurrentModulePath";

const { Header, Content, Footer } = Layout;

type IProps = {
  children: any;
};

const objStyle = {
  CT: {
    padding: 24,
    minHeight: 280,
    opacity: 1,
    backgroundImage: "linear-gradient(to right top, #c1f4ff, white 30%, white 70%, #c1f4ff)",
    backdropFilter: "blur(10px)"
  },
  SS: {
    // padding: 24,
    minHeight: 280,
    opacity: 1,
    background: "#F3F6FA 0% 0% no-repeat padding-box"
  },
  TM: {
    padding: "30px 5px",
    minHeight: 280,
    opacity: 1,
    background: "#f4f6f9"
  },
  CN: {
    height: "100%"
  }
};

export const DContent: FC<IProps> = ({ children }) => {
  const currentModulePath = useCurrentModulePath();
  const { layoutSync }: { layoutSync: LayoutSyncTypes } = useSelector((state: any) => state?.commonReducer);

  const getStyleWRTModules = () => {
    if (
      getCurrentLayout(currentModulePath).SEMANTIC_SEARCH ||
      getCurrentLayout(currentModulePath).CONTENT_AI ||
      getCurrentLayout(currentModulePath).CONTENT_TRANSLATION
    ) {
      return objStyle.SS;
    } else if (getCurrentLayout(currentModulePath).TRANSLATION_MODULE) {
      return objStyle.TM;
    } else if (getCurrentLayout(currentModulePath).CONTENT_NEWTON) {
      return objStyle.CN;
    } else {
      return objStyle.CT;
    }
  };

  return <Content style={getStyleWRTModules()}>{children}</Content>;
};
